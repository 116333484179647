import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import HomeIconButton from "../elements/HomeIcon/HomeIcon";
import PerfilIconButton from "../elements/PerfilIcon/PerfilIcon";
import HelpIconButton from "../elements/HelpIcon/HelpIcon";
import AboutIconButton from "./AboutIcon/AboutIcon";
import ManageIconButton from "./ManageIcon/ManageIcon";
import LogoutIconButton from "./LogoutIcon/LogoutIcon";
import SupervisionIconButton from "./SupervisionIcon/SupervisionIcon";
import SupervisionOffIconButton from "./SupervisionOffIcon/SupervisionOffIcon";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { BsList } from "react-icons/bs";

import "bootstrap/dist/css/bootstrap.min.css";
import "../elements/Styles.css";
import GetTokenApi from "../pages/KeyCloak/GetTokenApi";
import CheckPermission from "../pages/Auth/CheckPermission";
import SearchBar from "./SearchBar/SearchBar";

const Header = (props) => {
  let { id: projectId } = useParams();
  const navigate = useNavigate();

  let url = window.location.href.split("/");

  let page = url[3];

  const [isSupervisionOn, setIsSupervisionOn] = useState(false);

  const handleClick = () => {
    setIsSupervisionOn((prev) => !prev);
    if (props.toggleSupervisionMode) {
      props.toggleSupervisionMode();
    }
  };
  // Recupera dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  // Define o hook para armazenamento do token de acesso à API
  const [userPermission, setUserPermission] = useState(null);
  // Realiza a requisição de verificação da autenticação do usuário
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  function getUserData() {}

  // Aguarda até a verificação da autenticação do usuário seja realizada
  // e inicia a requisição dos dados à API
  useEffect(() => {
    if (userPermission) {
      getUserData();
      getPermissionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  let args = {
    fixed: "top",
    expand: true,
  };

  // Define os hooks de abertura do menu
  const [isOpen, setIsOpen] = useState(false);
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Define os hooks de abertuda do modal de detalhes do usuário
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  // Recurso do logout e redirecionamento do sistema
  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  // Hook de armazenamento do dicionário de permissões
  const [permissionsData, setPermissionsData] = useState([]);

  // Requisição de obtenção do dicionário de permissões
  function getPermissionsData() {
    fetch(apiUrl + "/users/roles", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setPermissionsData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  // Construção do layout da página
  return (
    <div style={{ zIndex: "1000" }}>
      <Navbar
        fixed="top"
        {...args}
        className="nav-header d-flex justify-content-center"
        style={page === "mapa" ? { zIndex: "1050" } : { zIndex: "1050" }}
      >
        <Button
          className="me-3"
          color="outline-secondary"
          onClick={() => setOffCanvasIsOpen(true)}
        >
          <BsList />
        </Button>

        {(props && props.title) && (
          <Link to={`/detalhesprojeto/${projectId}`}>
            {props?.title}
          </Link>
        )}

        {props.toggleSupervisionMode && !props.isMobile && (
          <button
            onClick={handleClick}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isSupervisionOn ? (
              <SupervisionIconButton width="30px" height="30px" />
            ) : (
              <SupervisionOffIconButton width="30px" height="30px" />
            )}
          </button>
        )}
        <NavbarBrand
          style={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {localStorage.getItem("robotictech_company_logo") &&
          localStorage.getItem("robotictech_company_logo") !== "null" &&
          localStorage.getItem("robotictech_company_logo") !== "" ? (
            <img
              alt="company_logo"
              src={localStorage.getItem("robotictech_company_logo")}
              style={{
                maxHeight: "50px", // Ajuste o tamanho conforme necessário
                display: "block",
                margin: "0 auto",
              }}
              className="m-1"
            />
          ) : (
            <img
              alt="company_logo"
              src="/imgs/LOGO_STREET.svg"
              style={{
                maxHeight: "50px",
                display: "block",
                margin: "0 auto",
              }}
              className="m-1"
            />
          )}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar></Nav>
          {window.location.pathname.indexOf("mapa") !== -1 ? (
            <div className="d-flex align-items-center">
              {/* Botão de Pesquisa */}
              {props.searchBarValue != null && !props.isMobile && (
                <SearchBar
                  value={props.searchBarValue}
                  setValue={props.setSearchBarValue}
                />
              )}
            </div>
          ) : null}
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={offCanvasIsOpen} style={{ zIndex: "1900 !important" }}>
        <OffcanvasHeader
          toggle={() => setOffCanvasIsOpen(false)}
        ></OffcanvasHeader>
        <OffcanvasBody>
          <Link
            to={"/home"}
            className="d-flex align-items-center my-2 link-item"
          >
            <HomeIconButton className="icon" width="40px" height="40px" />
            <span className="text ms-3"> Home</span>
          </Link>
          <Link
            to="/perfilusuario"
            className="d-flex align-items-center my-2 link-item"
          >
            <PerfilIconButton className="icon" width="40px" height="40px" />
            <span className="text ms-3">Meu perfil</span>
          </Link>
          {CheckPermission(
            userPermission && userPermission.role ? userPermission.role : "",
            "MANAGER"
          ) ? (
            CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "ADMIN"
            ) ? (
              <Link
                to={"/companiesManagement"}
                className="d-flex align-items-center my-2 link-item"
              >
                <ManageIconButton className="icon" width="40px" height="40px" />
                <span className="text ms-3"> Gerenciamento</span>
              </Link>
            ) : (
              <Link
                to={
                  "/companiesManagement/" +
                  localStorage.getItem("robotictech_company_id")
                }
                className="d-flex align-items-center my-2 link-item"
              >
                <ManageIconButton className="icon" width="40px" height="40px" />
                <span className="text ms-3"> Gerenciamento</span>
              </Link>
            )
          ) : (
            ""
          )}
          {/*<Link to={"/home"} className="d-flex align-items-center my-2 link-item">
            <HelpIconButton width="40px" height="40px"  className="icon"/>
            <span className="text ms-3"> Ajuda</span>
          </Link>
          <Link to={"/home"} className="d-flex align-items-center my-2 link-item">
            <AboutIconButton className="icon" width="40px" height="40px" />
            <span className="text ms-3"> Sobre</span>
          </Link>*/}
          <hr />
          <Link
            onClick={logout}
            className="d-flex align-items-center my-2 link-item"
          >
            <LogoutIconButton className="icon" width="40px" height="40px" />
            <span className="text ms-3"> Logout</span>
          </Link>
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          height: "50vh",
          width: "50vw",
          zIndex: "-90",
          overflow: "hidden",
          opacity: "0.025",
        }}
      >
        <img
          alt="strap_ball_alpha"
          src="/imgs/strap_ball_alpha.png"
          id="bigball"
          style={{
            width: "100%",
            position: "relative",
            right: "-50%",
            top: "-60%",
          }}
        />
      </div>

      <div
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
          height: "100vh",
          width: "50vw",
          zIndex: "-90",
          overflow: "hidden",
          opacity: "0.04",
        }}
      >
        <img
          alt="big_ball_alpha"
          src="/imgs/big_ball_alpha.png"
          id="bigball"
          style={{
            width: "100%",
            position: "relative",
            top: "10vh",
            left: "-50%",
            bottom: "0%",
          }}
        />
      </div>
      <div
        className="text-center"
        style={{
          position: "fixed",
          bottom: "0px",
          left: "0px",
          width: "100vw",
          zIndex: "2000",
          backgroundColor: "#ecf0f3",
          boxShadow: "0px 0px 8px 8px #ecf0f3",
        }}
      >
        Powered by RoboticTech
      </div>
    </div>
  );
};
export default Header;
