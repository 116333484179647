import React from 'react';
import CamIcon from '../../assets/icons/camera-360.svg';

const CamIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={CamIcon} alt={label} data-testid="cam-icon" width="35px" height="35px"/>
    </button>
  );
};

export default CamIconButton;