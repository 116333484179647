import React from 'react';
import TelecomBoxIcon from '../../assets/icons/CAIXAS_TELECOM.svg';

const TelecomBoxIconButton = ({ label, width = "20px", height = "20px" }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={TelecomBoxIcon} alt={label} data-testid="telecombox-icon" width={width} height={height}/>
    </button>
  );
};

export default TelecomBoxIconButton;