import React from 'react';
import DescriptionIcon from '../../assets/icons/DESCRICAO.svg';

const DescriptionIconButton = ({ label, width = "20px", height = "20px"  }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={DescriptionIcon} alt={label} data-testid="Description-icon" width={width} height={height}/>
    </button>
  );
};

export default DescriptionIconButton;