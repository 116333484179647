import React from 'react';
import DropOutFuseIcon from '../../assets/icons/FUZOES.svg';

const DropOutFuseIconButton = ({ label, width="20px", height="20px" }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={DropOutFuseIcon} alt={label} data-testid="outfuse-icon"  width={width} height={height}/>
    </button>
  );
};

export default DropOutFuseIconButton;