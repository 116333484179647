import React, { useEffect, useState } from "react";
import PhotoSphereViewer from "../../elements/PhotoSphereViewer/PhotoSphereViewer";
import { useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";

const PublicEvidenceView = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const fileType = searchParams.get("fType");
  const objectType = searchParams.get("oType");
  const imageUrl = searchParams.get("detection");
  const imagePath = searchParams.get("path");

  const [detection, setDetection] = useState(null);

  useEffect(() => {
    if (fileType === "xlsx") {
      const boundingBox = JSON.parse(imageUrl);

      setDetection({
        x1: boundingBox[0],
        y1: boundingBox[1],
        x2: boundingBox[2],
        y2: boundingBox[3],
        x3: boundingBox[4],
        y3: boundingBox[5],
        x4: boundingBox[6],
        y4: boundingBox[7],
        objectType,
        image: {
          path: `https://robotic-tech-images.s3.us-east-2.amazonaws.com/${imagePath}`,
        },
      });
    } else {
      try {
        if (imageUrl) {
          const parsedDetection = JSON.parse(atob(imageUrl));
          setDetection(parsedDetection);
        }
      } catch (error) {
        console.error("Failed to parse detection parameter:", error);
      }
    }
  }, [fileType, imagePath, imageUrl, objectType]);

  return (
    <>
      {detection ? (
        <PhotoSphereViewer
          detection={detection}
          imgSrc={detection.image}
          height="100vh"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner></Spinner>
        </div>
      )}
    </>
  );
};

export default PublicEvidenceView;
