import React from 'react';
import UsersIcon from '../../assets/icons/USUARIOS.svg';

const UsersIconButton = ({ label, width = "20px", height = "20px"  }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={UsersIcon} alt={label} data-testid="Users-icon" width={width} height={height}/>
    </button>
  );
};

export default UsersIconButton;