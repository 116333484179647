import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  Navigate,
  Link,
  useParams,
} from "react-router-dom";
import Header from "../../elements/Header";
import CheckAuth from "../Auth/CheckAuth";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
} from "reactstrap";
import {
  BsCloudDownloadFill,
  BsFillPlusCircleFill,
  BsArrowLeft,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsFillExclamationTriangleFill,
  BsTrashFill,
} from "react-icons/bs";
import "../../elements/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";

const Reports = (props) => {
  //Define o título da aba/página
  document.title = "Relatórios";

  // Recupera os parâmetros enviados pela url
  let { id: projectId } = useParams();

  // Recupera os dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  // Hooke de armazenamento do dicionário de tipos de relatórios
  const [reportTypes, setReportTypes] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate("/home");
    }
  };

  // Hook de atualização
  const [count, setCount] = useState();

  // Início aquisição das informações do usuário logado
  const [userPermission, setUserPermission] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  // Fim aquisição das informações do usuário logado

  //início controles aquisição das informações do projeto
  const [projectDetails, setProjectDetails] = useState(false);
  //fim controles aquisição das informações do projeto

  //início controles aquisição das missões do projeto
  const [projectMissions, setProjectMissions] = useState(false);
  //fim controles aquisição das missões do projeto

  // Início controles do modal de confirmação de exclusão de projeto
  const [modalDeleteProject, setModalDeleteProject] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const toggleModalDeleteProject = () =>
    setModalDeleteProject(!modalDeleteProject);
  const closeModalDeleteProject = () => {
    setCount(Math.random());
    setModalDeleteProject(false);
  };

  const openModalContent = (id, icon, title, body) => {
    setModalContent({
      idProject: id,
      icon: icon,
      title: title,
      body: body,
      footer: true,
    });
    toggleModalDeleteProject();
  };
  // Fim controles do modal de confirmação de exclusão de projeto

  const [reportItems, setReportItems] = useState();

  // Início função de requisição de exclusão do projeto pela API
  const deleteProject = (id) => {
    setModalContent({
      id: modalContent.id,
      icon: modalContent.icon,
      title: modalContent.title,
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footer: false,
    });
    fetch(apiUrl + "/reports/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",

        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        setModalContent({
          id: modalContent.id,
          icon: <BsFillCheckCircleFill />,
          title: modalContent.title,
          body: "Relatório apagado com sucesso.",
          footer: false,
        });
      })
      .catch((error) => {
        setModalContent({
          id: modalContent.id,
          icon: <BsFillXCircleFill />,
          title: modalContent.title,
          body: "Não foi possível apagar o relatório.",
          footer: false,
        });
        console.error("Error:", error);
      });
  };
  // Fim função de requisição de exclusão do projeto pela API

  // Obtenção da extensão de um path
  const getExtension = (path) => {
    let aux = path.split(".");
    return aux[aux.length - 1];
  };

  // Obtenção dos relatórios relacionados ao projeto selecionado
  function getData() {
    fetch(apiUrl + "/projects/" + projectId + "/reports?sort=creationTimestamp,desc", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {
          console.log(error);
        }
        let projetos = pulist.content;
        let reportsList = [];
        projetos.forEach((element) => {
          let listItem = element;
          reportsList.push(listItem);
        });
        setReportItems(reportsList);
      })
      .finally(() => {
        // Ao término da obtenção da lista de relatórios, é realizada
        // a requisição para obtenção dos dados do projeto e das missões do projeto
        getProjectData();
        getProjectMissions();
      })
      .catch((error) => {
        alert(error);
      });
  }

  // Rquisição para obtenção das missões do projeto
  const getProjectMissions = () => {
    fetch(apiUrl + "/projects/" + projectId + "/missions", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " +
              response.status +
              " ao tentar recuperar os dados do projeto."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setProjectMissions(data.content);
      });
  };

  // Obtenção dos dados do projeto
  const getProjectData = () => {
    fetch(apiUrl + "/projects/" + projectId, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " +
              response.status +
              " ao tentar recuperar os dados do projeto."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setProjectDetails(data);
      });
  };

  // Obtenção do dicionário de tipos de relatórios
  const getReportTypes = () => {
    fetch(apiUrl + "/reports/options", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {
          console.log(error);
        }
        let auxObject = {};
        pulist.forEach((e) => {
          auxObject[e.option] = e;
        });
        setReportTypes(auxObject);
      });
  };

  // Formatação de data visual

  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  function formatData(element) {
    if (element && element[0] && element[0].toString().length === 4) {
      let dia =
        doisDigitos(element[2]) +
        "/" +
        doisDigitos(element[1]) +
        "/" +
        element[0];
      return dia;
    } else {
      return element;
    }
  }

  // Ao receber o token de acesso, realiza a obtenção dos relatórios disponíveis
  useEffect(() => {
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypes]);

  // Ao receber o token de acesso, realiza a obtenção do dicionário de tipos de relatórios
  useEffect(() => {
    if (userPermission) {
      getReportTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission, count]);

  useEffect(() => {}, [reportItems]);

  // Veririca se o usuário está autenticado
  if (!CheckAuth()) {
    return <Navigate to="/login" />;
  }

  //Constrói o layout da página
  return (
    <div className="p-2">
      <Header />
      {/* Begin modal delete report */}
      <Modal
        isOpen={modalDeleteProject}
        toggle={toggleModalDeleteProject}
        backdrop="static"
      >
        <ModalHeader toggle={closeModalDeleteProject}>
          <div className="row m-0">
            <div className="col-2 m-auto">
              {modalContent.icon ? modalContent.icon : ""}
            </div>
            <div className="col m-auto">
              {modalContent.title ? modalContent.title : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>{modalContent.body ? modalContent.body : ""}</ModalBody>
        {modalContent.footer ? (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalDeleteProject}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => deleteProject(modalContent.idProject)}
            >
              Apagar
            </Button>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      {/* End modal delete report */}
      <Container
        className="p-0 pb-2"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <Row className="m-0">
                <Col className="mt-auto mb-auto">
                  <h2 className="m-0 text-start">
                    {/* Inicio botão voltar pagina */}
                    <div className="m-2">
                      <button
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Voltar à página anterior"
                        className="text-end"
                        style={{
                          marginRight: "0px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleGoBack}
                      >
                        <BsArrowLeft
                          className="nav-button-circle "
                          style={{ boxShadow: "none" }}
                        />
                      </button>
                      {projectDetails
                        ? projectDetails.name
                        : "Relatórios do projeto #" + projectId}
                    </div>
                    {/* Final Botão voltar pagina */}
                  </h2>
                </Col>
                {/* Início botão de adicionar novo projeto */}
                {projectMissions && projectMissions.length > 0 ? (
                  <Col lg={4} className="pr-0 m-0 row">
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "MANAGER"
                    ) ? (
                      <Link
                        className="text-end m-auto p-0"
                        style={{ marginRight: "0px" }}
                        to={"/novorelatorio/" + projectId}
                      >
                        Novo Relatório
                        <BsFillPlusCircleFill className="nav-button-circle me-0" />
                      </Link>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  ""
                )}
                {/* Fim botão de adicionar novo projeto */}
              </Row>
            </div>

            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }}>
                <hr />
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  <ListGroup>
                    {!reportItems ? (
                      <Spinner
                        className="m-auto"
                        style={{ display: "block" }}
                        color="primary"
                      />
                    ) : reportItems.length <= 0 ? (
                      <div>Sem relatórios disponíveis</div>
                    ) : (
                      reportItems.map((element) => {
                        return (
                          <ListGroupItem action key={Math.random()}>
                            <Row className="m-0 p-2">
                              <Col
                                id={"toggler" + element.id}
                                className="p-0"
                                style={{ width: "90%" }}
                              >
                                <div
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseExample8"
                                  aria-expanded="false"
                                  aria-controls="collapseExample8"
                                  id={"projeto-8" + element.id}
                                >
                                  <h5>
                                    {element.name
                                      ? element.name
                                      : "Relatório #" + element.id}
                                  </h5>
                                </div>
                                <div className="mt-3">
                                  <small style={{ fontSize: "0.7em" }}>
                                    <i>ID: {element.id}</i>
                                  </small>
                                </div>
                                {reportTypes && reportTypes[element.option] ? (
                                  <div className="mb-3">
                                    <i>
                                      <small>
                                        {
                                          reportTypes[element.option]
                                            .description
                                        }
                                      </small>
                                    </i>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.creation_timestamp && (
                                  <div>
                                    <small style={{ fontSize: "0.7em" }}>
                                      <i>
                                        Criado em:{" "}
                                        {formatData(element.creation_timestamp)}
                                      </i>
                                    </small>
                                  </div>
                                )}

                                {element.user && (
                                  <div>
                                    <small style={{ fontSize: "0.7em" }}>
                                      <i>
                                        Gerado por: {`${element.user.first_name} ${element.user.last_name}`}
                                      </i>
                                    </small>
                                  </div>
                                )}
                              </Col>
                              <Col md={4} lg={3} xl={3} className="row m-0">
                                <a
                                  href={element.path}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="m-auto btn btn-item-list"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ModalReports"
                                  id="R-btn-8"
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                  }}
                                >
                                  <small>
                                    <Row>
                                      <Col className="col-2">
                                        <BsCloudDownloadFill />
                                      </Col>
                                      <Col className="ml-auto">
                                        {getExtension(element.path)}
                                      </Col>
                                      <Col className="col-2"></Col>
                                    </Row>
                                  </small>
                                </a>
                                {CheckPermission(
                                  userPermission && userPermission.role
                                    ? userPermission.role
                                    : "",
                                  "MANAGER"
                                ) ? (
                                  projectDetails &&
                                  projectDetails.status &&
                                  projectDetails.status !== "Finalizado" ? (
                                    <Link
                                      className="m-auto btn btn-item-list p-auto"
                                      style={{
                                        width: "100%",
                                        height: "30px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                      }}
                                      type="button"
                                      onClick={() =>
                                        openModalContent(
                                          element.id,
                                          <BsFillExclamationTriangleFill />,
                                          element.id + " - " + element.name,
                                          "Deseja realmente apagar o relatório " +
                                            element.name +
                                            "?"
                                        )
                                      }
                                    >
                                      <small>
                                        <Row>
                                          <Col sm={1}>
                                            <BsTrashFill />
                                          </Col>
                                          <Col className="ml-auto">
                                            Excluir relatório
                                          </Col>
                                        </Row>
                                      </small>
                                    </Link>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        );
                      })
                    )}
                  </ListGroup>
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Reports;
