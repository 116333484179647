import React from "react";
import { BsXLg } from "react-icons/bs";
import { Card, CardBody, CardTitle, Col, Input, Label } from "reactstrap";

const filterColorScale = JSON.parse(process.env.REACT_APP_COLOR_SCALE);

const ModalFilterPoleComponents = ({
  toggle,
  selectedValue,
  setSelectedValue,
  setSelectedColor,
  setEnablePointsView,
  enablePointsView,
}) => {
  const objectPoleComponents = [
    { text: "Cruzeta", value: "crossHeads", color: filterColorScale[5] },
    {
      text: "Caixa junção fibra óptica",
      value: "fiberOpticJunctionBoxes",
      color: filterColorScale[10],
    },
    { text: "Lâmpada", value: "lamps", color: filterColorScale[15] },
    {
      text: "Braço de luminária",
      value: "lampArms",
      color: filterColorScale[20],
    },
    {
      text: "Caixa de telecom",
      value: "telecomBoxes",
      color: filterColorScale[25],
    },
    {
      text: "Transformador",
      value: "transformers",
      color: filterColorScale[30],
    },
    {
      text: "Reserva de fios",
      value: "wireReserves",
      color: filterColorScale[35],
    },
  ];

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedValue(selectedValue);

    const selectedComponent = objectPoleComponents.find(
      (component) => component.value === selectedValue
    );

    setSelectedColor(selectedComponent ? selectedComponent.color : "#000000");
  };

  return (
    <Card
      className="modal-pole-components-card"
      style={{
        right: "120px",
        top: "85px",
        position: "absolute",
        zIndex: "1030",
        display: "block",
        overflowX: "scroll",
        maxHeight: "85%",
        maxWidth: "50%",
      }}
    >
      <CardTitle
        style={{
          position: "sticky",
          top: 0,
          zIndex: "2000",
          backgroundColor: "white",
          margin: "0",
        }}
      >
        <div className="d-flex align-items-center p-3 gap-1">
          <Col style={{ fontSize: "1.2em" }}>Componentes</Col>

          <BsXLg onClick={toggle} style={{ cursor: "pointer" }} />
        </div>
        <hr className="m-0" />
      </CardTitle>
      <CardBody>
        <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
          <div className="container-switch" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => setEnablePointsView(!enablePointsView)}
                style={{ fontSize: "24px" }}
              >
                <img
                  alt="icon"
                  src={
                    enablePointsView
                      ? "/imgs/HABILITAR.svg"
                      : "/imgs/DESABILITAR.svg"
                  }
                  style={{ width: "37px" }}
                />
              </div>
              <Label
                onClick={() => setEnablePointsView(!enablePointsView)}
                style={{
                  marginLeft: "8px",
                  marginBottom: "0",
                  cursor: "pointer",
                }}
              >
                Postes
              </Label>
            </div>
          </div>

          <label>
            <Input
              type="radio"
              value=""
              checked={selectedValue === ""}
              onChange={handleChange}
              style={{ marginRight: ".5rem" }}
            />
            Não definido
          </label>
          {objectPoleComponents.map((component) => (
            <label key={component.value}>
              <Input
                type="radio"
                value={component.value}
                checked={selectedValue === component.value}
                onChange={handleChange}
                style={{ marginRight: ".5rem" }}
              />
              {component.text}
            </label>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default ModalFilterPoleComponents;
