import React from 'react';
import MapIcon from '../../assets/icons/MAPA_LIGADO.svg';

const MapIconButton = ({ label, width = "20px", height = "20px", isWhite = false}) => {
  return (
    <button
      aria-label={label}
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}
    >
      <img
        src={MapIcon}
        alt={label}
        data-testid="Map-icon"
        width={width}
        height={height}
        style={{
          filter: isWhite ? "invert(1)" : "none", // Inverte as cores
        }}
      />
    </button>
  );
};


export default MapIconButton;