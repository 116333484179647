import React, { useState, useEffect } from "react";
import { Navigate, Link, useParams } from "react-router-dom";
import Header from "../../elements/Header";
import CheckAuth from "../Auth/CheckAuth";
import {
  Row,
  Col,
  Spinner,
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
  UncontrolledCollapse,
} from "reactstrap";
import {
  BsArrowLeft,
  BsMap,
  BsLayers,
  BsFileEarmarkText,
  BsPencilFill,
  BsFolderFill,
  BsTrash,
  BsChevronDoubleDown,
  BsPinAngle,
} from "react-icons/bs";
import TreesIconButton from "../../elements/TreesIcon/TreesIcon";
import WireReserveIconButton from "../../elements/WireReserveIcon/WireReserveIcon";
import TransformerIconButton from "../../elements/TransformerIcon/TransformerIcon";
import TelecomBoxIconButton from "../../elements/TelecomBoxIcon/TelecomBoxIcon";
import LampArmIconButton from "../../elements/LampArmIcon/LampArmIcon";
import LampIconButton from "../../elements/LampIcon/LampIcon";
import InsulatorIconButton from "../../elements/InsulatorIcon/InsulatorIcon";
import FiberOpticJunctionBoxIconButton from "../../elements/FiberOpticJunctionBoxIcon/FiberOpticJunctionBoxIcon";
import DropOutFuseIconButton from "../../elements/DropOutFuseIcon/DropOutFuseIcon";
import CrossHeadIconButton from "../../elements/CrossHeadIcon/CrossHeadIcon";
import TimeIconButton from "../../elements/TimeIcon/TimeIcon";
import DistanceIconButton from "../../elements/DistanceIcon/DistanceIcon";
import CableSpacersIconButton from "../../elements/CableSpacersIcon/CableSpacersIcon";
import PoleIconButton from "../../elements/PoleIcon/PoleIcon";
import UsersIconButton from "../../elements/UsersIcon/UsersIcon";
import CityIconButton from "../../elements/CityIcon/CityIcon";
import StatusIconButton from "../../elements/StatusIcon/StatusIcon";
import CamIconButton from "../../elements/CamIcon/CamIcon";
import DroneIconButton from "../../elements/DroneIcon/DroneIcon";
import CamDroneIconButton from "../../elements/CamDroneIcon/CamDroneIcon";
import DetailsIconButton from "../../elements/DetailsIcon/DetailsIcon";
import MapIconButton from "../../elements/MapIcon/MapIcon";
import ReportsIconButton from "../../elements/ReportsIcon/ReportsIcon";
import DateIconButton from "../../elements/DateIcon/DateIcon";
import DescriptionIconButton from "../../elements/DescriptionIcon/DescriptionIcon";
import PointsIconButton from "../../elements/PointIcon/PointIcon";
import EditIconButton from "../../elements/EditIcon/EditIcon";
import AddIconButton from "../../elements/AddIcon/AddIcon";
import FolderIconButton from "../../elements/FolderIcon/FolderIcon";
import FilterButton from "../../elements/FilterButton/FilterButton";
import "../../elements/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import { Circle, MapContainer, Polygon, TileLayer } from "react-leaflet";
import cidadesEstadosCoords from "../../elements/CidadesEstadosCoords";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import api from "../../api/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Missions from "../Missions/Missions";


const ProjectDetails = (props) => {
  // Define o título da aba/página
  document.title = "Detalhes de projeto";

  // Recupera os parâmetros enviados pela url
  let { id: projectId } = useParams();

  // Recupera os dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;
  let mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);
  let defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;
  // let defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
  let selectedPointColor = process.env.REACT_APP_SELECTED_POINT_COLOR;
  const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);




    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
    };
  // Hook para armazenamento dos usuários relacionados ao projeto selecionado
  const [projectUsers, setProjectUsers] = useState(null);
  const [cableSpacers, setCableSpacers] = useState(0);
  const [trees, setTrees] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [poles, setPoles] = useState(null);
  const [componentPoles, setComponentPoles] = useState({});
  // Hook para armazenamento dos usuários relacionados ao projeto selecionado
  const [buttonMoreUsersList, setButtonMoreUsersList] = useState(true);

  // Hook para armazenamento dos dados do projeto selecionado
  const [projectData, setProjectData] = useState(false);

  // Hook para armazenar os pontos do mapa
  const [mapPoints, setMapPoints] = useState(null);

  // Hook para armazenar os funcionarios da empresa
  const [companyUsers, setCompanyUsers] = useState(null);

  // Hook para definição do indicador de carregamento
  const [loading, setLoading] = useState(true);

  // Hook para definição do indicador de carregamento
  const [updatePage, setUpdatePage] = useState(null);

  // Início aquisição das informações do usuário logado
  const [userPermission, setUserPermission] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  // Fim aquisição das informações do usuário logado

  // Início controles do modal de confirmação de finalização de projeto
  const [modalFinalizarProjeto, setModalFinalizarProjeto] = useState(false);
  const [modalFinalizarProjetoContent, setModalFinalizarProjetoContent] =
    useState(null);
  // Fim controles do modal de confirmação de finalização de projeto

  // Início controles do modal de usuarios do projeto
  const [modalProjectUsers, setModalProjectUsers] = useState(false);
  // Fim controles do modal de usuários do projeto

  // Início controles do modal de confirmação de adição usuarios do projeto
  const [modalConfirmAddUserProject, setModalConfirmAddUserProject] =
    useState(false);
  // Fim controles do modal de confirmação de adição  usuários do projeto

  // Início controles do modal de confirmação de remoção de usuarios do projeto
  const [modalConfirmRemoveUserProject, setModalConfirmRemoveUserProject] =
    useState(false);
  // Fim controles do modal de confirmação de remoção de usuários do projeto

  // Início controles do modal de notificação
  const [modalNotificacao, setModalNotificacao] = useState(false);
  // Fim controles do modal de notificação

  const [dadosCarregados, setDadosCarregados] = useState(false);

  useEffect(() => {
    if (mapPoints && projectData) setDadosCarregados(true);
  }, [mapPoints, projectData]);

  // Formatação de data
  function formatData(element) {
    if (
      typeof element === "string" &&
      element[1] &&
      element[1].toString().length === 1
    ) {
      let aux = element.toString().split("T");
      let aux_ = aux[0].toString().split("-");
      if (aux_[0] && aux_[1] && aux_[2]) return aux[0];
      else return false;
    } else if (element && element[0] && element[0].toString().length) {
      let dia =
        doisDigitos(element[2]) +
        "/" +
        doisDigitos(element[1]) +
        "/" +
        element[0];

      if (
        element[3] !== 0 &&
        element[3] !== "0" &&
        element[3] !== "00" &&
        element[4] !== 0 &&
        element[4] !== "0" &&
        element[4] !== "00"
      )
        dia += " " + doisDigitos(element[3]) + ":" + doisDigitos(element[4]);
      return dia;
    } else {
      return false;
    }
  }

  // Construção do indicador de carregamento
  const loadingIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          //display: "flex",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  // Obtenção de dados do projeto
  async function getData() {
    // Define o indicador de carregamento
    setLoading(true);

    // Requisição para obtenção de dados do projeto
    await fetch(apiUrl + "/projects/" + projectId, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        let cityDetails = {};
        cidadesEstadosCoords.map((city) => {
          if (city.nome === pulist.city) {
            cityDetails = city;
          }
          return cityDetails;
        });
        pulist["city_details"] = cityDetails;
        setProjectData(pulist);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        // No fim da obtenção dos dados do projeto, realiza a requisição
        // para obter os pontos de exibição do mapa
        getMapPoints();
        getProjectUsers();
        getTrees();
        getTotalDistance();
        getTotalTime();
        getCableSpacers();
        getPoles();
      });
  }

  async function getPoles() {
    try {
      const response = await api.get(`/projects/${projectId}/poles/count`);
      setPoles(response.data.poleCount);
      setComponentPoles({
        crossHeads: response.data.crossHeadCount,
        dropOutFuses: response.data.dropOutFuseCount,
        fiberOpticJunctionBoxes: response.data.fiberOpticJunctionBoxCount,
        insulators: response.data.insulatorCount,
        lamps: response.data.lampCount,
        lampArms: response.data.lampArmCount,
        telecomBoxes: response.data.telecomBoxCount,
        transformers: response.data.transformerCount,
        wireReserves: response.data.wireReserveCount,
      });
    } catch (error) {
      console.error("Erro ao obter contagem de poles e componentes:", error);
    }
  }

  async function getTrees() {
    try {
      const response = await api.get(`/projects/${projectId}/trees`);
      setTrees(response.data.totalElements);
    } catch (error) {
      console.error("Erro ao obter árvores:", error);
    }
  }

  async function getCableSpacers() {
    try {
      const response = await api.get(`/projects/${projectId}/cable-spacers`);
      setCableSpacers(response.data.totalElements);
    } catch (error) {
      console.error("Erro ao obter Espaçadores:", error);
    }
  }

  async function getTotalDistance() {
    try {
      const response = await api.get(`/projects/${projectId}/distance`);
      setTotalDistance(response.data);
    } catch (error) {
      console.error("Erro ao obter distância total:", error);
    }
  }

  async function getTotalTime() {
    try {
      const response = await api.get(`/projects/${projectId}/time`);
      setTotalTime(response.data);
    } catch (error) {
      console.error("Erro ao obter tempo total:", error);
    }
  }

  // Obtenção dos usuários relacionados ao projeto
  const getProjectUsers = () => {
    // Requisição para obter os pontos do mapa referentes ao projeto
    fetch(apiUrl + "/projects/" + projectId + "/users?size=10000", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        setProjectUsers(pulist);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {}, [projectUsers]);

  // Obtenção dos pontos do mapa
  const getMapPoints = async () => {
    // Requisição para obter os pontos do mapa referentes ao projeto
    try {
      const { data } = await api.get(`/projects/${projectId}/poles?size=10000`);

      setMapPoints(data.content);
    } catch (error) {
      console.error(error);
    }
  };

  // relaciona um usuário a um projeto
  const addProjectUsers = (idProject, userId) => {
    console.log(idProject, userId);
    // Requisição para obter os usuários da empresa relacionada
    fetch(apiUrl + "/projects/" + idProject + "/add-user/" + userId, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        console.log("Adicionado");
        setModalNotificacao("Usuario adicionado ao projeto.");
      })
      .catch((error) => {
        setModalNotificacao(error.message.toString());
        console.log(error);
      })
      .finally(() => {
        setModalConfirmAddUserProject(false);
        setUpdatePage(Math.random());
        setCompanyUsers(null);
        getCompanyUsers(projectData.company_id);
      });
  };

  // remove o relacionamento de um usuário a um projeto
  const removeProjectUsers = (idProject, userId) => {
    console.log(idProject, userId);
    // Requisição para obter os usuários da empresa relacionada
    fetch(apiUrl + "/projects/" + idProject + "/remove-user/" + userId, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        console.log("Removido");
        setModalNotificacao("Usuario removido do projeto.");
      })
      .catch((error) => {
        setModalNotificacao(error.message.toString());
        console.log(error);
      })
      .finally(() => {
        setModalConfirmRemoveUserProject(false);
        setUpdatePage(Math.random());
        setCompanyUsers(null);
        getCompanyUsers(projectData.company_id);
      });
  };

  // Obtenção dos colaboradores da empresa
  const getCompanyUsers = (idCompany) => {
    // Requisição para obter os usuários da empresa relacionada
    fetch(apiUrl + "/companies/" + idCompany + "/employees?size=10000", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        let list = pulist.content;
        try {
          list.sort(function (a, b) {
            if (a["first_name"] < b["first_name"]) {
              return -1;
            } else {
              return true;
            }
          });
        } catch (error) {}
        setCompanyUsers(list);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  // Formata a string de data vinda da API para o formato UTC
  const convertUTC = (stringDate) => {
    var date = new Date(stringDate);
    return date.toISOString();
  };

  // Finalização do projeto
  const finalizarProjeto = (end) => {
    //Define a mensagem do modal de finalização como sendo o indicador de carregamento
    setModalFinalizarProjetoContent({
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footerConfirm: false,
      footerCancel: false,
      footerOk: false,
    });

    // Formata os dados para o padrão da API
    let auxProjectData = projectData;
    if (end) {
      let d = new Date(Date.now());
      let d2 = new Date(d.valueOf() - d.getTimezoneOffset() * 60000);

      auxProjectData["real_end_date"] = convertUTC(d2.toISOString());
    } else {
      auxProjectData["real_end_date"] = null;
    }

    // Realiza a requisição a API para a edição do status do projeto
    fetch(apiUrl + "/projects/" + projectId, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(auxProjectData),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        // Caso positivo exibe a mensagem de confirmação
        setModalFinalizarProjetoContent({
          body: end ? "Projeto finalizado." : "Projeto reaberto.",
          footerConfirm: false,
          footerCancel: false,
          footerOk: true,
        });
      })
      .finally(() => {})
      .catch((error) => {
        // Caso negativo exibe a mensagem de erro
        setModalFinalizarProjetoContent({
          body: end
            ? "Houve um erro ao finalizar o projeto: " + error.toString()
            : "Houve um erro ao reabrir o projeto: " + error.toString(),
          footerConfirm: false,
          footerCancel: false,
          footerOk: true,
        });
        console.error("Error:", error);
      });
  };

  // Ao receber o token de autenticação, realiza a requisição para
  // obter os dados do projeto.
  useEffect(() => {
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePage, userPermission]);

  useEffect(() => {}, [projectData, mapPoints]);

  //Verifica se o usuário está autenticado
  if (!CheckAuth()) {
    return <Navigate to="/login" />;
  }

  //Formata um número para o padrão de dois números
  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  //Obtenção dos pontos extremos e centralização do mapa
  const centerMapPoints = (element, detections) => {
    let latMax = null;
    let latMin = null;
    let lngMax = null;
    let lngMin = null;
    let latCenter = 0;
    let lngCenter = 0;
    let diffLat = 0;
    let diffLng = 0;
    let zoomLevel = 1;
    let pointsCenter;
    let latManualDiff = 0;
    let lngManualDiff = 0;

    if (detections) {
      element.map((point) => {
        if (!latMax) latMax = point.latitude;
        if (!latMin) latMin = point.latitude;
        if (latMax < point.latitude) latMax = point.latitude;
        else if (latMin > point.latitude) latMin = point.latitude;

        if (!lngMax) lngMax = point.longitude;
        if (!lngMin) lngMin = point.longitude;
        if (lngMax < point.longitude) lngMax = point.longitude;
        else if (lngMin > point.longitude) lngMin = point.longitude;
        return true;
      });
      latCenter = (latMax + latMin) / 2;
      lngCenter = (lngMax + lngMin) / 2;

      diffLat = latMax - latMin;
      diffLng = lngMax - lngMin;

      if (diffLat < diffLng) diffLat = diffLng;
    } else {
      if (element.areas_of_interest.length > 0) {
        element.areas_of_interest.forEach((ime) => {
          ime.forEach((imePoint) => {
            if (!latMax || latMax < imePoint[0]) {
              latMax = imePoint[0];
            }
            if (!latMin || latMin > imePoint[0]) {
              latMin = imePoint[0];
            }
            if (!lngMax || lngMax < imePoint[1]) {
              lngMax = imePoint[1];
            }
            if (!lngMin || lngMin > imePoint[1]) {
              lngMin = imePoint[1];
            }
          });
        });
        latCenter = (latMax + latMin) / 2;
        lngCenter = (lngMax + lngMin) / 2;
      } else if (
        element &&
        element.city_details &&
        element.city_details.latitude &&
        element.city_details.longitude
      ) {
        latCenter = element.city_details.latitude;
        lngCenter = element.city_details.longitude;
      } else {
        latCenter = -20.320313101854854;
        lngCenter = -40.338304255180965;
      }
    }
    latManualDiff = 0.0005;
    if (diffLat * 10000 < 5) {
      zoomLevel = 13;
      latManualDiff = 0.005;
    } else if (diffLat * 10000 < 10) {
      zoomLevel = 17;
      latManualDiff = 0.0007;
    } else if (diffLat * 10000 < 70) {
      zoomLevel = 16;
      latManualDiff = 0.0015;
    } else if (diffLat * 10000 < 150) {
      zoomLevel = 15;
      latManualDiff = 0.002;
    } else if (diffLat * 10000 < 300) {
      zoomLevel = 14;
      latManualDiff = 0.003;
    } else if (diffLat * 10000 < 500) {
      zoomLevel = 13;
    } else if (diffLat * 10000 < 1000) {
      zoomLevel = 12;
    } else if (diffLat * 10000 < 10000) {
      zoomLevel = 10;
    } else if (diffLat * 10000 < 35000) {
      zoomLevel = 9;
    } else if (diffLat * 10000 < 50000) {
      zoomLevel = 8;
    } else if (diffLat * 10000 < 80000) {
      zoomLevel = 7;
    } else if (diffLat * 10000 < 100000) {
      zoomLevel = 6;
    } else if (diffLat * 10000 < 150000) {
      zoomLevel = 5;
    } else zoomLevel = 3;
    pointsCenter = [latCenter - latManualDiff, lngCenter - lngManualDiff];
    return {
      pointsCenter: pointsCenter,
      autoZoom: zoomLevel,
    };
  };

  //Constrói o layout da página
  return (
    <div className="p-2">
      <Header />

      {loadingIcon()}

      {/* Início modal de notificação */}
      <Modal // Modal de notificação
        isOpen={modalNotificacao ? true : false}
      >
        <ModalHeader>Aviso</ModalHeader>
        <ModalBody>
          <div className="row m-1">
            {modalNotificacao ? modalNotificacao : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={
              modalNotificacao && modalNotificacao.actionButton
                ? modalNotificacao.actionButton
                : () => setModalNotificacao(false)
            }
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      {/* Fim modal de notificação */}

      <Modal
        isOpen={modalConfirmAddUserProject ? true : false}
        toggle={() => setModalConfirmAddUserProject(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalConfirmAddUserProject(false)}>
          <div className="row m-0">
            <div className="col m-auto">Usuários</div>
          </div>
        </ModalHeader>
        <ModalBody>Deseja adicionar o usuário ao projeto?</ModalBody>

        <ModalFooter>
          <Button
            outline={true}
            color="secondary"
            onClick={() => setModalConfirmAddUserProject(false)}
          >
            Cancelar
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              addProjectUsers(projectData.id, modalConfirmAddUserProject)
            }
          >
            Adicionar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalConfirmRemoveUserProject ? true : false}
        toggle={() => setModalConfirmRemoveUserProject(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalConfirmRemoveUserProject(false)}>
          <div className="row m-0">
            <div className="col m-auto">Usuários</div>
          </div>
        </ModalHeader>
        <ModalBody>Deseja desvincular o usuário do projeto?</ModalBody>

        <ModalFooter>
          <Button
            outline={true}
            color="secondary"
            onClick={() => setModalConfirmRemoveUserProject(false)}
          >
            Cancelar
          </Button>
          <Button
            color="danger"
            onClick={() =>
              removeProjectUsers(projectData.id, modalConfirmRemoveUserProject)
            }
          >
            Remover
          </Button>
        </ModalFooter>
      </Modal>

      {/* Begin modal add users project */}
      <Modal
        isOpen={modalProjectUsers}
        toggle={() => setModalProjectUsers(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalProjectUsers(false)}>
          <div className="row m-0">
            <div className="col m-auto">Usuários</div>
          </div>
        </ModalHeader>
        <ModalBody>
          {companyUsers && companyUsers.length > 0 ? (
            companyUsers.map((userCompany) => {
              if (
                projectUsers.filter(
                  (userProject) => userProject.id === userCompany.id
                ).length > 0
              ) {
                return false;
              } else {
                return (
                  <ListGroup key={Math.random()}>
                    <ListGroupItem href="#" tag="a">
                      <Row>
                        <Col className="col-8">
                          <div className="font-weight-bold">
                            {userCompany.first_name && userCompany.first_name}
                          </div>
                          {userCompany.email && (
                            <div>
                              <small
                                style={{
                                  wordWrap: "break-word",
                                  fontSize: "0.8em",
                                }}
                              >
                                ({userCompany.email})
                              </small>
                            </div>
                          )}
                        </Col>
                        <Col sm={4} className="row col-4">
                          <Row className=" p-0 m-auto">
                            <Button
                              className="ml-auto pt-0 pb-0"
                              onClick={() => {
                                return setModalConfirmAddUserProject(
                                  userCompany.id
                                );
                              }}
                            >
                              <small>Adicionar</small>
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                );
              }
            })
          ) : companyUsers && companyUsers === 0 ? (
            "Nenhum usuário disponível"
          ) : (
            <Row>
              <Spinner
                color="primary"
                className="m-auto"
                style={{
                  // margin: "auto",
                  width: "30px",
                  height: "30px",
                }}
              >
                Loading...
              </Spinner>
            </Row>
          )}
        </ModalBody>
      </Modal>
      {/* End modal add users project */}

      {/* Begin modal close project */}
      <Modal
        isOpen={modalFinalizarProjeto}
        toggle={() => setModalFinalizarProjeto(false)}
        backdrop="static"
      >
        <ModalHeader>
          <div className="row m-0">
            <div className="col m-auto">Status do projeto</div>
          </div>
        </ModalHeader>
        <ModalBody>
          {modalFinalizarProjetoContent && modalFinalizarProjetoContent.body
            ? modalFinalizarProjetoContent.body
            : ""}
        </ModalBody>

        <ModalFooter>
          {modalFinalizarProjetoContent &&
          modalFinalizarProjetoContent.footerCancel ? (
            <Button
              color="secondary"
              onClick={() => setModalFinalizarProjeto(false)}
            >
              Cancelar
            </Button>
          ) : (
            ""
          )}
          {modalFinalizarProjetoContent &&
          modalFinalizarProjetoContent.footerOk ? (
            <Button
              color="secondary"
              onClick={() => window.location.reload(false)}
            >
              Ok
            </Button>
          ) : (
            ""
          )}
          {modalFinalizarProjetoContent &&
          modalFinalizarProjetoContent.footerConfirm ? (
            projectData.status !== "Finalizado" ? (
              <Button color="success" onClick={() => finalizarProjeto(true)}>
                Finalizar
              </Button>
            ) : (
              <Button color="success" onClick={() => finalizarProjeto(false)}>
                Reabrir
              </Button>
            )
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      {/* End modal close project */}
      <Tooltip id="my-tooltip" />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4 m-0">
              <Row className="m-0 p-0">
                <Col className="mt-auto mb-auto ms-0">
                  <h2 className="m-0 text-start">
                    {/* Inicio botão voltar pagina */}
                    <div className="m-2 ms-0">
                      <Link
                        className="text-end"
                        style={{ marginRight: "0px" }}
                        to={"/home"}
                      >
                        <BsArrowLeft
                          className="nav-button-circle "
                          style={{ boxShadow: "none" }}
                        />
                      </Link>
                      {projectData ? projectData.name : "Projeto #" + projectId}
                    </div>
                    {/* Final Botão voltar pagina */}
                  </h2>
                </Col>
              </Row>
            </div>

            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }}>
                <hr />
                <Container>
                  <Row>
                    <div
                      className="p-0"
                      style={{
                        backgroundColor: "white",
                        width: "100%",
                        zIndex: "1000",
                      }}
                    >                     
                     
                        <Card
                        className="m-2 p-1"
                        style={{ 
                          minHeight: "300px",
                          borderRadius: "15px", // Arredondamento das bordas
                          overflow: "hidden",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra para destaque
                          }}
                      >
                        <Row>
                        <Col lg={7} md={6} sm={12} 
                        style={{
                          marginTop: "10px",
                          marginLeft: "20px",
                          marginBottom: "10px",
                          borderRadius:"20px",
                          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.5)",
                          padding:0,
                        }}>
                        {dadosCarregados ? (
                        <MapContainer
                          className="m-0"
                          center={
                            mapPoints && mapPoints.length > 0
                              ? centerMapPoints(mapPoints, true).pointsCenter
                              : projectData && projectData.areas_of_interest
                              ? centerMapPoints(projectData).pointsCenter
                              : [0, 0]
                          }
                          zoom={
                            mapPoints && mapPoints.length > 0
                              ? centerMapPoints(mapPoints, true).autoZoom
                              : projectData && projectData.areas_of_interest
                              ? centerMapPoints(projectData).autoZoom
                              : 15
                          }
                          doubleClickZoom={false}
                          scrollWheelZoom={false}
                          style={{
                            borderRadius: "20px",
                            position: "relative",
                            width: "100%",
                            height: "400px",                          
                          }}
                          dragging={false}
                        >
                          <TileLayer
                            key={Math.random()}
                            attribution={mapSourcePreview.attribution}
                            url={mapSourcePreview.url}
                            subdomains={mapSourcePreview.subDomains}
                          />

                          {projectData
                            ? projectData.areas_of_interest
                              ? projectData.areas_of_interest.map(
                                  (point, cont = 0) => {
                                    return (
                                      <Polygon
                                        key={Math.random()}
                                        positions={point}
                                        pathOptions={{
                                          color: colorLayersList[cont][1],
                                        }}
                                        eventHandlers={{
                                          click: (record) => {
                                            return null;
                                          },
                                        }}
                                      ></Polygon>
                                    );
                                  }
                                )
                              : "Não foi possível carregar o mapa"
                            : ""}

                          {mapPoints
                            ? mapPoints.map((point) => {
                                return (
                                  <Circle
                                    key={Math.random()}
                                    center={[point.latitude, point.longitude]}
                                    fillColor={selectedPointColor}
                                    radius={defaultRadiusMarker}
                                    fillOpacity={0.6}
                                    color={selectedPointColor}
                                    eventHandlers={{
                                      click: (record) => {
                                        return null;
                                      },
                                    }}
                                  ></Circle>
                                );
                              })
                            : ""}
                        </MapContainer>
                         ) : (
                          ""
                        )}
                        </Col>
                          <Col lg={4} md={4} sm={12}
                          style={{
                            marginTop: "20px",
                            marginLeft: "10px",
                            paddingBottom: "10px",
                          }}>
                          <Row><h3>{projectData.name}</h3></Row>
                          <div className="d-flex align-items-center" style={{ color: "#bfbfbf" }}>
                            <BsPinAngle className="me-2" />
                            <span>
                              {projectData.city &&
                              projectData.city !== "null" &&
                              projectData.city !== "false"
                                ? projectData.city
                                : "Cidade não definida"}
                            </span>
                          </div>
                          {/* Primeira linha de botões */}
                            <Row className="m-0 p-0 justify-content-center">
                            <Col className="text-center m-2">
                                  <div style={{marginLeft:"20px", marginTop:"60px"}}>
                                    <Link
                                      className="btn btn-light d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                      }}
                                      to={"/novamissao/" + projectId}
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Criar uma nova missão"
                                    >
                                      <AddIconButton isWhite="true" width="35px" height="35px" />
                                    </Link>
                                    <div style={{ fontSize: "12px", marginTop: "5px", marginLeft:"7px", textAlign: "start" }}>Missão</div>
                                  </div>
                                </Col>
                              <Col className="text-center m-2">
                                <div style={{marginLeft:"20px", marginTop:"60px"}}>
                                  <Link
                                    className="btn btn-light d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                    }}
                                    to={"/mapa/projetos/" + projectId}
                                  >
                                    <MapIconButton width="35px" height="35px" />
                                  </Link>
                                  <div style={{ fontSize: "12px", marginTop: "5px", paddingLeft: "8px", textAlign: "start" }}>Mapa</div>
                                </div>
                              </Col>
                              <Col className="text-center m-2">
                                <div style={{marginLeft:"20px", marginTop:"60px"}}>
                                  <Link
                                    className="btn btn-light d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                    }}
                                    to={"/relatorios/" + projectId}
                                  >
                                    <ReportsIconButton width="35px" height="35px" />
                                  </Link>
                                  <div style={{ fontSize: "12px", marginTop: "5px", textAlign: "start" }}>Relatórios</div>
                                </div>
                              </Col>
                            </Row>
                            
                            {/* Segunda linha de botões */}
                            <Row className="m-0 p-0 justify-content-center" style={{ marginTop: "20px"}}>
                              
                                <Col className="text-center m-2" style={{ zIndex: 10000 }}>
                                  
                                    {CheckPermission(
                                      userPermission && userPermission.role
                                        ? userPermission.role
                                        : "",
                                      "MANAGER"
                                    ) &&
                                    projectData &&
                                    projectData.status !== "Finalizado" ? (
                                      <div style={{marginLeft:"80px"}}>
                                        <Link
                                        className="btn btn-light d-flex justify-content-center align-items-center"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                        }}
                                        to={"/editarprojeto/" + projectData.id}
                                      >
                                        <EditIconButton width="35px" height="35px" />
                                      </Link>
                                      <div style={{ fontSize: "12px", marginTop: "5px", paddingLeft: "8px", textAlign: "start" }}>Editar</div>
                                    </div>
                                    ) : (
                                      ""
                                    )}                                  
                                </Col>

                                <Col className="text-center m-2" style={{ zIndex: 10000 }}>
                                  {CheckPermission(
                                      userPermission && userPermission.role
                                        ? userPermission.role
                                        : "",
                                      "MANAGER"
                                    ) ? (
                                      projectData && projectData.status !== "Finalizado" ? (
                                        <Row style={{marginLeft:"20px"}}>
                                          <Link
                                            className="btn btn-light d-flex justify-content-center align-items-center"
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                            }}
                                            onClick={() => {
                                              setModalFinalizarProjetoContent({
                                                body: "Deseja finalizar o projeto?",
                                                footerConfirm: true,
                                                footerCancel: true,
                                                footerOk: false,
                                              });
                                              return setModalFinalizarProjeto(true);
                                            }}
                                          >
                                              <FolderIconButton width="20px" height="20px" />
                                          </Link>
                                        </Row>
                                      ) : (
                                        <Row>
                                          <Link
                                            className="mt-2 mb-2 ms-auto text-end"
                                            onClick={() => {
                                              setModalFinalizarProjetoContent({
                                                body: "Deseja reabrir o projeto?",
                                                footerConfirm: true,
                                                footerCancel: true,
                                                footerOk: false,
                                              });
                                              return setModalFinalizarProjeto(true);
                                            }}
                                            style={{ width: "auto" }}
                                          >
                                            <Row
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content="Reabrir o projeto"
                                            >
                                              <div className="col m-auto">Reabrir</div>
                                              <div className="m-auto nav-button-circle">
                                                <BsFolderFill />
                                              </div>
                                            </Row>
                                          </Link>
                                        </Row>
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <div style={{ fontSize: "12px", marginTop: "5px", marginLeft: "20px", textAlign: "start" }}>Finalizar</div>
                                </Col>

                            <Col
                                  className="text-end"
                                  style={{
                                    position: "relative",
                                    marginLeft: "200px",
                                    paddingLeft: "250px",
                                    top: "50%", // Centraliza verticalmente
                                    transform: "translateY(30%)", // Corrige o posicionamento vertical
                                    zIndex: 1,
                                  }}
                                >
                                  {projectData.project_type && projectData.project_type === "GROUND" ? (
                                    <CamIconButton />
                                  ) : projectData.project_type === "AERIAL" ? (
                                    <DroneIconButton />
                                  ) : projectData.project_type === "AERIAL_GROUND" ? (
                                    <CamDroneIconButton />
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              
                              {/* Ícone Ground alinhado à direita */}
                              
                            </Row>
                            </Col>
                          </Row>
                        </Card>
                     
                      
                      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                      <Row className="m-0 ps-3 pe-3">
                          {projectData &&
                          projectData.description &&
                          projectData.description.length > 1
                            ? projectData.description
                            : ""}
                        </Row>
                        <Row className="m-0 ps-1 pe-1 fw-bold align-items-center" >
                          <Col xs="auto" style={{ marginTop: "30px"}}>
                           <h3>Detalhes</h3> 
                          </Col>
                          <Col style={{ marginTop: "30px"}}>
                            <hr className="mt-0 mb-0" />
                          </Col>
                        </Row>
                        <Row className="m-0 ps-1 pe-1 fw-bold">
                        </Row>
                        <Row className="p-3">
                        <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-status"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-status"
                              data-tooltip-content="Status"
                            >
                              <CardBody>
                                <StatusIconButton width="40px" height="40px"/>
                                <CardText>
                                  {projectData ? projectData.status : "Sem Previsão"}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-description"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-description"
                              data-tooltip-content="Descrição"
                            >
                              <CardBody>
                                <DescriptionIconButton  width="40px" height="40px"/>
                                <CardText>
                                {projectData && projectData.description
                                    ? projectData.description
                                    : "N/D"}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-city"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-city"
                              data-tooltip-content="Cidade"
                            >
                              <CardBody>
                                <CityIconButton width="40px" height="40px" />
                                <CardText>
                                {projectData && projectData.city
                                    ? projectData.city
                                    : "N/D"}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                        <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-Date"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-Date"
                              data-tooltip-content="Data de inicio e fim"
                            >
                              <CardBody>
                                <DateIconButton width="40px" height="40px" />
                                <CardText>
                                {projectData && projectData.start_date
                                  ? formatData(projectData.start_date)
                                  : "N/D"}
                                  <br />
                                {projectData && projectData.real_end_date
                                  ? formatData(projectData.real_end_date)
                                  : ""}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-Users"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-Users"
                              data-tooltip-content="Usuários"
                              style={{ cursor: "pointer" }}
                              onClick={handleOpenModal} // <-- abre o modal ao clicar no card
                            >
                              <CardBody>
                                <UsersIconButton width="40px" height="40px" />
                                <CardText>
                                  {projectUsers && projectUsers.length > 0
                                    ? projectUsers.length + " Usuários"
                                    : "N/D"}
                                </CardText>
                              </CardBody>
                            </Card>
                            {/* Modal que exibe detalhes do projeto, usuários, etc. */}
                            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="lg">
                              <ModalHeader toggle={handleCloseModal}>
                                Usuários relacionados
                              </ModalHeader>

                              <ModalBody>
                                <ListGroup flush>
                                  <ListGroupItem className="pe-0 ps-0">
                                    <Row className="m-0 p-0">
                                      {/* Cabeçalho do bloco de usuários */}
                                      <Col className="m-0 p-0 ps-3" sm={12} lg={4}>
                                        <Row className="m-0 mb-1">
                                          <Col className="p-0 m-0">
                                            <b>Usuários relacionados</b>
                                          </Col>
                                          {CheckPermission(
                                            userPermission && userPermission.role
                                              ? userPermission.role
                                              : "",
                                            "MANAGER"
                                          ) && (
                                            <Col className="col-3 row">
                                              <Button
                                                className="btn-white p-0 ms-auto me-3 rounded-circle"
                                                style={{
                                                  width: "25px",
                                                  height: "25px",
                                                }}
                                                onClick={() => {
                                                  // Exemplo: pega usuários da empresa e abre modal adicional
                                                  getCompanyUsers(projectData.company_id);
                                                  setModalProjectUsers(true);
                                                }}
                                              >
                                                +
                                              </Button>
                                            </Col>
                                          )}
                                        </Row>
                                      </Col>

                                      <Col className="m-0 p-0">
                                        {/* Lista de usuários do projeto */}
                                        {projectUsers && projectUsers.length > 0 ? (
                                          <Row className="m-0 p-0">
                                            {/* Primeiro usuário */}
                                            <Link
                                              key={Math.random()}
                                              className="btn-outline-secondary col-sm-12 p-0 pr-1 me-2"
                                            >
                                              <Row className="m-0 p-0 ps-3">
                                                <Col className="m-0 p-0">
                                                  {projectUsers[0].first_name}
                                                </Col>
                                                {CheckPermission(
                                                  userPermission && userPermission.role
                                                    ? userPermission.role
                                                    : "",
                                                  "MANAGER"
                                                ) && (
                                                  <Col className="row m-0 pe-0 col-4">
                                                    <BsTrash
                                                      className="m-auto"
                                                      onClick={() => {
                                                        setModalConfirmRemoveUserProject(
                                                          projectUsers[0].id
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                )}
                                              </Row>
                                            </Link>

                                            {/* Demais usuários (collapse) */}
                                            <UncontrolledCollapse
                                              toggler="#toggler"
                                              className="m-0 p-0"
                                            >
                                              <Row className="m-0 p-0">
                                                {projectUsers.map((user, cont) => {
                                                  if (cont !== 0) {
                                                    return (
                                                      <Link
                                                        key={Math.random()}
                                                        className="btn-outline-secondary col-sm-12 p-0 pr-1 me-2"
                                                      >
                                                        <hr
                                                          className="p-0 mt-1 mb-1 me-0 ms-0"
                                                          style={{ opacity: "0.15" }}
                                                        />
                                                        <Row className="m-0 p-0 ps-3">
                                                          <Col className="m-0 p-0">
                                                            {user.first_name}
                                                          </Col>
                                                          {CheckPermission(
                                                            userPermission && userPermission.role
                                                              ? userPermission.role
                                                              : "",
                                                            "MANAGER"
                                                          ) && (
                                                            <Col className="row m-0 pe-0 col-4 ">
                                                              <BsTrash
                                                                className="m-auto"
                                                                onClick={() => {
                                                                  setModalConfirmRemoveUserProject(
                                                                    user.id
                                                                  );
                                                                }}
                                                              />
                                                            </Col>
                                                          )}
                                                        </Row>
                                                      </Link>
                                                    );
                                                  }
                                                  return false;
                                                })}
                                              </Row>
                                            </UncontrolledCollapse>

                                            {/* Botão "Mostrar todos" para expandir */}
                                            {buttonMoreUsersList && (
                                              <Button
                                                color="link"
                                                id="toggler"
                                                className="p-0 m-0 col-12 row"
                                                onClick={() => {
                                                  setButtonMoreUsersList(false);
                                                }}
                                              >
                                                <div
                                                  className="m-auto"
                                                  style={{
                                                    fontSize: "0.8em",
                                                    color: "black",
                                                    textDecoration: "underline black",
                                                  }}
                                                >
                                                  <small>Mostrar todos</small>
                                                  <BsChevronDoubleDown />
                                                </div>
                                              </Button>
                                            )}
                                          </Row>
                                        ) : (
                                          <Row className="m-0 p-3 pt-0 pb-0">
                                            Nenhum colaborador vinculado.
                                          </Row>
                                        )}
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                </ListGroup>
                              </ModalBody>

                              <ModalFooter>
                                <Button color="secondary" onClick={handleCloseModal}>
                                  Fechar
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-distance"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-distance"
                              data-tooltip-content="Distancia Total"
                            >
                              <CardBody>
                                <DistanceIconButton width="40px" height="40px" />
                                <CardText>
                                  {totalDistance.toFixed(2) || 0} Km
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-time"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-time"
                              data-tooltip-content="Tempo Total"
                            >
                              <CardBody>
                                <TimeIconButton width="40px" height="40px" />
                                <CardText>{totalTime || 0} Minutos</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-poles"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-poles"
                              data-tooltip-content="Postes"
                            >
                              <CardBody>
                                <PoleIconButton width="40px" height="40px"/>
                                <CardText>{poles || 0}</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-trees"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-trees"
                              data-tooltip-content="Árvores"
                            >
                              <CardBody>
                                <TreesIconButton width="40px" height="40px"/>
                                <CardText>{trees || 0}</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-lamp"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-lamp"
                              data-tooltip-content="Lampadas"
                            >
                              <CardBody>
                                <LampIconButton width="40px" height="40px"/>
                                <CardText>{componentPoles.lamps || 0}</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-transformer"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-transformer"
                              data-tooltip-content="Transformadores"
                            >
                              <CardBody>
                                <TransformerIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.transformers || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-insulator"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-insulator"
                              data-tooltip-content="Isoladores"
                            >
                              <CardBody>
                                <InsulatorIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.insulators || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-crosshead"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-crosshead"
                              data-tooltip-content="Cruzetas"
                            >
                              <CardBody>
                                <CrossHeadIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.crossHeads || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-lamp-arm"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-lamp-arm"
                              data-tooltip-content="Braço para Lampadas"
                            >
                              <CardBody>
                                <LampArmIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.lampArms || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-dropout-fuse"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-dropout-fuse"
                              data-tooltip-content="Chaves"
                            >
                              <CardBody>
                                <DropOutFuseIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.dropOutFuses || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-wire-reserve"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-wire-reserve"
                              data-tooltip-content="Reservas de Fios"
                            >
                              <CardBody>
                                <WireReserveIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.wireReserves || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-cable"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-cable"
                              data-tooltip-content="Espaçadores"
                            >
                              <CardBody>
                                <CableSpacersIconButton width="40px" height="40px"/>
                                <CardText>{cableSpacers || 0}</CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-telecom"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-telecom"
                              data-tooltip-content="Caixas de Telecom"
                            >
                              <CardBody>
                                <TelecomBoxIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.telecomBoxes || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={2} md={4} sm={6} xs={12} className="mb-3">
                            <Tooltip
                              id="tooltip-fiber-optic"
                              place="top"
                              effect="solid"
                            />
                            <Card
                              className="text-center"
                              data-tooltip-id="tooltip-fiber-optic"
                              data-tooltip-content="Caixas de Fibra Óptica"
                            >
                              <CardBody>
                                <FiberOpticJunctionBoxIconButton width="40px" height="40px"/>
                                <CardText>
                                  {componentPoles.fiberOpticJunctionBoxes || 0}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                          {/* Adicione outros cards conforme necessário */}
                        </Row>
                        <Row className="m-0 ps-1 pe-1 fw-bold align-items-center">
                          <Col xs="auto">
                           <h3>Missões</h3> 
                          </Col>
                          <Col>
                            <hr className="mt-0 mb-0" />
                          </Col>
                        </Row>
                        <Missions />
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectDetails;
