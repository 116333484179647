import React from 'react';
import DateIcon from '../../assets/icons/DATA.svg';

const DateIconButton = ({ label, width = "20px", height = "20px"  }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={DateIcon} alt={label} data-testid="Date-icon" width={width} height={height}/>
    </button>
  );
};

export default DateIconButton;