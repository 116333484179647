import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import Header from "../../elements/Header";
import { BsMapFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Map.css";
import SideBarMapRight from "../../elements/SideBarMapRight";
import "leaflet-imageoverlay-rotated";

import ModalObjectDetails from "../../elements/ModalObjectDetails/ModalObjectDetails";
import ModalPoleComponents from "../../elements/ModalPoleComponents/ModalPoleComponents";
import MapContainerComponent from "../../elements/MapContainer/MapContainerComponent";
import PointsList from "../../elements/PointsList/PointsList";
import DetectionDetailsModal from "../../elements/DetectionDetailsModal/DetectionDetailsModal";
import ColorTableSubtitle from "../../elements/ColorTableSubtitle/ColorTableSubtitle";
import api from "../../api/api";
import ModalNotification from "../../elements/ModalNotification/ModalNotification";
import ModalSupervisionMode from "../../elements/ModalSupervisionMode/ModalSupervisionMode";
import { IoCar, IoCarOutline, IoMoveOutline } from "react-icons/io5";
import SearchBar from "../../elements/SearchBar/SearchBar";
import ModalFilterPoleComponents from "../../elements/ModalFilterPoleComponents/ModalFilterPoleComponents";
import ModalFiltersLamp from "../../elements/ModalFiltersLamp/ModalFiltersLamp";
import { IoMdMove } from "react-icons/io";
import { PiMapPinPlusFill } from "react-icons/pi";
import { PiMapPinPlusBold } from "react-icons/pi";
import ModalNewDetectedObject from "../../elements/ModalNewDetectedObject/ModalNewDetectedObject";
import ModalFiltersTrees from "../../elements/ModalFiltersTrees/ModalFiltersTrees";
import ModalMarkersMission from "../../elements/ModalMarkersMissions/ModalMarkersMission";
import { FaRoute } from "react-icons/fa";
import { CiAlignCenterH } from "react-icons/ci";

const defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
const defaultZoom = process.env.REACT_APP_DEFAULT_ZOOM;
const filterColorScale = JSON.parse(process.env.REACT_APP_COLOR_SCALE);

const Map = (props) => {
  document.title = "Map Page";
  const [numberValuesApi, setNumberValuesApi] = useState(150);
  const [areaOfInterest, setAreaOfInterest] = useState();
  const [pointDetailOffcanvas, setPointDetailOffcanvas] = useState();
  const [loading, setLoading] = useState(true);
  const [areasOfInterestActive, setAreasOfInterestActive] = useState(false);
  const [modalNotificacao, setModalNotificacao] = useState(false);
  const [enablePointsView, setEnablePointsView] = useState(true);
  const [enableTreePointsView, setEnableTreePointsView] = useState(false);
  const [enableCableSpacerPointsView, setEnableCableSpacerPointsView] =
    useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [viewPoleComponents, setViewPoleComponents] = useState(false);
  const [loadingVector, setLoadingVector] = useState();
  const [hideLeftMenu, setHideLeftMenu] = useState(false);
  const [moveMapPoints, setMoveMapPoints] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const toggle = () => setModalDetails(!modalDetails);
  const [offCanvas, setOffCanvas] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const [supervisionModeOpen, setSupervisionModeOpen] = useState(false);
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(null);
  let { id: projectId, type: typeVisualization } = useParams();

  const [offCanvasListPoints, setOffCanvasListPoints] = useState(() => {
    if (typeVisualization && typeVisualization === "list") return true;
    else return false;
  });

  const toggleOffCanvas = () => {
    if (!offCanvas) {
      closeAllMenus();
    }
    setOffCanvas(!offCanvas);
    setActivePointSupervision(null);
  };

  const toggleSupervisionMode = () => {
    if (!supervisionModeOpen) {
      closeAllMenus();
    }
    setSupervisionModeOpen(!supervisionModeOpen);
  };

  const toggleOffCanvasListPoints = () => {
    if (!offCanvasListPoints) {
      closeAllMenus();
    }
    setOffCanvasListPoints(!offCanvasListPoints);
  };

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoadingVector(loadingIcon());
      }, 1000);
    } else {
      setLoadingVector(loadingIcon());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeAllMenus = () => {
    setOffCanvas(false);
    setOffCanvasListPoints(false);
    setModalFilterPoleComponents(false);
    setModalFiltersLamp(false);
    setSupervisionModeOpen(false);
    setModalNotificacao(false);
    setViewPoleComponents(false);
    setPreviewImagesVisible(false);
    setNewPointPreviewMap(false);
    setModalDetails(false);
    setSidebarMenuOpen(null);
    setMoveMapPoints(false);
    setModalFiltersTrees(false);
    setModalMarkersMission(false);
    setAlignMarkers(false);
  };

  const loadingIcon = () => {
    return (
      <div
        key={Math.random()}
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  //Objetos filhos do poste - Captura das detecções e chamada da função para captura de imagem

  function openDetailsModal(poleId, imageArray, index = 0, pointDetails) {
    let details = {
      header: "Ponto de detecção n° " + poleId,
      imageArray: imageArray,
      index: index,
      pointDetails: pointDetails,
    };

    setModalContent(details);
    toggle();
  }

  let baseMaps = JSON.parse(process.env.REACT_APP_LEAFLET_BASEMAPS);

  const [baseMapSource, setBaseMapSource] = useState(baseMaps.Street);
  const [newPointPreviewMap, setNewPointPreviewMap] = useState(false);
  const [filterLamp, setFilterLamp] = useState([]);
  const [filterDistanceToCrossHead, setFilterDistanceToCrossHead] = useState(0);

  const [mapPoints, setMapPoints] = useState([]);
  const [trees, setTrees] = useState([]);
  const [cableSpacers, setCableSpacers] = useState([]);

  const [lastPagePoles, setLastPagePoles] = useState(false);
  const [lastPageTrees, setLastPageTrees] = useState(false);
  const [pageNoTrees, setPageNoTrees] = useState(0);
  const [lastPageCableSpacers, setLastPageCableSpacers] = useState(false);
  const [pageNoCableSpacers, setPageNoCableSpacers] = useState(0);
  const [newPointPosition, setNewPointPosition] = useState([]);

  const [activePointSupervision, setActivePointSupervision] = useState(null);

  const [previewImagesVisible, setPreviewImagesVisible] = useState(false);
  const [previewImagesNewPoint, setPreviewImagesNewPoint] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [carPath, setCarPath] = useState(false);
  const [carPathMapPoints, setCarPathMapPoints] = useState([]);
  const [modalFilterPoleComponents, setModalFilterPoleComponents] =
    useState(false);
  const [filterPoleComponentsValue, setFilterPoleComponentsValue] =
    useState("");
  const [filteredMapPoints, setFilteredMapPoints] = useState([]);
  const [selectedColorPoleComponents, setSelectedColorPoleComponents] =
    useState("");
  const [modalFiltersLamp, setModalFiltersLamp] = useState(false);

  const [modalMarkersMission, setModalMarkersMission] = useState(false);
  const [missions, setMissions] = useState([]);
  const [loadingMissions, setLoadingMissions] = useState(false);
  const [selectedMissions, setSelectedMissions] = useState([]);

  const [modalFiltersTrees, setModalFiltersTrees] = useState(false);

  const [loadingCollision, setLoadingCollision] = useState(false);

  const [criticalDistanceTrees, setCriticalDistanceTrees] = useState([]);
  const [fallRiskTrees, setFallRiskTrees] = useState([]);

  const [enableCriticalDistanceTrees, setEnableCriticalDistanceTrees] =
    useState(false);
  const [enableFallRiskTrees, setEnableFallRiskTrees] = useState(false);

  const [alignMarkers, setAlignMarkers] = useState(false);

  const toggleNewPointPreviewMap = () => {
    if (!newPointPreviewMap) {
      closeAllMenus();
    }
    setNewPointPreviewMap(!newPointPreviewMap);
  };

  const toggleModalFilterPoleComponents = () => {
    if (!modalFilterPoleComponents) {
      closeAllMenus();
    }
    setModalFilterPoleComponents(!modalFilterPoleComponents);
  };

  const toggleModalFiltersLamp = () => {
    if (!modalFiltersLamp) {
      closeAllMenus();
    }
    setModalFiltersLamp(!modalFiltersLamp);
  };

  const toggleModalMarkersMission = () => {
    setModalMarkersMission(!modalMarkersMission);
  };

  const toggleModalAlignMarkers = () => {
    setAlignMarkers(!alignMarkers);
  };

  const toggleModalFiltersTrees = () => {
    if (!modalFiltersTrees) {
      setModalFiltersTrees(!modalFiltersTrees);
      setEnableTreePointsView(true);
    }

    setModalFiltersTrees(!modalFiltersTrees);
  };

  const handleTreeFilterSave = async (config) => {
    setLoadingCollision(true);

    const { criticalVerticalDistance, criticalHorizontalDistance } = config;

    try {
      const response = await api.get(`projects/${projectId}/trees/collide`, {
        params: {
          criticalVerticalDistance,
          criticalHorizontalDistance,
        },
      });

      const { criticalDistanceTrees, fallRiskTrees } = response.data;

      setCriticalDistanceTrees(criticalDistanceTrees);
      setFallRiskTrees(fallRiskTrees);

      setTrees((prevTrees) =>
        prevTrees.map((tree) => {
          if (criticalDistanceTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: "red" };
          }
          if (fallRiskTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: "orange" };
          }
          return { ...tree };
        })
      );

      setEnableCriticalDistanceTrees(criticalDistanceTrees.length > 0);
      setEnableFallRiskTrees(fallRiskTrees.length > 0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCollision(false);
    }
  };

  useEffect(() => {
    if (!newPointPreviewMap && previewImagesNewPoint.length > 0) {
      setPreviewImagesVisible(false);
    }
  }, [previewImagesNewPoint, newPointPreviewMap]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (enableTreePointsView && !lastPageTrees) {
          await fetchDetectedObjectsPoints(
            pageNoTrees,
            setTrees,
            setPageNoTrees,
            setLastPageTrees,
            "trees"
          );
        }

        if (enableCableSpacerPointsView && !lastPageCableSpacers) {
          await fetchDetectedObjectsPoints(
            pageNoCableSpacers,
            setCableSpacers,
            setPageNoCableSpacers,
            setLastPageCableSpacers,
            "cable-spacers"
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastPageTrees,
    pageNoTrees,
    enableTreePointsView,
    enableCableSpacerPointsView,
    projectId,
    numberValuesApi,
    trees,
    cableSpacers,
  ]);

  useEffect(() => {
    const fetchPolesRecursive = async (page) => {
      if (lastPagePoles) return;

      try {
        const { data } = await api.get(
          `/projects/${projectId}/poles?page=${page}&size=150`
        );

        setMapPoints((prev) => [...prev, ...assignVisibleProp(data.content)]);

        if (page === 0) {
          setLoading(false);
        }

        if (data.last) {
          setLastPagePoles(true);
        } else {
          fetchPolesRecursive(page + 1);
        }
      } catch (error) {
        console.error(error);
        if (page === 0) setLoading(false);
      }
    };

    const fetchPoles = async () => {
      setLoading(true);
      await fetchPolesRecursive(0);
    };

    fetchPoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const assignVisibleProp = (data) => {
    return data?.map((d) => {
      return { ...d, enabled: true };
    });
  };

  const fetchDetectedObjectsPoints = async (
    pageNo,
    setObjectState,
    setPageNoState,
    setLastPageState,
    endpoint
  ) => {
    const response = await api.get(
      `/projects/${projectId}/${endpoint}?page=${pageNo}&size=${numberValuesApi}`
    );

    const responseData = response.data;

    setLastPageState(responseData.last);
    setPageNoState(responseData.number + 1);

    const content = assignVisibleProp(responseData.content);

    setObjectState((prev) => [...prev, ...content]);
  };

  useEffect(() => {
    async function getAreasOfInterest() {
      // Início obtém pontos do desenho "areas_of_interest"
      try {
        const areasOfInterestResponse = await api.get(`/projects/${projectId}`);

        const report = areasOfInterestResponse.data;
        setProjectDetails(report);
        setAreaOfInterest(report["areas_of_interest"]);
      } catch (error) {
        console.error(error);
      }
      // Fim obtém pontos do desenho "areas_of_interest"
    }

    getAreasOfInterest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    async function getMissions() {
      setLoadingMissions(true);
      try {
        const { data } = await api.get(
          `/projects/${projectId}/missions-detected-objects?sort=name&size=2000`
        );

        const missions = data.content;

        setMissions(missions);
        setSelectedMissions(missions.map(({ missionId }) => missionId));
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingMissions(false);
      }
    }

    getMissions();
  }, []);

  useEffect(() => {
    async function getFilterData() {
      let searchCriteria = {
        led: false,
        allDayOn: false,
        distanceToCrossHead: null,
      };

      setLoading(true);

      filterLamp.map((filters) => {
        if (filters.option === "led") {
          searchCriteria.led = true;
        }
        if (filters.option === "allDayOn") {
          searchCriteria.allDayOn = true;
        }
        return null;
      });

      if (filterDistanceToCrossHead > 0) {
        searchCriteria.distanceToCrossHead = filterDistanceToCrossHead;
      }

      try {
        const { data } = await api.post(
          `/detected-objects/poles/projects/${projectId}/search`,
          searchCriteria
        );

        const updatedLedMapPoints = mapPoints.map((point) => {
          const foundInLeds = data.leds?.some((led) => led.id === point.id);
          const foundInAllDayOn = data.allDayOn?.some(
            (allDay) => allDay.id === point.id
          );
          const foundInDistanceToCrossHead = data.distanceToCrossHead?.some(
            (item) => item.id === point.id
          );

          if (foundInLeds && foundInAllDayOn && foundInDistanceToCrossHead) {
            return {
              ...point,
              color: filterColorScale[0],
            };
          }

          if (foundInLeds) {
            return {
              ...point,
              color: getColor("Led"),
            };
          }
          if (foundInAllDayOn) {
            return {
              ...point,
              color: getColor("24 horas ligada"),
            };
          }
          if (foundInDistanceToCrossHead) {
            return {
              ...point,
              color: "red",
            };
          }

          return { ...point, color: undefined };
        });

        setFilteredMapPoints(updatedLedMapPoints);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      } finally {
        setLoading(false);
      }
    }

    if (filterLamp.length > 0 || filterDistanceToCrossHead > 0) {
      getFilterData();
    } else {
      const clearedMapPoints = filteredMapPoints.map((point) => {
        const { color, ...rest } = point;
        return rest;
      });

      setFilteredMapPoints(clearedMapPoints);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLamp, filterDistanceToCrossHead]);

  const getColor = (type) => {
    return filterLamp.filter((item) => item.text === type)[0].color;
  };

  useEffect(() => {
    async function getMissionsImagesByProjectId() {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/projects/${projectId}/missions/images?step=1`
        );

        setCarPathMapPoints(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (carPath && carPathMapPoints.length === 0) {
      getMissionsImagesByProjectId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carPath]);

  useEffect(() => {
    let updatedPoints;
    if (enablePointsView) {
      updatedPoints = mapPoints.map((point) => {
        const components = point.components;
        const shouldColor =
          filterPoleComponentsValue !== "" &&
          components[filterPoleComponentsValue] &&
          components[filterPoleComponentsValue].length > 0;
        return {
          ...point,
          color: shouldColor ? selectedColorPoleComponents : defaultPointColor,
        };
      });
    } else if (filterPoleComponentsValue !== "") {
      updatedPoints = mapPoints
        .filter((point) => {
          const components = point.components;
          return (
            components[filterPoleComponentsValue] &&
            components[filterPoleComponentsValue].length > 0
          );
        })
        .map((point) => ({
          ...point,
          color: selectedColorPoleComponents,
        }));
    } else {
      updatedPoints = [];
    }

    setFilteredMapPoints(updatedPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPoints, filterPoleComponentsValue, enablePointsView]);

  const handleRemovePoint = (pointId, objectType) => {
    if (objectType === "poles") {
      setFilteredMapPoints((prevPoints) =>
        prevPoints.filter((point) => point.id !== pointId)
      );
    }
    if (objectType === "trees") {
      setTrees((prevPoints) =>
        prevPoints.filter((point) => point.id !== pointId)
      );
    }
    if (objectType === "cable-spacers") {
      setCableSpacers((prevPoints) =>
        prevPoints.filter((point) => point.id !== pointId)
      );
    }
  };

  const handleNewPoint = (newCreatedMapPoint, newObjectType) => {
    if (newObjectType === "poles") {
      const exists = mapPoints.some(
        (point) => point.id === newCreatedMapPoint.id
      );
      if (!exists) {
        setMapPoints((prevPoints) => [...prevPoints, newCreatedMapPoint]);
      }
    }
    if (newObjectType === "trees") {
      const exists = trees.some((point) => point.id === newCreatedMapPoint.id);
      if (!exists) {
        setTrees((prevPoints) => [...prevPoints, newCreatedMapPoint]);
      }
    }
  };

  const handleCheckboxChange = (type) => {
    if (type === "criticalDistanceTrees") {
      setEnableCriticalDistanceTrees(!enableCriticalDistanceTrees);
    } else if (type === "fallRiskTrees") {
      setEnableFallRiskTrees(!enableFallRiskTrees);
    }
  };

  const memoizedModalObjectDetails = useMemo(
    () => (
      <ModalObjectDetails
        offCanvas={offCanvas}
        openDetailsModal={openDetailsModal}
        pointDetailOffcanvas={pointDetailOffcanvas}
        setEnablePointsView={setEnablePointsView}
        setHideLeftMenu={setHideLeftMenu}
        setOffCanvas={setOffCanvas}
        setViewLightComponents={setViewPoleComponents}
        toggleOffCanvas={toggleOffCanvas}
        viewLightComponents={viewPoleComponents}
        updateMapPoints={handleRemovePoint}
      />
    ),
    [offCanvas, openDetailsModal, pointDetailOffcanvas, toggleOffCanvas, viewPoleComponents]
  );

  return (
    <div style={{ overflow: "hidden" }}>
      <Header
        title={projectDetails && projectDetails.name ? projectDetails.name : ""}
        url={"detalhesprojeto/" + projectId}
        isMobile={isMobile}
        toggleSupervisionMode={toggleSupervisionMode}
        searchBarValue={searchBarValue}
        setSearchBarValue={setSearchBarValue}
      />

      {supervisionModeOpen && (
        <ModalSupervisionMode
          toggleSupervisionMode={toggleSupervisionMode}
          supervisionModeOpen={supervisionModeOpen}
          setActivePointSupervision={setActivePointSupervision}
          activePointSupervision={activePointSupervision}
        />
      )}

      {/* Início modal de notificação */}
      {modalNotificacao && (
        <ModalNotification
          modalNotificacao={modalNotificacao}
          setModalNotificacao={setModalNotificacao}
        />
      )}

      {/* Fim modal de notificação */}

      {newPointPreviewMap && (
        <ModalNewDetectedObject
          isOpen={newPointPreviewMap}
          toggle={toggleNewPointPreviewMap}
          pointPosition={newPointPosition}
          setActivePointSupervision={setActivePointSupervision}
          toggleSupervisionMode={toggleSupervisionMode}
          setPreviewImagesNewPoint={setPreviewImagesNewPoint}
          setPreviewImagesVisible={setPreviewImagesVisible}
          onNewPoint={handleNewPoint}
        />
      )}

      {modalFilterPoleComponents && (
        <ModalFilterPoleComponents
          toggle={toggleModalFilterPoleComponents}
          selectedValue={filterPoleComponentsValue}
          setSelectedValue={setFilterPoleComponentsValue}
          setEnablePointsView={setEnablePointsView}
          enablePointsView={enablePointsView}
          setSelectedColor={setSelectedColorPoleComponents}
        />
      )}

      {modalFiltersLamp && (
        <ModalFiltersLamp
          toggle={toggleModalFiltersLamp}
          filterDistanceToCrossHead={filterDistanceToCrossHead}
          setFilterDistanceToCrossHead={setFilterDistanceToCrossHead}
          setLampfilter={setFilterLamp}
          lampFilter={filterLamp}
          filters={[
            { text: "Led", color: filterColorScale[1], option: "led" },
            {
              text: "24 horas ligada",
              color: filterColorScale[2],
              option: "allDayOn",
            },
          ]}
        />
      )}

      {modalFiltersTrees && (
        <ModalFiltersTrees
          toggle={toggleModalFiltersTrees}
          onSave={handleTreeFilterSave}
          treeIsLoaded={lastPageTrees}
          loadingCollision={loadingCollision}
          criticalDistanceTrees={criticalDistanceTrees}
          fallRiskTrees={fallRiskTrees}
          setTrees={setTrees}
          enableCriticalDistanceTrees={enableCriticalDistanceTrees}
          enableFallRiskTrees={enableFallRiskTrees}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}

      {modalMarkersMission && (
        <ModalMarkersMission
          toggle={toggleModalMarkersMission}
          setPoles={setMapPoints}
          setTrees={setTrees}
          setCableSpacers={setCableSpacers}
          missions={missions}
          loading={loadingMissions}
          selectedMissions={selectedMissions}
          setSelectedMissions={setSelectedMissions}
          lastPageTrees={lastPageTrees}
          lastPageCableSpacers={lastPageCableSpacers}
        />
      )}

      <SideBarMapRight
        showLeftMenu={offCanvasListPoints ? "show" : "hide"}
        offCanvas={offCanvas}
        closeAllMenus={closeAllMenus}
        secondaryMenuContentOpen={sidebarMenuOpen}
        setSecondaryMenuContentOpen={setSidebarMenuOpen}
        baseMaps={[
          ["Camadas", baseMaps, baseMapSource, setBaseMapSource, <BsMapFill />],
        ]}
        areasOfInterest={[
          [
            "Contorno",
            areaOfInterest,
            areasOfInterestActive,
            setAreasOfInterestActive,
            <img alt="icon" src="/imgs/MAPA-LIGADO.svg" className="icon" />,
            <img alt="icon" src="/imgs/MAPA-DESLIGADO.svg" className="icon" />,
          ],
        ]}
        enablePoints={[
          [
            "Postes",
            null,
            modalFilterPoleComponents,
            setModalFilterPoleComponents,
            <img alt="icon" src="/imgs/POSTE-ATIVADO.svg" className="icon" />,
            <img
              alt="icon"
              src="/imgs/POSTE-DESATIVADO.svg"
              className="icon"
            />,
          ],
        ]}
        enableTreePoints={[
          [
            "Árvores",
            null,
            enableTreePointsView,
            setEnableTreePointsView,
            <img alt="icon" src="/imgs/ARVORE-ATIVADA.svg" className="icon" />,
            <img
              alt="icon"
              src="/imgs/ARVORE-DESATIVADA.svg"
              className="icon"
            />,
          ],
        ]}
        enableCableSpacerPointsView={[
          [
            "Espaçador de cabo",
            null,
            enableCableSpacerPointsView,
            setEnableCableSpacerPointsView,
            <img
              alt="icon"
              src="/imgs/ESPACADOR-ATIVADO.svg"
              className="icon"
            />,
            <img
              alt="icon"
              src="/imgs/ESPACADOR-DESATIVADO.svg"
              className="icon"
            />,
          ],
        ]}
        newPointPreviewMap={[
          [
            "Novo ponto",
            null,
            newPointPreviewMap,
            setNewPointPreviewMap,
            <PiMapPinPlusFill />,
            <PiMapPinPlusBold />,
          ],
        ]}
        mapCarPath={[
          ["Trajeto", null, carPath, setCarPath, <IoCar />, <IoCarOutline />],
        ]}
        moveMapPoints={[
          [
            "Mover pontos",
            null,
            moveMapPoints,
            setMoveMapPoints,
            <IoMdMove />,
            <IoMoveOutline />,
          ],
        ]}
        alignMarkers={[
          [
            "Alinhar pontos",
            null,
            alignMarkers,
            setAlignMarkers,
            <CiAlignCenterH />,
            <CiAlignCenterH />,
          ],
        ]}
        treeCollision={[
          [
            modalFiltersTrees,
            toggleModalFiltersTrees,
            <img alt="icon" src="/imgs/ARVORE-POSTE.svg" className="icon" />,
            <img alt="icon" src="/imgs/ARVORE-POSTE.svg" className="icon" />,
            "Filtro de colisão para vegetação",
          ],
        ]}
        filters={[
          [
            modalFiltersLamp,
            setModalFiltersLamp,
            <img alt="icon" src="/imgs/LAMPADA.svg" className="icon" />,
            "Filtros de Lâmpada",
          ],
          [
            modalMarkersMission,
            setModalMarkersMission,
            <FaRoute />,
            "Filtro de Missões",
          ],
        ]}
        missionsFilter={[
          [
            "Filtro de colisão para vegetação",
            modalMarkersMission,
            toggleModalMarkersMission,
            <FaRoute />,
            <FaRoute />,
          ],
        ]}
        toast={props.toast}
        pointTable={[[setOffCanvasListPoints, offCanvasListPoints]]}
      />

      {/*
      <SideBarMap
        toast={props.toast}
        hideLeftMenu={[hideLeftMenu, setHideLeftMenu]}
        pointTable={[[setOffCanvasListPoints, offCanvasListPoints]]}
        qtdePontos={[
          ["Qtde Pontos", null, numberValuesApi, setNumberValuesApi],
        ]}
        filters={[
          [
            modalFiltersLamp,
            setModalFiltersLamp,
            <img alt="icon" src="/imgs/LAMPADA.svg" className="icon" />,
            "Filtros de Lâmpada",
          ],
          [
            modalMarkersMission,
            setModalMarkersMission,
            <FaRoute />,
            "Filtro de Missões",
          ],
        ]}
      />
*/}
      {((filterLamp && filterLamp.length > 0) ||
        filterDistanceToCrossHead > 0) && (
        <ColorTableSubtitle
          offCanvas={offCanvas}
          filterLamp={filterLamp}
          filterDistanceToCrossHead={{
            value: filterDistanceToCrossHead,
            color: "red",
          }}
        />
      )}

      {loading && loadingVector}

      {viewPoleComponents && (
        <ModalPoleComponents
          pointDetailOffcanvas={pointDetailOffcanvas}
          openDetailsModal={openDetailsModal}
          setViewLightComponents={setViewPoleComponents}
        />
      )}

      {/* Begin Modal Lightning point details */}
      {/*  */}

      {modalDetails && (
        <DetectionDetailsModal
          modalDetails={modalDetails}
          toggle={toggle}
          modalContent={modalContent}
        />
      )}

      {/* End Modal Lightning point details */}

      <MapContainerComponent
        baseMapSource={baseMapSource}
        zoom={defaultZoom}
        areaOfInterest={areaOfInterest}
        areasOfInterestActive={areasOfInterestActive}
        enableTreePointsView={enableTreePointsView}
        enableCableSpacerPointsView={enableCableSpacerPointsView}
        newPointPreviewMap={newPointPreviewMap}
        setHideLeftMenu={setHideLeftMenu}
        setOffCanvas={setOffCanvas}
        setPointDetailOffcanvas={setPointDetailOffcanvas}
        offCanvas={offCanvas}
        pointDetailOffcanvas={pointDetailOffcanvas}
        mapPoints={filteredMapPoints}
        trees={trees}
        cableSpacers={cableSpacers}
        activePointSupervision={activePointSupervision}
        setActivePointSupervision={setActivePointSupervision}
        newPointPosition={newPointPosition}
        setNewPointPosition={setNewPointPosition}
        previewImagesVisible={previewImagesVisible}
        previewImagesNewPoint={previewImagesNewPoint}
        searchBarValue={searchBarValue}
        setLoading={setLoading}
        carPathMapPoints={carPathMapPoints}
        carPath={carPath}
        moveMapPoints={moveMapPoints}
        setMapPoints={setFilteredMapPoints}
        setTrees={setTrees}
        setCableSpacers={setCableSpacers}
        modalFilterPoleComponents={modalFilterPoleComponents}
        alignMarkers={alignMarkers}
        toggleModalAlignMarkers={toggleModalAlignMarkers}
        lastPageTrees={lastPageTrees}
        lastPageCableSpacers={lastPageCableSpacers}
      />

      {offCanvas && memoizedModalObjectDetails}

      {offCanvasListPoints && (
        <PointsList
          offCanvasListPoints={offCanvasListPoints}
          toggleOffCanvasListPoints={toggleOffCanvasListPoints}
          setPointDetailOffcanvas={setPointDetailOffcanvas}
          setOffCanvas={setOffCanvas}
          modalOpen={offCanvas}
        />
      )}

      {isMobile && (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            bottom: "45px",
            left: "10px",
          }}
        >
          <SearchBar value={searchBarValue} setValue={setSearchBarValue} />
          <Button
            style={{ marginTop: ".5rem" }}
            onClick={toggleSupervisionMode}
          >
            Modo de supervisão
          </Button>
        </div>
      )}
    </div>
  );
};

export default Map;
