import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import "./PointList.css";
import SearchBar from "../SearchBar/SearchBar";

const numberValuesApi = 100000;

const PointsList = ({
  offCanvasListPoints,
  toggleOffCanvasListPoints,
  setPointDetailOffcanvas,
  setOffCanvas,
}) => {
  const [listPoints, setListPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");

  let { id: projectId } = useParams();

  useEffect(() => {
    async function getData() {
      try {
        const pointsResponse = await api.get(
          `/projects/${projectId}/poles?sort=id&size=${numberValuesApi}`
        );

        const promises = pointsResponse.data.content.map(async (point) => {
          const lampResponse = await api.get(
            `/detected-objects/poles/${point.id}/lamps`
          );
          point.lamp = lampResponse.data;
          return point;
        });

        const updatedPoints = await Promise.all(promises);

        setListPoints(updatedPoints);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPoints = listPoints.filter((point) =>
    point.id.toString().includes(searchBarValue)
  );

  return (
    <div>
      <div className="sticky-top" style={{ zIndex: "1 !important" }}>
        <Offcanvas
          id="listaPontos"
          backdrop={false}
          direction="end"
          isOpen={offCanvasListPoints}
          style={{
            minWidth: "100%",
            top: "80px",
          }}
        >
          <OffcanvasHeader toggle={toggleOffCanvasListPoints}>
            <div className="ms-4">Lista de pontos</div>
          </OffcanvasHeader>

          <SearchBar
            button={false}
            value={searchBarValue}
            setValue={setSearchBarValue}
          />

          <div className="container-labels-color-circle">
            <div className="label-color-circle">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                }}
              />
              Lâmpada de Led
            </div>
            <div className="label-color-circle">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "orange",
                }}
              />
              Lâmpada sem Led
            </div>
            <div className="label-color-circle">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "brown",
                }}
              />
              Poste sem lâmpadas
            </div>
            <div className="label-color-circle">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "black",
                }}
              />
              Lâmpada não categorizada
            </div>
          </div>

          <OffcanvasBody>
            <Table hover>
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>ID</th>
                  <th style={{ textAlign: "center" }}>Lâmpada</th>
                  <th style={{ textAlign: "center" }}>Latitude</th>
                  <th style={{ textAlign: "center" }}>Longitude</th>
                </tr>

                {loading && (
                  <div className="loading">
                    <Spinner
                      color="primary"
                      style={{
                        margin: "auto",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </div>
                )}

                {filteredPoints?.map((point) => {
                  return (
                    <tr
                      key={Math.random()}
                      style={{ marginBottom: "1em", cursor: "pointer" }}
                      onClick={() => {
                        setPointDetailOffcanvas({
                          point: point,
                          type: "poles",
                        });
                        setOffCanvas(true);
                      }}
                    >
                      <td style={{ textAlign: "center" }}>#{point.id}</td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: ".5rem",
                          }}
                        >
                          {point.lamp?.map((lamp, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    lamp.led != null
                                      ? lamp.led
                                        ? "green"
                                        : "orange"
                                      : "black",
                                }}
                              />
                            );
                          })}

                          {point.lamp.length === 0 && (
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: "brown",
                              }}
                            />
                          )}
                        </div>
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {Math.floor(point.latitude * 10000) / 10000}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {Math.floor(point.longitude * 10000) / 10000}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </div>
  );
};

export default PointsList;
