import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Input, Label } from "reactstrap";
import { BsXLg } from "react-icons/bs";

const ModalFiltersLamp = ({
  filterDistanceToCrossHead,
  setFilterDistanceToCrossHead,
  setLampfilter,
  lampFilter,
  filters,
  toggle,
}) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    setCurrentValue(filterDistanceToCrossHead);
  }, [filterDistanceToCrossHead]);

  const handleRangeChange = (event) => {
    setCurrentValue(event.target.value);
  };

  const handleRangeMouseUp = (event) => {
    const newValue = event.target.value;
    setFilterDistanceToCrossHead(newValue);
  };
  return (
    <Card
      style={{
        right: "90px", 
        bottom: "60px", 
        position: "absolute",
        zIndex: "1000",
        display: "block",
        overflowX: "scroll",
        width: "300px",
        background: "rgba(230, 230, 230, .85)",
      }}
    >
      <CardTitle>
        <div className="d-flex align-items-center p-3 gap-1">
          <Col style={{ fontSize: "1.2em" }}>Filtro de lampada</Col>
          <BsXLg onClick={toggle} style={{ cursor: "pointer" }} />
        </div>
        <hr className="m-0" />
      </CardTitle>
      <CardBody
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        {filters.map((it) => (
          <div
            key={it.text}
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <Input
              id={"filterPowerCheck" + it.text}
              addon
              type="checkbox"
              className="m-1"
              defaultChecked={
                !!lampFilter.find((item) => item.text === it.text)
              }
              onClick={() => {
                const aux = [...lampFilter];
                const index = aux.findIndex((item) => item.text === it.text);

                if (index !== -1) {
                  aux.splice(index, 1);
                } else {
                  aux.push(it);
                }

                setLampfilter(aux);
              }}
            />
            <Label style={{ margin: "0" }} for={"filterPowerCheck" + it.text}>
              <small>{it.text}</small>
            </Label>
          </div>
        ))}

        <div>
          <hr />
          <Label>Distância até a cruzeta menor que:</Label>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Input
              type="range"
              min="0"
              max="5"
              step="0.5"
              value={currentValue}
              onChange={handleRangeChange}
              onMouseUp={handleRangeMouseUp}
            />
            <Input
              type="number"
              value={currentValue}
              readOnly
              style={{ width: "40px", textAlign: "center", padding: ".2rem 0" }}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ModalFiltersLamp;
