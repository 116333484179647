import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Spinner,
} from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import MapReportArea from "../../elements/MapReportArea/MapReportArea";

const NewReport = (props) => {
  const navigate = useNavigate();

  // Define o título da aba/página
  document.title = "Criar novo Relatório";

  // Recupera os dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  // Recupera os parâmetros recebidos via url
  let { id: projectId } = useParams();

  // Hook para armazenamento do dicionário dos tipos de relatórios
  const [typesData, setTypesData] = useState();

  // Hooke para armazenamento do arquivo a ser enviado
  const [fileToSend, setFileToSend] = useState();

  // Hook para armazenamento da descrição do tipo de relatório selecionado
  const [detailsSelectedType, setDetailsSelectedType] = useState();

  // Hook para verificação da necessidade de envio de um arquivo conforme
  // o tipo de relatório solicitado
  const [uploadFileNeeded, setUploadFileNeeded] = useState();

  // Controle de exibição do modal
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
  });

  const [areasOfInterest, setAreasOfInterest] = useState([]);
  const [selectAreasOfInterest, setSelectAreasOfInterest] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);

  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.actionButton) content.actionButton = null;

    setModalContent(content);
    setModal(!modal);
  };

  // Obtenção do dicionário dos tipos de relatórios
  function getTypesData() {
    fetch(apiUrl + "/reports/options-street", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setTypesData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Controles e requisição das permissões do usuário
  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  // Ao receber o token de acesso é iniciado a requisição para a
  // obtenção dos tipos de relatórios disponíveis
  useEffect(() => {
    if (userPermission) {
      getTypesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  // Indicador de carregamento
  const getLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  // Recebimento dos dados do relatório enviados pelo utilizador
  const onFormSubmit = (values) => {
    values.preventDefault();

    // Include pointsByType in fieldsAndValues
    let fieldsAndValues = {
      areas_of_interest: [...areasOfInterest]
    };

    let formData = new FormData();
    let selectedSubOptions = [];

    Object.entries(values.target.elements).forEach((ele) => {
      const value = ele[1].value;
    
      if (value) {
        if (ele[1].type === "checkbox") {
          if (ele[1].checked) {
            if (value !== "on" && !selectedSubOptions.includes(value)) {
              selectedSubOptions.push(value);
            }
          }
        } else if (ele[1].id !== "file" && ele[1].type !== "checkbox") {
          fieldsAndValues[ele[1].id] = value;
        }
      }
    });

    fieldsAndValues.sub_options = selectedSubOptions;

    // Alteração do tipo de requisição para o padrão da API
    const jsontoSend = new Blob([JSON.stringify(fieldsAndValues)], {
      type: "application/json",
    });

    formData.append("report", jsontoSend, "report");

    // Verificação da existencia de um arquivo a enviar
    if (fileToSend) {
      formData.append("reference", fileToSend);
    } else {
      formData.append("reference", null);
    }

    toggleModal({
      title: "Inserir novo relatório",
      content:
        "O processamento do relatório foi iniciado, em breve o relatório poderá ser visto na lista de relatórios.",
      // buttonHref: "/relatorios/" + projectId,
      buttonHref: null,
      actionButton: () => navigate("/relatorios/" + projectId),
    });

    props.toast({
      title: "Geração de relatórios",
      message: (
        <>
          <div style={{ display: "flex" }}>
            <Spinner
              className="ms-2 me-2"
              style={{
                display: "block",
                width: "20px",
                height: "20px",
              }}
              color="primary"
            />
            Gerando...
          </div>
        </>
      ),
      timer: 3000,
    });
    // Realiza a requisição para a criação do relatório
    fetch(apiUrl + "/reports/create", {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro " + response.status);
        }
      })
      .then((data) => {
        props.toast({
          title: "Geração de relatórios",
          message: (
            <div>
              <div>Relatório gerado.</div>
              <Button
                className="pt-1 pb-1 col-12"
                onClick={() => navigate("/relatorios/" + projectId)}
              >
                Ver lista de relatórios
              </Button>
            </div>
          ),
          timer: 3000,
        });
      })
      .catch((error) => {
        toggleModal({
          title: "Inserir novo relatório",
          content:
            "Houve um erro ao inserir o relatório.\n\nDetalhes:\n" + error,
          buttonHref: null,
          actionButton: () => setModal(false),
        });
        console.error(error);
      });
  };

  // Recebe o arquivo selecionado pelo utilizador para envio
  // e define dentro do hook de envio de arquivo.
  const handleUpload = (files) => {
    setFileToSend(files.target.files[0]);
  };

  const labelSize = 3;
  const inputSize = 6;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableOptions =
    typesData?.find((category) => category.category === selectedCategory)
      ?.options || [];

  useEffect(() => {
    if (availableOptions.length > 0) {
      setSelectedSubOptions(availableOptions.map((option) => option.option));
    }
  }, [availableOptions, selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (option) => {
    setSelectedSubOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option]
    );
  };

  //Constrói o layout da página
  return (
    <div className="p-2">
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        {modalContent.buttonHref || modalContent.actionButton ? (
          <ModalFooter>
            {modalContent.actionButton && (
              <Button
                className="btn btn-outline-secondary text-light"
                onClick={
                  modalContent.actionButton ? modalContent.actionButton : false
                }
              >
                {modalContent.button}
              </Button>
            )}

            {modalContent.buttonHref && (
              <Link
                className="btn btn-outline-secondary"
                to={modalContent.buttonHref ? modalContent.buttonHref : false}
              >
                {modalContent.button}
              </Link>
            )}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Header />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <h2 className="col-10 m-0 text-start">
                {/* Inicio botão voltar pagina */}
                <div className="m-2">
                  <Link
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/relatorios/" + projectId}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Novo Relatório
                </div>
                {/* Final Botão voltar pagina */}
              </h2>
              <div className="col-2 pr-0 m-0 row"></div>
            </div>
            <hr />
            <div
              className="main-content p-3 mt-0"
              style={{ display: "flex", padding: "5px" }}
            >
              <div style={{ width: "100%" }} id="uprojetos">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  !typesData ? (
                    getLoading()
                  ) : (
                    <Form onSubmit={(e) => onFormSubmit(e)}>
                      <FormGroup row className="report-form-group">
                        <Label for={"name"} sm={labelSize}>
                          Nome
                        </Label>
                        <Col sm={inputSize}>
                          <Input
                            id={"name"}
                            required
                            name={"Nome"}
                            placeholder={"Nome"}
                            type={"text"}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row className="report-form-group">
                        <Label for={"option"} sm={labelSize}>
                          Tipo
                        </Label>
                        <Col sm={inputSize}>
                          <Input
                            id="option"
                            name="option"
                            type="select"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                          >
                            <option value="">Selecione</option>
                            {typesData.map((category) => (
                              <option
                                key={Math.random()}
                                value={category.category}
                              >
                                {category.text}
                              </option>
                            ))}
                          </Input>

                          {detailsSelectedType && (
                            <small className={"m-2 p-0"}>
                              *{detailsSelectedType.description}
                            </small>
                          )}
                        </Col>
                      </FormGroup>

                      {selectedCategory && (
                        <FormGroup row className="report-form-group">
                          <Label for={"role"} sm={labelSize}>
                            Sub Opções
                          </Label>
                          <Col
                            sm={inputSize}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "1rem",
                            }}
                          >

                            {availableOptions.map((option) => (
                              <div key={option.option} className="form-check">
                                <Input
                                  type="checkbox"
                                  id={option.option}
                                  name="subOptions[]"
                                  value={option.option}
                                  checked={selectedSubOptions.includes(
                                    option.option
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(option.option)
                                  }
                                />
                                <Label
                                  for={option.option}
                                  className="form-check-label"
                                >
                                  {option.name}
                                </Label>
                              </div>
                            ))}

                            <small
                              className="m-2 p-0"
                              style={{ width: "100%" }}
                            >
                              Selecione uma ou mais opções para o relatório.
                            </small>
                          </Col>
                        </FormGroup>
                      )}

                      {selectedCategory === "VEGETATION" && (
                        <>
                          <FormGroup row className="report-form-group">
                            <Label
                              for="horizontalCriticalDistance"
                              sm={labelSize}
                            >
                              Distância crítica horizontal
                            </Label>
                            <Col sm={inputSize}>
                              <Input
                                id="horizontal_critical_distance"
                                name="Distância Crítica Horizontal"
                                placeholder="Distância Crítica Horizontal"
                                type="text"
                                onInput={(event) => {
                                  // Allow only numbers and a single dot
                                  const value = event.target.value;
                                  event.target.value = value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1");
                                }}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row className="report-form-group">
                            <Label
                              for="verticalCriticalDistance"
                              sm={labelSize}
                            >
                              Distância crítica vertical
                            </Label>
                            <Col sm={inputSize}>
                              <Input
                                id="vertical_critical_distance"
                                name="Distância Crítica Vertical"
                                placeholder="Distância Crítica Vertical"
                                type="text"
                                onInput={(event) => {
                                  // Allow only numbers and a single dot
                                  const value = event.target.value;
                                  event.target.value = value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1");
                                }}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row className="report-form-group">
                            <Label for="file" sm={labelSize}>
                              Alimentadores de referência
                            </Label>
                            <Col sm={inputSize}>
                              <Input
                                onChange={(file) => handleUpload(file)}
                                id="file"
                                name="file"
                                type="file"
                                accept=".kmz,.kml"
                              />
                            </Col>
                          </FormGroup>
                        </>
                      )}

                      {["LOAD_COMPARISON", "COMPARISON"].includes(
                        detailsSelectedType?.option
                      ) ? (
                        <FormGroup row className="report-form-group">
                          <Label for={"file"} sm={labelSize}>
                            Arquivo
                          </Label>
                          <Col sm={inputSize}>
                            <Input
                              required={uploadFileNeeded ? true : false}
                              onChange={(file) => handleUpload(file)}
                              id={"file"}
                              name={"file"}
                              type={"file"}
                              accept=".xlsx,.xls"
                            />
                          </Col>
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <FormGroup row className="report-form-group">
                        <Label for={"interest-area"} sm={labelSize}>
                          Selecionar região de interesse
                        </Label>
                        <Col sm={inputSize}>
                          <Input
                            id="interest-area"
                            name="interest-area"
                            type="checkbox"
                            onChange={() =>
                              setSelectAreasOfInterest(!selectAreasOfInterest)
                            }
                          />
                        </Col>
                      </FormGroup>

                      {selectAreasOfInterest && (
                        <MapReportArea
                        setAreasOfInterest={
                          setAreasOfInterest
                          }
                        />
                      )}

                      <FormGroup row className="report-form-group">
                        <Col sm={inputSize}>
                          <Input
                            id={"project_id"}
                            name={"project"}
                            type={"text"}
                            hidden={true}
                            defaultValue={projectId}
                          />
                        </Col>
                      </FormGroup>
                      <div className="row">
                        <Button className="col-8 m-auto">
                          Criar Relatório
                        </Button>
                      </div>
                    </Form>
                  )
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewReport;
