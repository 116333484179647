import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../elements/Header";
import CamIconButton from "../../elements/CamIcon/CamIcon";
import DroneIconButton from "../../elements/DroneIcon/DroneIcon";
import CamDroneIconButton from "../../elements/CamDroneIcon/CamDroneIcon";
import {
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Col,
  Row,
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Container,
} from "reactstrap";
import {
  BsFillPencilFill,
  BsFillPlusCircleFill,
  BsTrashFill,
  BsFillExclamationTriangleFill,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsPinAngleFill,
  BsThreeDotsVertical,
  BsFilter,
  BsPinAngle,
  BsCheck,
} from "react-icons/bs";
import "../../elements/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import ReportsIconButton from "../../elements/ReportsIcon/ReportsIcon";
import DetailsIconButton from "../../elements/DetailsIcon/DetailsIcon";
import MapIconButton from "../../elements/MapIcon/MapIcon";
import PointsIconButton from "../../elements/PointIcon/PointIcon";
import AddIconButton from "../../elements/AddIcon/AddIcon";
import FolderIconButton from "../../elements/FolderIcon/FolderIcon";
import FilterButton from "../../elements/FilterButton/FilterButton";


function doisDigitos(number) {
  var output = number + "";
  while (output.length < 2) {
    output = "0" + output;
  }
  return output;
}

function formatData(element) {
  if (element && element[0] && element[0].toString().length === 4) {
    let dia = element[0] + doisDigitos(element[1]) + doisDigitos(element[2]);
    return dia;
  } else {
    return element;
  }
}

const Projects = () => {
  document.title = "Lista de Projetos";

  const navigate = useNavigate();
  let apiUrl = process.env.REACT_APP_API_URL;

  let mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);

  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);

  const [loading, setLoading] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);

  // Início controle do filtro de empresas

  const [companies, setCompanies] = useState();
  const [companiesList, setCompaniesList] = useState(null);
  const [companiesAppliedFilterList, setCompaniesAppliedFilterList] =
    useState(null);

  // Início controle de filtro da lista de projetos

  const [filtroLista, setFiltroLista] = useState(null);

  // Início controle de filtro da lista de projetos

  // Início controles da lista de projetos
  const [projectItems, setProjectItems] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [updateList, setUpdateList] = useState();
  // Fim controles da lista de projetos

  // Início controles do modal de confirmação de exclusão de projeto
  const [modalDeleteProject, setModalDeleteProject] = useState(false);
  const [modalDeleteContent, setModalDeleteContent] = useState({});

  const toggleModalDeleteProject = () =>
    setModalDeleteProject(!modalDeleteProject);
 
  const closeModalDeleteProject = () => {
    setModalDeleteProject(false);
  };

  const openmodalDeleteContent = (id, icon, title, body) => {
    setModalDeleteContent({
      idProject: id,
      icon: icon,
      title: title,
      body: body,
      footer: true,
    });
    toggleModalDeleteProject();
  };

  // Início da função de criação de cada item da lista de projetos
  const createProjectItemList = (element) => {
    let latCenter = 0;
    let lngCenter = 0;
    let contPoints = 0;
    let latMax = null;
    let latMin = null;
    let lngMax = null;
    let lngMin = null;
    element.areas_of_interest.forEach((ime) => {
      ime.forEach((imePoint) => {
        contPoints++;
        latCenter = latCenter + imePoint[0];
        lngCenter = lngCenter + imePoint[1];
        if (!latMax || latMax < imePoint[0]) {
          latMax = imePoint[0];
        }
        if (!latMin || latMin > imePoint[0]) {
          latMin = imePoint[0];
        }
        if (!lngMax || lngMax < imePoint[1]) {
          lngMax = imePoint[1];
        }
        if (!lngMin || lngMin > imePoint[1]) {
          lngMin = imePoint[1];
        }
      });
    });
    latCenter = latCenter / contPoints;
    lngCenter = lngCenter / contPoints;

    let diffLat = latMax - latMin;

    let zoomLevel = 6;
    if (diffLat * 10000 < 10) zoomLevel = 19;
    else if (diffLat * 10000 < 70) zoomLevel = 15;
    else if (diffLat * 10000 < 150) zoomLevel = 14;
    else if (diffLat * 10000 < 300) zoomLevel = 13;
    else if (diffLat * 10000 < 500) zoomLevel = 12;
    else if (diffLat * 10000 < 1000) zoomLevel = 11;
    else if (diffLat * 10000 < 8000) zoomLevel = 10;
    else if (diffLat * 10000 < 10000) zoomLevel = 9;
    else if (diffLat * 10000 < 35000) zoomLevel = 8;
    else if (diffLat * 10000 < 50000) zoomLevel = 7;
    else if (diffLat * 10000 < 80000) zoomLevel = 6;
    else if (diffLat * 10000 < 100000) zoomLevel = 5;
    else if (diffLat * 10000 < 150000) zoomLevel = 4;
    else zoomLevel = 2;

    //console.log(element);

    return (
      <Col sm={12} lg={6} xl={4} key={Math.random()}>
        <Card
          className="m-2 p-1"
          style={{ 
            minHeight: "300px",
            borderRadius: "15px", // Arredondamento das bordas
            overflow: "hidden",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra para destaque
           }}
          onDoubleClick={() => {
            navigate("/detalhesprojeto/" + element.id);
          }}
        >
          {/* Nome do projeto como título do card */}
          
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#FFF",
                padding: "10px 0",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Link
                style={{
                  marginTop: "-50px",
                  zIndex: "1000",
                  height: "80%",
                  width: "65%",
                  backgroundColor: "#FFF",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
                to={"/detalhesprojeto/" + element.id}
              >
                <i>
                  {element && element.name
                    ? element.name
                    : "#" + element.id + " - " + element.name}
                </i>
              </Link>
            </div>
            
          <Row className="m-0">
            <Row
              className="m-0"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                zIndex: "1000",
              }}
            >

            </Row>
            <MapContainer
              className="m-0"
              center={
                latCenter && lngCenter && latCenter !== 0 && lngCenter !== 0
                  ? [latCenter, lngCenter]
                  : [-20.32138449535715, -40.339507198852004]
              }
              // center={
              //   element.areas_of_interest[0] && element.areas_of_interest[0][0]
              //     ? element.areas_of_interest[0][0]
              //     : [-20.32138449535715, -40.339507198852004]
              // }
              zoom={
                latCenter && lngCenter && latCenter !== 0 && lngCenter !== 0
                  ? zoomLevel
                  : 2
              }
              scrollWheelZoom={false}
              style={{ position: "relative", width: "100%", height: "200px" }}
              dragging={false}
              doubleClickZoom={false}
            >
              <TileLayer
                attribution={mapSourcePreview.attribution}
                url={mapSourcePreview.url}
                subdomains={mapSourcePreview.subDomains}
              />
              {element.areas_of_interest.map((elem, cont = 0) => {
                return (
                  <Polygon
                    key={Math.random()}
                    pathOptions={{ color: colorLayersList[cont][1] }}
                    positions={elem ? elem : false}
                  />
                );
              })}
            </MapContainer>
             {/* Status no canto esquerdo */}
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: "1000",
                }}
              >
                <Badge
                  color={
                    element.status === "Sem previsão"
                      ? "secondary"
                      : element.status === "Concluído" || element.status === "Finalizado"
                      ? "success"
                      : element.status === "Atrasado"
                      ? "danger"
                      : "warning"
                  }
                  style={{ padding: "5px 5px", marginTop: "50px" }}
                >
                  {element.status}
                </Badge>
              </div>
          </Row>
          {/* nome da cidade */}
          <Row className="m-0">
            <Col 
              className="mt-2 mb-2 text-center"
              style={{ color: "#bfbfbf" }}
            >
              <BsPinAngle className="text-center me-2" />
              <u>
                {element.city &&
                element.city !== "null" &&
                element.city !== "false"
                  ? element.city
                  : "Cidade não definida"}
              </u>
            </Col>
          </Row>
          <div className="text-center m-3">
            {/* Primeira linha de botões */}
            <Row className="m-0 p-0 justify-content-center" style={{ marginTop: "10px" }}>
              <Col className="text-center m-2">
                <div>
                  <Link
                    className="btn btn-light d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    to={"/mapa/projetos/" + element.id}
                  >
                    <MapIconButton width="35px" height="35px" />
                  </Link>
                  <div style={{ fontSize: "12px", marginTop: "5px", paddingLeft: "8px", textAlign: "start" }}>Mapa</div>
                </div>
              </Col>
              <Col className="text-center m-2">
                <div>
                  <Link
                    className="btn btn-light d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    to={"/relatorios/" + element.id}
                  >
                    <ReportsIconButton width="35px" height="35px" />
                  </Link>
                  <div style={{ fontSize: "12px", marginTop: "5px", textAlign: "start" }}>Relatórios</div>
                </div>
              </Col>
              <Col className="text-center m-2" >
                <div>
                  <Link
                    className="btn btn-light d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    to={"/detalhesprojeto/" + element.id}
                  >
                    <DetailsIconButton width="35px" height="35px" />
                  </Link>
                  <div style={{ fontSize: "12px", marginTop: "5px", paddingLeft: "8px", textAlign: "start" }}>Sobre</div>
                </div>
              </Col>
            </Row>
            
            {/* Segunda linha de botões */}
            <Row className="m-0 p-0 justify-content-center" style={{ marginTop: "10px" }}>
            <Col
                  className="text-end"
                  style={{
                    position: "absolute",
                    marginLeft: "5px",
                    top: "50%", // Centraliza verticalmente
                    transform: "translateY(450%)", // Corrige o posicionamento vertical
                    zIndex: 1,
                  }}
                >
                  {element.project_type && element.project_type === "GROUND" ? (
                    <CamIconButton />
                  ) : element.project_type === "AERIAL" ? (
                    <DroneIconButton />
                  ) : element.project_type === "AERIAL_GROUND" ? (
                    <CamDroneIconButton />
                  ) : (
                    ""
                  )}
                </Col>
              
               {/* Ícone Ground alinhado à direita */}
               
            </Row>
          </div>
          <UncontrolledDropdown
            className="m-0"
            style={{
              position: "absolute",
              top: "42px",
              right: "5px",
              zIndex: "1001",

            }}
          >
            <DropdownToggle color="white" style={{ border: "1px solid #ccc",boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)", backgroundColor: "white" }}>
              <BsThreeDotsVertical />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Mais</DropdownItem>

              <DropdownItem>
                <Link
                  //className="btn btn-item-list pt-0 pb-0"
                  style={{ width: "100%", height: "1.7em" }}
                  //type="button"
                  to={"/mapa/list/" + element.id}
                >
                  <div
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Acessar a lista de pontos desse projeto"
                  >
                    <small>
                      <Row>
                        <Col className="col-2">
                          <BsPinAngleFill />
                        </Col>
                        <Col className="ml-auto">Pontos</Col>
                      </Row>
                    </small>
                  </div>
                </Link>
              </DropdownItem>

              <DropdownItem divider />
              <DropdownItem header>Ações</DropdownItem>

              {/* Início link para editar projeto */}
              {CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "MANAGER"
              ) ? (
                element.status !== "Finalizado" ? (
                  <DropdownItem>
                    <Link
                      //className="m-auto btn btn-item-list p-auto"
                      style={{ width: "100%" }}
                      //type="button"
                      //to={"/editarprojeto/" + element.id}
                      to={"/editarprojeto/" + element.id}
                      //projectData.status !== "Finalizado"
                    >
                      <small>
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Editar detalhes do projeto"
                        >
                          <Row>
                            <Col className="col-2">
                              <BsFillPencilFill />
                            </Col>
                            <Col className="ml-auto">Editar projeto</Col>
                          </Row>
                        </div>
                      </small>
                    </Link>
                  </DropdownItem>
                ) : (
                  <div
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Não é possível editar pois o projeto foi finalizado."
                  >
                    <DropdownItem className="disabled">
                      <div style={{ width: "100%" }}>
                        <small>
                          <Row>
                            <Col className="col-2">
                              <BsFillPencilFill />
                            </Col>
                            <Col className="ml-auto">Editar projeto</Col>
                          </Row>
                        </small>
                      </div>
                    </DropdownItem>
                  </div>
                )
              ) : (
                ""
              )}
              {/* Fim link para editar projeto */}
              {/* Início link para excluir projeto */}
              {CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "MANAGER"
              ) ? (
                <DropdownItem>
                  <Link
                    //className="m-auto btn btn-item-list p-auto"
                    style={{ width: "100%" }}
                    //type="button"
                    onClick={() =>
                      openmodalDeleteContent(
                        element.id,
                        <BsFillExclamationTriangleFill />,
                        element.id + " - " + element.name,
                        "Deseja realmente apagar o projeto " +
                          element.name +
                          "?"
                      )
                    }
                  >
                    <small>
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Excluir o projeto (Esta ação não pode ser revertida)"
                      >
                        <Row>
                          <Col className="col-2">
                            <BsTrashFill />
                          </Col>
                          <Col className="ml-auto">Excluir projeto</Col>
                        </Row>
                      </div>
                    </small>
                  </Link>
                </DropdownItem>
              ) : (
                ""
              )}
              {/* Fim link para excluir projeto */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Card>
      </Col>
    );
  };
  // Fim da função de criação de cada item da lista de projetos

  useEffect(() => {
    if (projectList && projectList.length > 0) {
      let auxProjectsListVisible = [];
      if (
        !companiesAppliedFilterList ||
        companiesAppliedFilterList.length === 0
      ) {
        projectList.forEach((element) => {
          let listItem = createProjectItemList(element);
          auxProjectsListVisible.push(listItem);
        });
      } else {
        projectList.forEach((element) => {
          companiesAppliedFilterList.forEach((filterElement) => {
            if (filterElement.toString() === element.company_id.toString()) {
              let listItem = createProjectItemList(element);
              auxProjectsListVisible.push(listItem);
            }
          });
        });
      }
      setProjectItems(auxProjectsListVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesAppliedFilterList, projectList]);

  // Início da função de aquisição das empresas disponibilizadas pela API

  const getCompanies = (projectListToCompany) => {
    fetch(apiUrl + "/companies", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setCompanies(data.content);
        // if (projectListToCompany) {
        //   let auxCompaniesList = projectListToCompany;
        //   console.log(data.content);

        //   data.content.forEach((companiesItem) => {
        //     projectListToCompany.forEach((projectItem) => {
        //       if (companiesItem.id.toString() === projectItem.company_id) {
        //         auxCompaniesList[projectItem.company_id] = companiesItem;
        //       }
        //     });
        //   });

        //   // projectListToCompany.forEach((companyItem) => {
        //   //   // console.log(companyItem);
        //   //   data.content.forEach((resultItem) => {
        //   //     console.log(resultItem.id.toString(), companyItem.company_id);
        //   //     if (resultItem.id.toString() === companyItem.company_id) {
        //   //       auxCompaniesList[companyItem.company_id] = { resultItem };
        //   //     }
        //   //   });
        //   // });
        //   console.log(auxCompaniesList);
        //   setCompaniesList(auxCompaniesList);
        // }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Término da função de aquisição das empresas disponibilizadas pela API

  // Início da função de aquisição de dados da API
  function getData() {
    if (
      userPermission !== null
      // &&
      // localStorage.getItem("robotictech_company_id") !== null &&
      // localStorage.getItem("robotictech_company_id") !== "null"
    ) {
      setLoadingProjects(true);
      let projectListToCompany = null;
      fetch(
        CheckPermission(
          userPermission && userPermission.role ? userPermission.role : "",
          "ADMIN"
        )
          ? apiUrl + "/projects?size=10000&beingDeleted=false"
          : `${apiUrl}/projects?companyId=${localStorage.getItem(
              "robotictech_company_id"
            )}&beingDeleted=false&size=10000`,
        {
          method: "GET",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + userPermission.access_token,
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((pulist) => {
          try {
            pulist = JSON.parse(pulist);
          } catch (error) {
            throw error;
          }

          if (pulist.content && pulist.content.length > 0) {
            setCompaniesList(null);
            let auxCompaniesList = {};
            pulist.content.forEach((projectItem) => {
              auxCompaniesList[projectItem.company_id] = {};
            });
            setTimeout(() => {
              setCompaniesList(auxCompaniesList);
            }, 1);
          }

          let projectList = [];
          let projetos = [];
          // Parâmetros de filtro
          if (!filtroLista || filtroLista === "id90") {
            projetos = pulist.content.sort(function (a, b) {
              return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "id09") {
            projetos = pulist.content.sort(function (a, b) {
              return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "nameAZ") {
            projetos = pulist.content.sort(function (a, b) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "nameZA") {
            projetos = pulist.content.sort(function (a, b) {
              return a.name > b.name ? -1 : a.name < b.name ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "statusAZ") {
            projetos = pulist.content.sort(function (a, b) {
              return a.status < b.status ? -1 : a.status > b.status ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "statusZA") {
            projetos = pulist.content.sort(function (a, b) {
              return a.status > b.status ? -1 : a.status < b.status ? 1 : 0;
            });
          } else if (filtroLista && filtroLista === "start_date09") {
            projetos = pulist.content.sort(function (a, b) {
              return formatData(a.start_date) < formatData(b.start_date)
                ? -1
                : formatData(a.start_date) > formatData(b.start_date)
                ? 1
                : 0;
            });
          } else if (filtroLista && filtroLista === "start_date90") {
            projetos = pulist.content.sort(function (a, b) {
              return formatData(a.start_date) > formatData(b.start_date)
                ? -1
                : formatData(a.start_date) < formatData(b.start_date)
                ? 1
                : 0;
            });
          } else {
            projetos = pulist.content;
          }

          setProjectList(projetos);
          projectListToCompany = projetos;
          return projectList;
        })
        .finally(() => {
          getCompanies(projectListToCompany);
          setLoadingProjects(false);
        })
        .catch((error) => {
          console.log(error);
          // if (
          //   window.confirm(
          //     "Não foi possível carregar a lista de projetos. Deseja tentar novamente?"
          //   )
          // ) {
          //   window.location = "/";
          // } else {
          //   localStorage.clear();
          //   setTimeout(() => {
          //     navigate("/login");
          //   }, 100);
          // }
        });
    }
  }
  // Fim da função de aquisição de dados da API

  // Início hook para aquisição/atualização de dados da API
  useEffect(() => {
    // Início exibição do ícone de carregamento
    setProjectItems(
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    // Fim exibição do ícone de carregamento
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateList, userPermission]);
  // Fim hook para aquisição/atualização de dados da API

  // Início hook para filtro da lista de projetos
  useEffect(() => {
    // Início exibição do ícone de carregamento
    setProjectItems(
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    // Fim exibição do ícone de carregamento
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroLista]);
  // Fim hook para filtro da lista de projetos

  // Início função de requisição de exclusão do projeto pela API
  const deleteProject = (id) => {
    setModalDeleteContent({
      id: modalDeleteContent.id,
      icon: modalDeleteContent.icon,
      title: modalDeleteContent.title,
      body: ("Apagando projeto..."),
      footer: false,
    });
    fetch(apiUrl + "/projects/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",

        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        setUpdateList(Math.random());
        closeModalDeleteProject();
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        setModalDeleteContent({
          id: modalDeleteContent.id,
          icon: <BsFillCheckCircleFill />,
          title: modalDeleteContent.title,
          body: "Projeto apagado com sucesso.",
          footer: false,
        });
      })
      .catch((error) => {
        setModalDeleteContent({
          id: modalDeleteContent.id,
          icon: <BsFillXCircleFill />,
          title: modalDeleteContent.title,
          body: "Não foi possível apagar o projeto.",
          footer: false,
        });
        console.error("Error:", error);
      });
  };
  // Fim função de requisição de exclusão do projeto pela API

  const loadingIcon = () => {
    return (
      <div
        key={Math.random()}
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  return (
    <div className="p-2">
      {/* Begin modal delete project */}
      <Modal
        isOpen={modalDeleteProject}
        toggle={toggleModalDeleteProject}
        // centered
        backdrop="static"
      >
        <ModalHeader toggle={closeModalDeleteProject}>
          <div className="row m-0">
            <div className="col-2 m-auto">
              {modalDeleteContent.icon ? modalDeleteContent.icon : ""}
            </div>
            <div className="col m-auto">
              {modalDeleteContent.title ? modalDeleteContent.title : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {modalDeleteContent.body ? modalDeleteContent.body : ""}
        </ModalBody>
        {modalDeleteContent.footer ? (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalDeleteProject}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => deleteProject(modalDeleteContent.idProject)}
            >
              Apagar
            </Button>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Header />

      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        {/* Início da exibição da lista de projetos */}
        <div className="container p-0" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4 m-0">
              <Row className="m-0">
                <Col className="mt-auto mb-auto col-lg-6">
                  <h2 className="m-0 text-start">
                    <strong>Projetos</strong>
                    </h2>
                  <Tooltip id="my-tooltip" style={{ zIndex: "1500" }} />
                </Col>
                <Col lg={3} className="pr-0 m-0 row col-lg-2" >
                  <div className="text-end" style={{ marginLeft:"80px", marginTop:"10px" }}>
                    {/* Início botão de adicionar novo projeto */}
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "EMPLOYEE"
                      // "MANAGER"
                    ) ? (
                      <Link
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Criar um novo projeto"
                        className="text-end"
                        style={{
                          width:"70%",
                          background: "linear-gradient(90deg, #68f1fc 0%, #0082ff 50%, #202e79 100%)",
                          border: "none",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "transform 0.2s",
                        }}
                        to={"/novoprojeto"}
                      >
                       <AddIconButton width="30px" height="30px" />
                       <span className="fs-6">Novo Projeto</span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {/* Fim botão de adicionar novo projeto */}
                   
                  </div>
                </Col>
                <Col className="text-end col-lg-3">
                    <div  style={{  display: "flex",  marginLeft:"50px" }} >
                      <UncontrolledDropdown>
                      Ordernar por
                        <DropdownToggle  style={{ border: "none", backgroundColor: "#fff"}}>
                           <FilterButton />
                        </DropdownToggle>

                        <DropdownMenu style={{ zIndex: "2000" }}>
                          {/** FILTRO POR EMPRESAS  */}
                          {companiesList && Object.entries(companiesList).length > 1 && (
                            <>
                              {/* Opção "Todas" */}
                              <Row
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCompaniesAppliedFilterList(null);
                                }}
                              >
                                <Col sm={1} className="mt-auto mb-auto">
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      border: "1px solid #CCC",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {!companiesAppliedFilterList ||
                                    companiesAppliedFilterList.length === 0 ? (
                                      <BsCheck
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          marginTop: "-10px",
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </Col>
                                <Col>Todas</Col>
                              </Row>

                              {/* Lista de empresas */}
                              {Object.entries(companiesList).map((companyItem) => {
                                return (
                                  <Row
                                    key={companyItem[0]}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setLoading(true);

                                      // Se não houver nenhum filtro aplicado ainda
                                      if (
                                        !companiesAppliedFilterList ||
                                        companiesAppliedFilterList.length === 0
                                      ) {
                                        setTimeout(() => {
                                          setCompaniesAppliedFilterList([companyItem[0]]);
                                          setLoading(false);
                                        }, 100);
                                      } else {
                                        // Se já houver filtros aplicados
                                        let auxCompaniesAppliedFilterList =
                                          [...companiesAppliedFilterList];
                                        let exists = auxCompaniesAppliedFilterList.includes(
                                          companyItem[0]
                                        );

                                        if (exists) {
                                          auxCompaniesAppliedFilterList =
                                            auxCompaniesAppliedFilterList.filter(
                                              (item) => item !== companyItem[0]
                                            );
                                        } else {
                                          auxCompaniesAppliedFilterList.push(companyItem[0]);
                                        }

                                        setCompaniesAppliedFilterList(null);
                                        setTimeout(() => {
                                          setLoading(false);
                                          setCompaniesAppliedFilterList(
                                            auxCompaniesAppliedFilterList
                                          );
                                        }, 0.1);
                                      }
                                    }}
                                  >
                                    <Col sm={1} className="mt-auto mb-auto">
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          border: "1px solid #CCC",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {companiesAppliedFilterList
                                          ? companiesAppliedFilterList.map((item) => {
                                              if (item === companyItem[0]) {
                                                return (
                                                  <BsCheck
                                                    key={item}
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      marginTop: "-10px",
                                                    }}
                                                  />
                                                );
                                              }
                                              return null;
                                            })
                                          : null}
                                      </div>
                                    </Col>
                                    <Col>
                                      {companies
                                        ? companies.map((c) =>
                                            c.id.toString() === companyItem[0]
                                              ? c.name
                                              : null
                                          )
                                        : ""}
                                    </Col>
                                  </Row>
                                );
                              })}
                              <DropdownItem divider />
                            </>
                          )}

                          {/** ORDENAÇÃO */}
                          {/* ID */}
                          <DropdownItem
                            style={
                              filtroLista === "id09" || filtroLista === "id90"
                                ? { fontWeight: "700", fontStyle: "italic" }
                                : {}
                            }
                            onClick={() => {
                              if (filtroLista === "id09") setFiltroLista("id90");
                              else setFiltroLista("id09");
                            }}
                          >
                            ID ({filtroLista === "id09" ? "9 - 0" : "0 - 9"})
                          </DropdownItem>

                          {/* Nome */}
                          <DropdownItem
                            style={
                              filtroLista === "nameAZ" || filtroLista === "nameZA"
                                ? { fontWeight: "700", fontStyle: "italic" }
                                : {}
                            }
                            onClick={() => {
                              if (filtroLista === "nameAZ") setFiltroLista("nameZA");
                              else setFiltroLista("nameAZ");
                            }}
                          >
                            Nome ({filtroLista === "nameAZ" ? "Z - A" : "A - Z"})
                          </DropdownItem>

                          {/* Status */}
                          <DropdownItem
                            style={
                              filtroLista === "statusAZ" || filtroLista === "statusZA"
                                ? { fontWeight: "700", fontStyle: "italic" }
                                : {}
                            }
                            onClick={() => {
                              if (filtroLista === "statusAZ") setFiltroLista("statusZA");
                              else setFiltroLista("statusAZ");
                            }}
                          >
                            Status ({filtroLista === "statusAZ" ? "Z - A" : "A - Z"})
                          </DropdownItem>

                          {/* Data */}
                          <DropdownItem
                            style={
                              filtroLista === "start_date09" || filtroLista === "start_date90"
                                ? { fontWeight: "700", fontStyle: "italic" }
                                : {}
                            }
                            onClick={() => {
                              if (filtroLista === "start_date09")
                                setFiltroLista("start_date90");
                              else setFiltroLista("start_date09");
                            }}
                          >
                            Data (
                            {filtroLista === "start_date09" ? "9 - 0" : "0 - 9"})
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }}>
                <hr />

                <Row>
                  {/* <ListGroup>{projectItems}</ListGroup> */}
                  <Col className="m-2 text-start">
                    {projectItems.length > 0
                      ? projectItems.length + " projetos"
                      : ""}
                  </Col>
                </Row>

                {(!loadingProjects && projectItems.length === 0) && (
                  <div style={{textAlign: "center", padding: "2rem"}}>
                    Nenhum projeto encontrado!
                  </div>
                )}

                <Row>{projectItems}</Row>
              </div>
            </div>
          </div>
        </div>
        {/* Fim da exibição da lista de projetos */}
      </Container>
      {loading && loadingIcon()}
    </div>
  );
};

export default Projects;
