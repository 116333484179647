import React from 'react';
import TransformerIcon from '../../assets/icons/TRANSFORMADORES.svg';

const TransformerIconButton = ({ label, width = "20px", height = "20px" }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={TransformerIcon} alt={label} data-testid="transformer-icon" width={width} height={height}/>
    </button>
  );
};

export default TransformerIconButton;