import React from 'react';
import SupervisionOffIcon from '../../assets/icons/SUPERVISAO_OFF.svg';

const SupervisionOffIconButton = ({ label, width = "20px", height = "20px", isWhite = false}) => {
  return (
    <span
      aria-label={label}
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}
    >
      <img
        src={SupervisionOffIcon}
        alt={label}
        data-testid="supervisionOff-icon"
        width={width}
        height={height}
        style={{
          filter: isWhite ? "invert(1)" : "none", // Inverte as cores
        }}
      />
    </span>
  );
};


export default SupervisionOffIconButton;