import React from 'react';
import TreesIcon from '../../assets/icons/ARVORE_ATIVADA.svg';

const TreesIconButton = ({ label , width = "20px", height = "20px"}) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={TreesIcon} alt={label} data-testid="trees-icon" width={width} height={height}/>
    </button>
  );
};

export default TreesIconButton;