import React, { useState } from "react";
import { Input, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.css";

const SearchBar = ({ value, setValue, button = true }) => {
  const [showInput, setShowInput] = useState(false);

  const handleButtonClick = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="search-bar-container">
      {button && (
        <Button onClick={handleButtonClick}>
          <FaSearch />
        </Button>
      )}

      {(showInput || !button) && (
        <Input
          type="search"
          name="search"
          placeholder="Id"
          value={value}
          onChange={handleInputChange}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        />
      )}
    </div>
  );
};

export default SearchBar;
