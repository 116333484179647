import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Button,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { BsXLg } from "react-icons/bs";

const ModalFiltersTrees = ({
  toggle,
  onSave,
  treeIsLoaded,
  loadingCollision,
  criticalDistanceTrees,
  fallRiskTrees,
  setTrees,
  enableCriticalDistanceTrees,
  enableFallRiskTrees,
  handleCheckboxChange,
}) => {
  const [criticalVerticalDistance, setCriticalVerticalDistance] = useState(1);
  const [criticalHorizontalDistance, setCriticalHorizontalDistance] =
    useState(5);

  const handleSave = () => {
    if (!treeIsLoaded || !loadingCollision) {
      onSave({
        criticalVerticalDistance,
        criticalHorizontalDistance,
      });
    }
  };

  const handleCriticalDistanceColorChange = () => {
    setTrees((prevTrees) =>
      prevTrees.map((tree) => {
        if (enableCriticalDistanceTrees) {
          if (criticalDistanceTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: null };
          }
        } else {
          if (criticalDistanceTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: "red" };
          }
        }
        return tree;
      })
    );
  };

  const handleFallRiskColorChange = () => {
    setTrees((prevTrees) =>
      prevTrees.map((tree) => {
        if (enableFallRiskTrees) {
          if (fallRiskTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: null };
          }
        } else {
          if (fallRiskTrees.some((t) => t.id === tree.id)) {
            return { ...tree, color: "orange" };
          }
        }
        return tree;
      })
    );
  };

  return (
    <Card
      style={{
        right: "120px",
        top: "95px",
        position: "absolute",
        zIndex: "1000",
        display: "block",
        overflowX: "scroll",
        width: "320px",
        background: "rgba(230, 230, 230, .95)",
      }}
    >
      <CardTitle>
        <div className="d-flex align-items-center p-3 gap-1">
          <Col style={{ fontSize: "1.2em" }}>
            Filtro de colisão para vegetação
          </Col>
          <BsXLg onClick={toggle} style={{ cursor: "pointer" }} />
        </div>
        <hr className="m-0" />
      </CardTitle>
      <CardBody
        style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
      >
        {/* Range inputs */}
        <Row className="align-items-center mb-2">
          <Col>
            <Label>Distância vertical crítica (m):</Label>
          </Col>
          <Col xs="auto">
            <span className="text-muted">{criticalVerticalDistance} m</span>
          </Col>
          <Col xs="12">
            <Input
              type="range"
              min="0"
              max="10"
              step="1"
              value={criticalVerticalDistance}
              onChange={(e) => setCriticalVerticalDistance(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="align-items-center mb-2">
          <Col>
            <Label>Distância horizontal crítica (m):</Label>
          </Col>
          <Col xs="auto">
            <span className="text-muted">{criticalHorizontalDistance} m</span>
          </Col>
          <Col xs="12">
            <Input
              type="range"
              min="0"
              max="10"
              step="1"
              value={criticalHorizontalDistance}
              onChange={(e) => setCriticalHorizontalDistance(e.target.value)}
            />
          </Col>
        </Row>

        <Row className="align-items-center mb-2">
          <Col>
            <Input
              type="checkbox"
              style={{ marginRight: ".5rem" }}
              disabled={fallRiskTrees.length === 0}
              checked={enableFallRiskTrees}
              onChange={() => {
                handleCheckboxChange("fallRiskTrees");
                handleFallRiskColorChange();
              }}
            />

            <Label>Risco de queda</Label>
          </Col>
        </Row>

        <Row className="align-items-center mb-2">
          <Col>
            <Input
              style={{ marginRight: ".5rem" }}
              type="checkbox"
              disabled={criticalDistanceTrees.length === 0}
              checked={enableCriticalDistanceTrees}
              onChange={() => {
                handleCheckboxChange("criticalDistanceTrees");
                handleCriticalDistanceColorChange();
              }}
            />
            <Label>Distância crítica</Label>
          </Col>
        </Row>
        {/* Save Button */}

        <Button
          onClick={handleSave}
          color="primary"
          className="mt-3"
          id="search-connections-button"
          disabled={loadingCollision || !treeIsLoaded}
        >
          {loadingCollision || !treeIsLoaded ? (
            <Spinner>Loading...</Spinner>
          ) : (
            "Buscar"
          )}
        </Button>

        {(loadingCollision || !treeIsLoaded) && (
          <UncontrolledTooltip
            placement="top"
            target="search-connections-button"
          >
            {loadingCollision
              ? "Buscando árvores!"
              : "Carregando árvores no mapa!"}
          </UncontrolledTooltip>
        )}

        <Row className="align-items-center mb-2 mt-2">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "orange",
                  borderRadius: "50%",
                  marginRight: ".5rem",
                }}
              ></div>
              <p style={{ margin: "0" }}>Risco de queda</p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mb-2">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  marginRight: ".5rem",
                }}
              ></div>
              <p style={{ margin: "0" }}>Distância crítica</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ModalFiltersTrees;
