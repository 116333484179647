import React, { useEffect, useMemo, useState } from "react";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import PhotoSphereViewer from "../PhotoSphereViewer/PhotoSphereViewer";
import "./GalleryCarousel.css";
import api from "../../api/api";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

const classNames = [
  "cross_head",
  "lamp",
  "lamp_arm",
  "transformer",
  "telecom_box",
  "wire_reserve",
  "fiber_optic_junction_box",
  "pole",
  "tree",
];

const classNameAdapt = [
  "Cruzeta",
  "Lâmpada",
  "Braço de luminária",
  "Transformador",
  "Caixa de telecom",
  "Reserva de fios",
  "Caixa junção fibra óptica",
  "Poste",
  "Árvore",
];

const GalleryCarousel = ({ imageArray, index, detectedObjectType }) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const [animating, setAnimating] = useState(false);
  const [allDetections, setAllDetections] = useState(false);
  const [allDetectionsArray, setAllDetectionsArray] = useState([]);
  const [checkboxFilter, setCheckboxFilter] = useState(new Set());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch initial detections
    fetchDetections(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, detectedObjectType]);

  const fetchDetections = async (index) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/images/${imageArray[index].image.id}/detections-obb`
      );

      const detectedObjectId = imageArray[index].detectedObjectId;

      const filteredData = response.data.filter((item) => {
        if (item.objectType === "pole") {
          return item.detectedObjectId === detectedObjectId;
        }
        return detectedObjectType === "pole"
          ? item.poleId === detectedObjectId
          : item.objectType !== "tree";
      });

      setAllDetectionsArray(filteredData);
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  const navigate = async (nextIndex) => {
    if (animating) return;
    setActiveIndex(nextIndex);

    if (allDetections) {
      await fetchDetections(nextIndex);
    }
  };

  const next = () =>
    navigate(activeIndex === imageArray.length - 1 ? 0 : activeIndex + 1);
  const previous = () =>
    navigate(activeIndex === 0 ? imageArray.length - 1 : activeIndex - 1);
  const goToIndex = (newIndex) => navigate(newIndex);

  const typeCounts = useMemo(
    () =>
      allDetectionsArray.reduce((counts, item) => {
        counts[item.objectType] = (counts[item.objectType] || 0) + 1;
        return counts;
      }, {}),
    [allDetectionsArray]
  );

  const updateFilters = (checked, categoryFilter) => {
    setCheckboxFilter((prev) => {
      const next = new Set(prev);
      if (checked) {
        next.add(categoryFilter);
      } else {
        next.delete(categoryFilter);
      }
      return next;
    });
  };

  const filteredProducts = () =>
    checkboxFilter.size === 0
      ? allDetectionsArray
      : allDetectionsArray.filter((p) => checkboxFilter.has(p.objectType));

  const handleAllDetections = async () => {
    await fetchDetections(activeIndex); // Fetch detections for the activeIndex
    setAllDetections(!allDetections);
  };

  return (
    <>
      {detectedObjectType === "pole" && (
        <div className="checkbox-container">
          <div className="all-detections-checkbox">
            <input
              type="checkbox"
              id="type-checkbox"
              checked={allDetections}
              onChange={handleAllDetections}
            />
            <label htmlFor="type-checkbox">
              Visualizar todas as detecções.
            </label>
          </div>

          {allDetections && (
            <div className="filter-options-container-carousel">
              {classNames.map(
                (className, index) =>
                  typeCounts[className] > 0 && (
                    <div key={className} className="filter-option-carousel">
                      <input
                        type="checkbox"
                        id={className}
                        onChange={(e) =>
                          updateFilters(e.target.checked, className)
                        }
                      />
                      <label htmlFor={className}>
                        <strong>{`${classNameAdapt[index]} (${typeCounts[className]})`}</strong>
                      </label>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      )}

      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        <CarouselIndicators
          items={imageArray}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {imageArray.map((item, idx) => (
          <CarouselItem
            key={Math.random()}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            active={idx === activeIndex}
          >
            {!loading && idx === activeIndex && (
              <PhotoSphereViewer
                containerId={`viewer-${idx}`}
                imgSrc={item.image}
                detection={allDetections ? filteredProducts() : item}
                height={allDetections ? "75vh" : "85vh"}
              />
            )}

            {loading && (
              <div style={{ height: allDetections ? "75vh" : "85vh" }}>
                <LoadingIcon />
              </div>
            )}
          </CarouselItem>
        ))}

        {imageArray.length > 1 && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </>
        )}
      </Carousel>
    </>
  );
};

export default GalleryCarousel;
