import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";

const clarityId = process.env.REACT_APP_CLARITY_ID;

export default function App() {
  useEffect(() => {
    const isProduction = window.location.hostname === "streetcensus.robotictech.com.br"; 

    if (isProduction) {
      clarity.init(clarityId);
    } 
  }, []);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
