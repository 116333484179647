import React from "react";
import { BsXLg } from "react-icons/bs";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";

const ModalMarkersTransform = ({
  transform,
  numSelectedPoints,
  toggle,
  clear,
  save,
}) => {
  return (
    <>
      <Card
        style={{
          right: "125px",
          bottom: "95px",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          width: "350px",
        }}
      >
        <CardTitle>
          <div className="d-flex align-items-center p-3 gap-1">
            <BsXLg style={{ cursor: "pointer" }} onClick={toggle} />
          </div>

          <hr className="m-0" />
        </CardTitle>
        <CardBody>
          <p>Mova pelo menos 4 pontos da origem para o destino!</p>
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Button color="primary" disabled={numSelectedPoints < 4} onClick={() => transform()}>
            Alinhar
          </Button>

          <Button color="primary" onClick={() => clear()}>Limpar</Button>

          <Button color="success" onClick={() => save()}>Salvar</Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ModalMarkersTransform;
