import React from 'react';
import CamIcon from '../../assets/icons/cam-drone.svg';

const CamDroneIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={CamIcon} alt={label} data-testid="cam-drone-icon" width="35px" height="35px"/>
    </button>
  );
};

export default CamDroneIconButton;