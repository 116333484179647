import { useEffect, useRef } from "react";
import { BsXLg } from "react-icons/bs";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Spinner,
} from "reactstrap";

const ModalMarkersMission = ({
  toggle,
  setPoles,
  setTrees,
  setCableSpacers,
  missions,
  loading,
  selectedMissions,
  setSelectedMissions,
  lastPageTrees,
  lastPageCableSpacers,
}) => {
  const hasProcessedTrees = useRef(false);
  const hasProcessedCableSpacers = useRef(false);

  const handleMissionChange = (missionId) => {
    setSelectedMissions((prevSelected) =>
      prevSelected.includes(missionId)
        ? prevSelected.filter((id) => id !== missionId)
        : [...prevSelected, missionId]
    );
  };

  const updateMarkersVisibility = (missionId, isEnabled) => {
    const mission = missions.find((m) => m.missionId === missionId);
    if (!mission) return;

    const updateVisibility = (items, missionItems) =>
      items.map((item) => ({
        ...item,
        enabled: missionItems.some((missionItem) => missionItem.id === item.id)
          ? isEnabled
          : item.enabled,
      }));

    setPoles((prevPoles) => updateVisibility(prevPoles, mission.poles));
    setTrees((prevTrees) => updateVisibility(prevTrees, mission.trees));
    setCableSpacers((prevSpacers) =>
      updateVisibility(prevSpacers, mission.cableSpacers)
    );
  };

  function handleCheckAll() {
    const allMissionsSelected = selectedMissions.length === missions.length;

    const newSelectedMissions = allMissionsSelected
      ? []
      : missions.map(({ missionId }) => missionId);

    setSelectedMissions(newSelectedMissions);

    missions.forEach(({ missionId }) => {
      updateMarkersVisibility(missionId, !allMissionsSelected);
    });
  }

  useEffect(() => {
    if (lastPageTrees && !hasProcessedTrees.current) {
      missions.forEach(({ missionId }) => {
        updateMarkersVisibility(
          missionId,
          selectedMissions.includes(missionId)
        );
      });
      hasProcessedTrees.current = true;
    }
  
    if (lastPageCableSpacers && !hasProcessedCableSpacers.current) {
      missions.forEach(({ missionId }) => {
        updateMarkersVisibility(
          missionId,
          selectedMissions.includes(missionId)
        );
      });
      hasProcessedCableSpacers.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPageTrees, lastPageCableSpacers]);

  return (
    <>
      <Card
        className="modal-pole-components-card"
        style={{
          right: "120px",
          top: "95px",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          maxHeight: "85%",
          maxWidth: "50%",
        }}
      >
        <CardTitle
          style={{
            position: "sticky",
            top: 0,
            zIndex: "2000",
            backgroundColor: "white",
            margin: "0",
          }}
        >
          <div className="d-flex align-items-center p-3 gap-1">
            <Col style={{ fontSize: "1.2em" }}>Filtro de Missões</Col>
            <BsXLg onClick={toggle} style={{ cursor: "pointer" }} />
          </div>
          <hr className="m-0" />
        </CardTitle>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!loading && (
              <>
                <div className="form-check">
                  <Input
                    type="checkbox"
                    id="mission-all"
                    name="all-missions"
                    value=""
                    checked={selectedMissions.length === missions.length}
                    onChange={handleCheckAll}
                  />
                  <Label htmlFor="mission-all" className="form-check-label">
                    Todas
                  </Label>
                </div>

                {missions?.map(({ missionId, name }) => (
                  <div key={missionId} className="form-check">
                    <Input
                      type="checkbox"
                      id={`mission-${missionId}`}
                      name={name}
                      value={missionId}
                      checked={selectedMissions?.includes(missionId)}
                      onChange={() => {
                        handleMissionChange(missionId);
                        updateMarkersVisibility(
                          missionId,
                          !selectedMissions.includes(missionId)
                        );
                      }}
                    />
                    <Label
                      htmlFor={`mission-${missionId}`}
                      className="form-check-label"
                    >
                      {name}
                    </Label>
                  </div>
                ))}
              </>
            )}

            {loading && (
              <div className="m-auto text-center">
                <Spinner
                  color="primary"
                  style={{
                    margin: "auto",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            )}

            {!loading && missions.length === 0 && (
              <div className="m-auto text-center">
                <span>Missões não encontradas!</span>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ModalMarkersMission;
