import React from 'react';
import LampArmIcon from '../../assets/icons/BRACO_DE_LAMPADA.svg';

const LampArmIconButton = ({ label, width="20px", height="20px" }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={LampArmIcon} alt={label} data-testid="lamparm-icon"  width={width} height={height}/>
    </button>
  );
};

export default LampArmIconButton;