import * as turf from "@turf/turf";
import { default as L, default as Leaflet } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { BsFillTrashFill, BsPencil, BsPencilFill } from "react-icons/bs";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import api from "../../api/api";
import MapReportAreaButton from "../MapReportAreaButton/MapReportAreaButton";

const mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);
const defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
const defaultTreePointColor = process.env.REACT_APP_DEFAULT_TREE_POINT_COLOR;
const defaultCableSpacersColor =
  process.env.REACT_APP_DEFAULT_CABLE_SPACER_POINT_COLOR;
const defaultZoom = process.env.REACT_APP_DEFAULT_ZOOM;

const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);

const MapReportArea = ({ setAreasOfInterest }) => {
  const numberValuesApi = 150;

  const [poles, setPoles] = useState([]);
  const [trees, setTrees] = useState([]);
  const [cableSpacers, setCableSpacers] = useState([]);

  const [enablePoles, setEnablePoles] = useState(true);
  const [enableTrees, setEnableTrees] = useState(false);
  const [enableCableSpacers, setEnableCableSpacers] = useState(false);

  const [lastPagePoles, setLastPagePoles] = useState(false);
  const [pageNoPoles, setPageNoPoles] = useState(0);
  const [lastPageTrees, setLastPageTrees] = useState(false);
  const [pageNoTrees, setPageNoTrees] = useState(0);
  const [lastPageCableSpacers, setLastPageCableSpacers] = useState(false);
  const [pageNoCableSpacers, setPageNoCableSpacers] = useState(0);

  const [draw, setDraw] = useState(false);

  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);

  const [mapBounds, setMapBounds] = useState(null);
  const [centerAuto, setCenterAuto] = useState(true);

  const [layers, setLayers] = useState([]);

  const polesRef = useRef(poles);
  const treesRef = useRef(trees);
  const cableSpacersRef = useRef(cableSpacers);
  const drawControlRef = useRef(null);
  const drawnLayersRef = useRef(new Leaflet.FeatureGroup());

  let { id: projectId } = useParams();

  const handleMoveEnd = () => {
    const bounds = map.getBounds();
    setMapBounds(bounds);
  };

  useEffect(() => {
    polesRef.current = [...poles];
    treesRef.current = [...trees];
    cableSpacersRef.current = [...cableSpacers];
  }, [poles, trees, cableSpacers]);

  useEffect(() => {
    if (map != null) {
      map.on("moveend", handleMoveEnd);

      return () => {
        map.off("moveend", handleMoveEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const createCustomIcon = (color) => {
    const customSvgIcon = `
      <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="15" stroke="${color}" stroke-width="0" fill="transparent" />
        <circle cx="20" cy="20" r="5" fill="${color}" />
      </svg>
    `;

    return Leaflet.divIcon({
      html: customSvgIcon,
      className: "custom-icon",
      iconSize: [40, 40],
      iconAnchor: [20, 20],
    });
  };

  let centerTag = {
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  };

  const getPointsMarkers = (points, color, type) => {
    let pointMarkers = [];

    if (mapBounds) {
      points = points.filter((point) => {
        const { latitude, longitude } = point;
        return mapBounds.contains([latitude, longitude]);
      });
    }

    points.map((point) => {
      pointMarkers.push(
        <Marker
          key={Math.random()}
          position={[point.latitude, point.longitude]}
          icon={createCustomIcon(color)}
        />
      );

      if (type === "poles") {
        if (!centerTag.latMax || point.latitude < centerTag.latMax)
          centerTag.latMax = point.latitude;
        if (!centerTag.latMin || point.latitude > centerTag.latMin)
          centerTag.latMin = point.latitude;
        if (!centerTag.lngMax || point.longitude < centerTag.lngMax)
          centerTag.lngMax = point.longitude;
        if (!centerTag.lngMin || point.longitude > centerTag.lngMin)
          centerTag.lngMin = point.longitude;

        pointMarkers.push(
          <RecenterAutomatically
            key={Math.random()}
            lat={(centerTag.latMax + centerTag.latMin) / 2}
            lng={(centerTag.lngMax + centerTag.lngMin) / 2}
          />
        );
      }

      return null;
    });
    return pointMarkers;
  };

  const RecenterAutomatically = ({ lat, lng }) => {
    let diffLat = centerTag.latMax - centerTag.latMin;
    if (diffLat < 0) diffLat *= -1;
    const map = useMap();
    useEffect(() => {
      if (centerAuto) {
        let diffLat = centerTag.latMax - centerTag.latMin;

        if (diffLat < 0) diffLat *= -1;

        let zoomLevel = 17;

        if (diffLat * 10000 < 10) zoomLevel = 19;
        else if (diffLat * 10000 < 70) zoomLevel = 17;
        else if (diffLat * 10000 < 150) zoomLevel = 16;
        else if (diffLat * 10000 < 300) zoomLevel = 15;
        else if (diffLat * 10000 < 500) zoomLevel = 14;
        else if (diffLat * 10000 < 1000) zoomLevel = 13;
        else if (diffLat * 10000 < 8000) zoomLevel = 11;
        else if (diffLat * 10000 < 10000) zoomLevel = 8;
        else if (diffLat * 10000 < 35000) zoomLevel = 6;
        else zoomLevel = 2;

        let newLat = lat;
        let newLng = lng;
        if (lat === 0 || lng === 0) {
          newLat = -19.54708428614826;
          newLng = -40.513774803548415;
          zoomLevel = 7;
        }
        map.setView([newLat, newLng], zoomLevel);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return null;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (!lastPagePoles) {
          const polesResponse = await api.get(
            `/projects/${projectId}/poles?page=${pageNoPoles}&size=${numberValuesApi}`
          );

          const polesData = polesResponse.data;

          setLastPagePoles(polesData.last);
          setPageNoPoles(polesData.number + 1);

          setPoles((prevMapPoints) => [...prevMapPoints, ...polesData.content]);
        }

        if (enableTrees && !lastPageTrees) {
          await fetchDetectedObjectsPoints(
            pageNoTrees,
            setTrees,
            setPageNoTrees,
            setLastPageTrees,
            "trees"
          );
        }

        if (enableCableSpacers && !lastPageCableSpacers) {
          await fetchDetectedObjectsPoints(
            pageNoCableSpacers,
            setCableSpacers,
            setPageNoCableSpacers,
            setLastPageCableSpacers,
            "cable-spacers"
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastPagePoles,
    lastPageTrees,
    pageNoPoles,
    pageNoTrees,
    enableTrees,
    enableCableSpacers,
    projectId,
    numberValuesApi,
  ]);

  const fetchDetectedObjectsPoints = async (
    pageNo,
    setObjectState,
    setPageNoState,
    setLastPageState,
    endpoint
  ) => {
    const response = await api.get(
      `/projects/${projectId}/${endpoint}?page=${pageNo}&size=${numberValuesApi}`
    );

    const responseData = response.data;

    setLastPageState(responseData.last);
    setPageNoState(responseData.number + 1);

    setObjectState((prev) => [...prev, ...responseData.content]);
  };

  const renderMarkers = (points, markerRenderer) => {
    return points.map(
      ({ enabled, data, color, type }) =>
        enabled && markerRenderer(data, color, type)
    );
  };

  const getColorFromList = (index) => {
    const allColors = colorLayersList.flat();
    return allColors[index % allColors.length];
  };

  const onDrawCreate = (e) => {
    const layer = e.layer;
    const id = layer._leaflet_id;

    const color = getColorFromList(id);

    if (layer instanceof L.Polygon) {
      layer.setStyle({ color, fillColor: color, fillOpacity: 0.5 });

      const coordinates = layer.getLatLngs();
      setLayers((prevLayers) => [
        ...prevLayers,
        { id, layer, coordinates, color },
      ]);
    }

    if (drawnLayersRef.current) {
      drawnLayersRef.current.addLayer(layer);
    }
    
    setDraw(false);
  };

  useEffect(() => {
    setAreasOfInterest(
      layers.map((layer) => layer.coordinates[0].map((point) => [point.lng, point.lat])) 
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers]);
  

  const startDrawingPolygon = () => {
    setDraw(true);

    if (!drawControlRef.current) {
      drawControlRef.current = new L.Draw.Polygon(map); // Initialize drawControl
    }

    drawControlRef.current.enable(); // Enable drawing
  };

  const deleteLayer = (id) => {
    if (drawnLayersRef.current) {
      const layerToRemove = layers.find((l) => l.id === id);

      if (layerToRemove) {
        drawnLayersRef.current.removeLayer(layerToRemove.layer);

        setLayers((prevLayers) => prevLayers.filter((l) => l.id !== id));  
      }
    }
  };

  const ZoomUpdate = () => {
    useMapEvents({
      zoomend: () => {
        setCenterAuto(false);
      },
    });
    return null;
  };

  const detectedObjects = [
    {
      enabled: draw,
      enableFunction: startDrawingPolygon,
      enabledImage: <BsPencilFill />,
      disabledImage: <BsPencil />,
      tooltipText: "Desenhar",
    },
    {
      enabled: enablePoles,
      enableFunction: setEnablePoles,
      enabledImage: (
        <img alt="icon" src="/imgs/POSTE-ATIVADO.svg" className="icon" />
      ),
      disabledImage: (
        <img alt="icon" src="/imgs/POSTE-DESATIVADO.svg" className="icon" />
      ),
      tooltipText: "Postes",
    },
    {
      enabled: enableTrees,
      enableFunction: setEnableTrees,
      enabledImage: (
        <img alt="icon" src="/imgs/ARVORE-ATIVADA.svg" className="icon" />
      ),
      disabledImage: (
        <img alt="icon" src="/imgs/ARVORE-DESATIVADA.svg" className="icon" />
      ),
      tooltipText: "Árvores",
    },
    {
      enabled: enableCableSpacers,
      enableFunction: setEnableCableSpacers,
      enabledImage: (
        <img alt="icon" src="/imgs/ESPACADOR-ATIVADO.svg" className="icon" />
      ),
      disabledImage: (
        <img alt="icon" src="/imgs/ESPACADOR-DESATIVADO.svg" className="icon" />
      ),
      tooltipText: "Espaçadores",
    },
  ];

  return (
    <>
      {loading ? (
        <Skeleton
          style={{
            backgroundColor: "#f0f0f0",
            width: "100%",
            height: "400px",
            borderRadius: "20px",
          }}
        />
      ) : (
        <div
          style={{
            position: "relative",
          }}
        >
          <Row
            className="ms-0 me-0 mt-3 mb-3 m-md-3 p-0"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "200px",
              zIndex: "1000",
            }}
          >
            {
              <small>
                <ListGroup>
                  {layers?.map((layer, cont = 0) => {
                    return (
                      <ListGroupItem
                        key={Math.random()}
                        action
                        className="pt-1 pb-1"
                      >
                        <Row className="ps-2 pe-2">
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor: layer.color,
                              borderRadius: "7px",
                            }}
                          ></div>
                          <Col>Layer #{cont}</Col>
                          <div
                            style={{
                              width: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteLayer(layer.id)}
                          >
                            <BsFillTrashFill />
                          </div>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </small>
            }
          </Row>

          <MapContainer
            ref={setMap}
            className="m-0"
            center={[-20.32138449535715, -40.339507198852004]}
            scrollWheelZoom={true}
            zoom={defaultZoom}
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            <ZoomUpdate />
            <TileLayer
              attribution={mapSourcePreview.attribution}
              url={mapSourcePreview.url}
              subdomains={mapSourcePreview.subDomains}
            />

            {renderMarkers(
              [
                {
                  enabled: enablePoles,
                  data: poles,
                  color: defaultPointColor,
                  type: "poles",
                },
                {
                  enabled: enableTrees,
                  data: trees,
                  color: defaultTreePointColor,
                  type: "trees",
                },
                {
                  enabled: enableCableSpacers,
                  data: cableSpacers,
                  color: defaultCableSpacersColor,
                  type: "cable-spacers",
                },
              ],
              getPointsMarkers
            )}

            <FeatureGroup ref={drawnLayersRef}>
              <EditControl
                position="topright"
                onCreated={onDrawCreate}
                draw={{
                  rectangle: false,
                  circle: false,
                  marker: false,
                  polyline: false,
                  circlemarker: false,
                  polygon: false,
                }}
                edit={{
                  remove: false,
                  edit: false,
                }}
              />
            </FeatureGroup>
          </MapContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: ".5rem",
            }}
          >
            <div
              className="m-0 secondary-menu-right"
              style={{ position: "unset" }}
            >
              <div className="m-0 p-3" style={{ display: "flex" }}>
                {detectedObjects.map(
                  (
                    {
                      enabled,
                      enableFunction,
                      enabledImage,
                      disabledImage,
                      tooltipText,
                    },
                    index
                  ) => (
                    <MapReportAreaButton
                      key={index}
                      enabled={enabled}
                      enableFunction={enableFunction}
                      tooltipText={tooltipText}
                      enabledImage={enabledImage}
                      disabledImage={disabledImage}
                      tooltipId={`tooltip-${index}`}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MapReportArea;
