export default class LocalCoordinateTransformer {
  calculateCenter(points) {
    const sum = points.reduce(
      (acc, point) => {
        acc.lat += point.latitude;
        acc.lon += point.longitude;
        return acc;
      },
      { lat: 0, lon: 0 }
    );

    return {
      latitude: sum.lat / points.length,
      longitude: sum.lon / points.length,
    };
  }

  latLonToCartesian(lat, lon, refLat, refLon) {
    const R = 6371000;

    const phi = (lat * Math.PI) / 180;
    const lambda = (lon * Math.PI) / 180;
    const phiRef = (refLat * Math.PI) / 180;
    const lambdaRef = (refLon * Math.PI) / 180;

    const x = R * Math.cos(phi) * Math.sin(lambda - lambdaRef);
    const y =
      R *
      (Math.cos(phiRef) * Math.sin(phi) -
        Math.sin(phiRef) * Math.cos(phi) * Math.cos(lambda - lambdaRef));
    const z = 0;

    return [x, y, z];
  }

  cartesianToLatLon(x, y, z, refLat, refLon) {
    const R = 6371000;

    const phiRef = (refLat * Math.PI) / 180;
    const lambdaRef = (refLon * Math.PI) / 180;

    const lambda =
      lambdaRef + Math.atan2(x, R * Math.cos(phiRef) - y * Math.sin(phiRef));
    const phi = Math.asin(
      (R * Math.sin(phiRef) + y * Math.cos(phiRef)) /
        Math.sqrt(R * R + x * x + y * y)
    );

    const lat = phi * (180 / Math.PI);
    const lon = lambda * (180 / Math.PI);

    return [lat, lon];
  }
}
