import React from 'react';
import DetailsIcon from '../../assets/icons/DETALHES.svg';

const DetailsIconButton = ({ label, width = "20px", height = "20px", isWhite = false}) => {
  return (
    <button
      aria-label={label}
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}
    >
      <img
        src={DetailsIcon}
        alt={label}
        data-testid="Details-icon"
        width={width}
        height={height}
        style={{
          filter: isWhite ? "invert(1)" : "none", // Inverte as cores
        }}
      />
    </button>
  );
};


export default DetailsIconButton;