import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  Navigate,
  Link,
  useParams,
} from "react-router-dom";
import CheckAuth from "../Auth/CheckAuth";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col,
  Container,
  Card,
  Badge,
} from "reactstrap";
import {
  BsFillExclamationTriangleFill,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
} from "react-icons/bs";
import "../../elements/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Circle, MapContainer, Polyline, TileLayer } from "react-leaflet";
import PaginationBar from "../../elements/PaginationBar/PaginationBar";
import EditIconButton from "../../elements/EditIcon/EditIcon";
import TrashIconButton from "../../elements/TrashIcon/TrashIcon";
import CopyIconButton from "../../elements/CopyIcon/CopyIcon";
import api from "../../api/api";

// Recupera informações do arquivo ".env"
let mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);

const missionStatus = [
  "Executando recorte do vídeo",
  "Executando atualização das imagens",
  "Executando Reconstrução 3D",
  "Executando detecção dos objetos",
  "Executando agrupamento das detecções",
  "Executando segmentação da via",
  "Executando atualização dos objetos",
];

const Missions = (props) => {
  // Define o título da aba/página
  document.title = "Lista de Missões";

  // Recupera informações do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;
  let defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;

  const navigate = useNavigate();
  const location = useLocation();
  const handleGoBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate("/home");
    }
  };

  // Recupera informações recebidas pela url
  let { id: projectId } = useParams();

  // Início aquisição das informações do usuário logado
  const [userPermission, setUserPermission] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  // Fim aquisição das informações do usuário logado

  // Hook de armazenamento dos dados do projeto
  const [projectData, setProjectData] = useState(false);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  // Hook de apoio para atualização dos dados da página
  // const [updatePage, setUpdatePage] = useState();

  // begin get projects list
  const [listProjectsList, setProjectsList] = useState(null);

  const getProjectsList = () => {
    fetch(
      // apiUrl +
      //   "/companies/" +
      //   localStorage.getItem("robotictech_company_id") +
      //   "/projects?size=10000",
      CheckPermission(
        userPermission && userPermission.role ? userPermission.role : "",
        "ADMIN"
      )
        ? apiUrl + "/projects?size=10000"
        : apiUrl +
            "/companies/" +
            localStorage.getItem("robotictech_company_id") +
            "/projects?size=10000",
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((plist) => {
        try {
          plist = JSON.parse(plist);
        } catch (error) {}
        let projectList = [];
        if (plist.content && plist.content.length > 0) {
          projectList = plist.content;
        }
        setProjectsList(projectList);

        return projectList;
      })
      .finally((res) => {});
  };
  // end get projects list

  // Begin controls modal cópia de imagens
  const [modalCopiaImagens, setModalCopiaImagens] = useState(false);
  const [modalCopiaImagensContent, setModalCopiaImagensContent] =
    useState(false);
  const openModalCopiaImagensContent = (title, body, cancel, confirm, ok) => {
    setModalCopiaImagensContent({
      title: title,
      body: body,
      cancel: cancel,
      confirm: confirm,
      ok: ok,
    });
    setModalCopiaImagens(true);
  };

  // const contador = (i) => {
  //   if (i < 10) {
  //     setTimeout(() => {
  //       console.log(i);
  //       contador(++i);
  //     }, 1000);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const enviaRequisicaoCopiaImagens = (selectedMissionId, selectedProject) => {
    let apiUrlFomatted =
      apiUrl +
      "/missions/" +
      selectedMissionId +
      "/copy-to-project-" +
      selectedProject;

    openModalCopiaImagensContent(
      "Copiar imagens da missão",
      <>
        <div className="m-4">
          <p className="text-start">Processo de cópia iniciado</p>
        </div>
        <ModalFooter className="mt-4">
          <Button
            color="secondary"
            onClick={
              () => setModalCopiaImagens(false)
              // window.location.reload(false)
            }
          >
            Ok
          </Button>
        </ModalFooter>
      </>
    );

    props.toast({
      title: "Copiar imagens da missão",
      message: (
        <>
          <div style={{ display: "flex" }}>
            <Spinner
              // className="m-auto"
              className="ms-2 me-2"
              style={{
                display: "block",
                width: "20px",
                height: "20px",
              }}
              color="primary"
            />
            Copiando...
          </div>
        </>
      ),
    });

    fetch(apiUrlFomatted, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        props.toast({
          title: "Copiar imagens da missão",
          message: (
            <div>
              <div className="mt-1 mb-1">Imagens copiadas.</div>
              <a
                className="btn btn-outline-secondary pt-1 pb-1 col-12"
                style={{ cursor: "pointer" }}
                href={"/missoes/" + selectedProject}
              >
                Ver projeto
              </a>
            </div>
          ),
          timer: 3000,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // End controls modal  cópia de imagens

  // Início do controle de exclusão de missões
  const [modalDeleteMission, setModalDeleteMission] = useState(false);
  const [modalContent, setModalDeleteMissionContent] = useState({});

  const toggleModalDeleteMission = () =>
    setModalDeleteMission(!modalDeleteMission);
  // const closeModalDeleteMission = () => {
  //   setUpdatePage(Math.random());
  //   setModalDeleteMission(false);
  // };

  const openModalDeleteMissionContent = (id, icon, title, body) => {
    setModalDeleteMissionContent({
      idMission: id,
      icon: icon,
      title: title,
      body: body,
      footer: true,
    });
    toggleModalDeleteMission();
  };

  const deleteMission = (id) => {
    setModalDeleteMissionContent({
      id: modalContent.id,
      icon: modalContent.icon,
      title: modalContent.title,
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footer: false,
    });
    fetch(apiUrl + "/missions/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        setModalDeleteMissionContent({
          id: modalContent.id,
          icon: <BsFillCheckCircleFill />,
          title: modalContent.title,
          body: "Missão apagada com sucesso.",
          footer: "reload",
        });
      })
      .catch((error) => {
        setModalDeleteMissionContent({
          id: modalContent.id,
          icon: <BsFillXCircleFill />,
          title: modalContent.title,
          body: "Não foi possível apagar a missão.",
          footer: false,
        });
        console.error("Error:", error);
      });
  };
  // Fim do controle de exclusão de missões

  // Hooks de armazenamento de missões
  const [missionItems, setMissionItems] = useState([]);
  const [missionItemsList, setMissionItemsList] = useState([]);
  const [updateItemsList, setUpdateItemsList] = useState(0);
  const [missionMapList, setMissionMapList] = useState([]);

  // Atualização da lista de missões
  useEffect(() => {
    let missionList = [];

    missionItemsList.forEach((element, cont = 0) => {
      let listItem = createMissionItemList(element, cont++);
      missionList.push(listItem);
    });
    setMissionItems([]);
    if (missionList.length > 0) setMissionItems(missionList);
    else setMissionItems(<div>Sem missões disponíveis</div>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemsList]);

  // Atualização da lista de missões do projeto

  // Obtenção dos dados do projeto
  const getProjectData = () => {
    fetch(apiUrl + "/projects/" + projectId, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        setProjectData(pulist);
        setDadosCarregados(true);
      })
      .finally(() => {});
  };

  // Detecção dos pontos extremos para centralização e zoom
  const calculateMaxMinCoord = (element) => {
    let latCenter = 0;
    let lngCenter = 0;
    let contPoints = 0;
    let latMax = null;
    let latMin = null;
    let lngMax = null;
    let lngMin = null;
    element.forEach((imePoint) => {
      contPoints++;
      latCenter = latCenter + imePoint.latitude;
      lngCenter = lngCenter + imePoint.longitude;
      if (!latMax || latMax < imePoint.latitude) {
        latMax = imePoint.latitude;
      }
      if (!latMin || latMin > imePoint.latitude) {
        latMin = imePoint.latitude;
      }
      if (!lngMax || lngMax < imePoint.longitude) {
        lngMax = imePoint.longitude;
      }
      if (!lngMin || lngMin > imePoint.longitude) {
        lngMin = imePoint.longitude;
      }
    });

    if (!latMax) latMax = 0;
    if (!latMin) latMin = 0;
    if (!lngMax) lngMax = 0;
    if (!lngMin) lngMin = 0;

    latCenter = latCenter / contPoints;
    lngCenter = lngCenter / contPoints;

    if (!latCenter) latCenter = -20.32138449535715;
    if (!lngCenter) lngCenter = -40.339507198852004;

    let diffLat = (latMax - latMin) * 10000;

    let zoomLevel = 0;

    if (!diffLat || (diffLat <= 1 && diffLat >= -1)) {
      zoomLevel = 2;
    } else {
      if (diffLat < 10) zoomLevel = 19;
      else if (diffLat < 70) zoomLevel = 16;
      else if (diffLat < 150) zoomLevel = 15;
      else if (diffLat < 300) zoomLevel = 14;
      else if (diffLat < 500) zoomLevel = 13;
      else if (diffLat < 1000) zoomLevel = 12;
      else if (diffLat < 8000) zoomLevel = 11;
      else if (diffLat < 10000) zoomLevel = 10;
      else if (diffLat < 35000) zoomLevel = 9;
      else if (diffLat < 50000) zoomLevel = 8;
      else if (diffLat < 80000) zoomLevel = 7;
      else if (diffLat < 100000) zoomLevel = 6;
      else if (diffLat < 150000) zoomLevel = 5;
      else zoomLevel = 2;
    }

    let data = {
      latCenter: latCenter,
      lngCenter: lngCenter,
      contPoints: contPoints,
      latMax: latMax,
      latMin: latMin,
      lngMax: lngMax,
      lngMin: lngMin,
      zoomLevel: zoomLevel,
    };
    return data;
  };

  // Cria o elemento da lista de missões
  const createMissionItemList = (element, id) => {
    const missionMapItem = missionMapList[element.id];
    let latCenter = -20.32138449535715;
    let lngCenter = -40.339507198852004;
    let zoomLevel = 16;

    if (missionMapItem && missionMapItem.maxMinCoord) {
      latCenter = missionMapItem.maxMinCoord.latCenter;
      lngCenter = missionMapItem.maxMinCoord.lngCenter;
      zoomLevel = missionMapItem.maxMinCoord.zoomLevel;
    }

    return (
      <Col key={Math.random()} lg={3} md={4} sm={6} xs={12}>
        {" "}
        {/* Responsividade definida */}
        <Card
          className="m-2 p-1"
          style={{
            minHeight: "300px",
            maxWidth: "300px",
            borderRadius: "15px",
            overflow: "hidden",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onDoubleClick={() => {
            navigate("/missoes/" + element.id + "/imagens/" + projectId);
          }}
        >
          {/* Header simples: nome da missão */}
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#FFF",
              padding: "10px 0",
              borderBottom: "1px solid #ddd",
            }}
          >
            <Link
              style={{
                marginTop: "-50px",
                zIndex: 1000,
                height: "80%",
                width: "65%",
                backgroundColor: "#FFF",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
              to={"/missoes/" + element.id + "/imagens/" + projectId}
            >
              <i>{element.name ? element.name : "Missão #" + element.id}</i>
            </Link>
          </div>

          {/* Mapa da missão */}
          <Link to={"/missoes/" + element.id + "/imagens/" + projectId}>
            <Row className="m-0">
              <MapContainer
                className="m-0"
                center={[latCenter, lngCenter]}
                zoom={zoomLevel}
                style={{ position: "relative", width: "100%", height: "200px" }}
                dragging={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
              >
                <TileLayer
                  attribution={mapSourcePreview.attribution}
                  url={mapSourcePreview.url}
                  subdomains={mapSourcePreview.subDomains}
                />

                {missionMapList[element.id]?.images && (
                  <Polyline
                    key={id}
                    positions={missionMapList[element.id].images.map(
                      (coord) => [coord.latitude, coord.longitude]
                    )}
                    weight={5}
                    color={defaultPointColor}
                  />
                )}
              </MapContainer>
            </Row>
          </Link>
          {/* Exemplo de um "rodapé" dentro do Card para cidade/descrição/etc */}
          <Row className="m-0 p-2">
            <Col className="mt-2 mb-2 text-center" style={{ color: "#bfbfbf" }}>
              <Badge color="primary" style={{ padding: "5px 5px" }}>
                {element.status} {getCurrentMissionStatusStep(element.status)}
              </Badge>
            </Col>
          </Row>
          {/* Botões de ação (pode ser substituído por dropdown, etc.) */}
          {dadosCarregados ? (
            <Row className="m-0 p-2 justify-content-center">
              {/* Copiar missão */}
              {CheckPermission(userPermission?.role || "", "EMPLOYEE") && (
                <Col className="text-center m-1">
                  <Button
                    className="btn btn-light d-flex justify-content-center align-items-center"
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    size="sm"
                    color="light"
                    onClick={() => {
                      let selectedProject = null;
                      openModalCopiaImagensContent(
                        "Copiar imagens da missão",
                        <div>
                          {listProjectsList && listProjectsList.length > 0 ? (
                            <div className="m-4">
                              <p className="text-start">
                                Selecione o projeto de destino
                              </p>
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    selectedProject = e.target.value;
                                  }}
                                >
                                  <option value={null}></option>
                                  {listProjectsList.map((proj) => (
                                    <option key={proj.id} value={proj.id}>
                                      {"#" + proj.id + " - " + proj.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            <Spinner
                              className="m-auto"
                              style={{ display: "block" }}
                              color="primary"
                            />
                          )}
                          <ModalFooter className="mt-4">
                            <Button
                              color="secondary"
                              onClick={() => setModalCopiaImagens(false)}
                            >
                              Cancelar
                            </Button>
                            <Button
                              color="success"
                              onClick={() =>
                                enviaRequisicaoCopiaImagens(
                                  element.id,
                                  selectedProject
                                )
                              }
                            >
                              Confirmar
                            </Button>
                          </ModalFooter>
                        </div>
                      );
                    }}
                  >
                    <CopyIconButton width="25px" height="25px" />
                  </Button>
                  <small>Copiar</small>
                </Col>
              )}

              {/* Editar missão */}
              {CheckPermission(userPermission?.role || "", "EMPLOYEE") &&
              projectData &&
              projectData.status !== "Finalizado" ? (
                <Col className="text-center m-1">
                  <Link
                    className="btn btn-light d-flex justify-content-center align-items-center"
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    to={"/editarmissao/" + element.id + "/" + projectId}
                  >
                    <EditIconButton width="25px" height="25px" />
                  </Link>
                  <small>Editar</small>
                </Col>
              ) : (
                ""
              )}

              {/* Excluir missão */}
              {CheckPermission(userPermission?.role || "", "EMPLOYEE") &&
                projectData &&
                projectData.status !== "Finalizado" && (
                  <Col className="text-center m-1">
                    <Button
                      className="btn btn-light d-flex justify-content-center align-items-center"
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                      }}
                      size="sm"
                      color="light"
                      onClick={() => {
                        openModalDeleteMissionContent(
                          element.id,
                          <BsFillExclamationTriangleFill />,
                          element.id + " - " + element.name,
                          "Deseja realmente apagar a missão " +
                            element.name +
                            " (#" +
                            element.id +
                            ")?"
                        );
                      }}
                    >
                      <TrashIconButton width="25px" height="25px" />
                    </Button>
                    <small>Excluir</small>
                  </Col>
                )}
            </Row>
          ) : (
            ""
          )}
        </Card>
      </Col>
    );
  };

  const fetchMissionImages = async (missionItemsList) => {
    if (missionItemsList && missionItemsList.length > 0) {
      try {
        const { data } = await api.get(
          `/projects/${projectId}/missions/images?step=5`
        );

        const missionData = data.reduce((acc, item) => {
          const images = item.images;

          if (Array.isArray(images) && images.length > 0) {
            const missionId = images[0].mission_id;
            acc[missionId] = {
              images,
              maxMinCoord: calculateMaxMinCoord(images),
            };
          }

          return acc;
        }, {});

        setMissionMapList(missionData);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }

    setUpdateItemsList(Math.random());
  };

  const [totalPages, setTotalPages] = useState(0);
  const [pageNo, setPageNo] = useState(0);

  // Obtenção da lista de missões do projeto
  async function getData() {
    getProjectsList();

    await fetch(
      apiUrl +
        "/projects/" +
        projectId +
        "/missions?page=" +
        pageNo +
        "&size=8&sort=name",
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
          setTotalPages(pulist.totalPages);
        } catch (error) {}
        let missionList = [];
        let missions = pulist.content;

        setMissionMapList([]);
        setUpdateItemsList(Math.random());

        fetchMissionImages(missions);

        setMissionItemsList(missions);
        missions.forEach((element, cont = 0) => {
          let listItem = createMissionItemList(element, cont++);

          missionList.push(listItem);
        });

        if (missionList.length > 0) {
          setMissionItems(missionList);
        } else {
          setMissionItems(<div>Sem missões disponíveis</div>);
        }

        return missionList;
      })
      .finally(() => {
        getProjectData();
      });
  }

  // Ao obter o token de acesso ou ao for necessário recarregar os dados
  // da página é definido o indicador de carregamento e realizada a
  // requisição para obter os dados.
  useEffect(() => {
    setMissionItems(
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission, pageNo]);

  useEffect(() => {}, [missionMapList]);

  // Verifica se o usuario está autenticado
  if (!CheckAuth()) {
    return <Navigate to="/login" />;
  }

  function getCurrentMissionStatusStep(currentMissionStatus) {
    let indexMission = missionStatus.indexOf(currentMissionStatus);

    if (indexMission === -1) {
      return "";
    }

    return `(${indexMission + 1}/${missionStatus.length})`;
  }

  // Constrói o layout da página
  return (
    <div className="p-2">
      <Tooltip id="my-tooltip" style={{ zIndex: "1000" }} />
      {/* Início modal de cópia de imagem */}
      <Modal // Modal copy
        isOpen={modalCopiaImagens}
        toggle={() => setModalCopiaImagens(false)}
        backdrop="static"
        className="p-0"
      >
        <ModalHeader toggle={() => setModalCopiaImagens(false)}>
          <div className="row m-0">
            <div className="col m-auto">
              {modalCopiaImagensContent.title
                ? modalCopiaImagensContent.title
                : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="m-0 p-0">
          {modalCopiaImagensContent.body ? modalCopiaImagensContent.body : ""}
        </ModalBody>
      </Modal>
      {/* Fim modal de cópia de imagem */}

      <Modal
        isOpen={modalDeleteMission}
        toggle={toggleModalDeleteMission}
        backdrop="static"
      >
        <ModalHeader>
          <div className="row m-0">
            <div className="col-2 m-auto">
              {modalContent.icon ? modalContent.icon : ""}
            </div>
            <div className="col m-auto">
              {modalContent.title ? modalContent.title : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>{modalContent.body ? modalContent.body : ""}</ModalBody>
        {modalContent.footer && modalContent.footer === "reload" ? (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => window.location.reload(false)}
            >
              Ok
            </Button>
          </ModalFooter>
        ) : modalContent.footer ? (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalDeleteMission}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                deleteMission(modalContent.idMission);
              }}
            >
              Apagar
            </Button>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container">
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }} id="uprojetos">
                <Row className="g-3">{missionItems}</Row>
                {totalPages > 1 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <PaginationBar
                      totalPages={totalPages}
                      currentPage={pageNo}
                      setCurrentPage={setPageNo}
                    />
                  </div>
                )}
                {missionItems && missionItems.length > 0 ? (
                  <Row className="m-0 pt-3">
                    <small style={{ fontSize: ".8em" }}>
                      *Os pontos apresentados no mapa são referentes a
                      geolocalização das imagens enviadas .
                    </small>
                  </Row>
                ) : (
                  ""
                )}
                {projectData.status === "Finalizado" ? (
                  <Row className="m-0 pt-1">
                    <small style={{ fontSize: ".8em" }}>
                      *As alterações nas missões deste projeto não estão
                      disponíveis devido ao projeto estar finalizado.
                    </small>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Missions;
