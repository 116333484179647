import React, { useEffect, useRef, useState } from "react";
import {
  Marker,
  MapContainer,
  TileLayer,
  useMapEvents,
  Polygon,
  FeatureGroup,
  Polyline,
} from "react-leaflet";
import Leaflet from "leaflet";
import DraggableMarker from "../DraggableMarker/DraggableMarker";
import api from "../../api/api";
import DraggableMapMarker from "../DraggableMapMarker/DraggableMapMarker";
import { EditControl } from "react-leaflet-draw";
import "./MapContainerComponent.css";
import ModalDragMapPoints from "../ModalDragMapPoints/ModalDragMapPoints";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import ModalMessage from "../ModalMessage/ModalMessage";
import L from "leaflet";
import * as turf from "@turf/turf";
import MarkerClusterGroup from "react-leaflet-cluster";
import { CreateCustomIcon } from "../CreateCustomIcon/CreateCustomIcon";
import SelectedIcon from "../SelectedIcon/SelectedIcon";
import { RecenterAutomatically } from "../RecenterAutomatically/RecenterAutomatically";
import PolylineDecorator from "../PolylineDecorator/PolylineDecorator";

import vtkLandmarkTransform from "@kitware/vtk.js/Common/Transform/LandmarkTransform";
import Mode from "@kitware/vtk.js/Common/Transform/LandmarkTransform";
import vtkPoints from "@kitware/vtk.js/Common/Core/Points";
import ModalMarkersTransform from "../ModalMarkersTransform/ModalMarkersTransform";
import LocalCoordinateTransformer from "../../utils/LocalCoordinateTransformer ";

const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);
const defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
const defaultTreePointColor = process.env.REACT_APP_DEFAULT_TREE_POINT_COLOR;
const defaultCableSpacersColor =
  process.env.REACT_APP_DEFAULT_CABLE_SPACER_POINT_COLOR;

const minZoomToGroupPoints = process.env.REACT_APP_MIN_ZOOM_TO_GROUP_POINTS;

let centerTag = {
  latMax: null,
  latMin: null,
  lngMax: null,
  lngMin: null,
};

const MapContainerComponent = ({
  zoom, // Recebendo zoom como prop
  baseMapSource,
  areaOfInterest,
  areasOfInterestActive,
  enableTreePointsView,
  enableCableSpacerPointsView,
  newPointPreviewMap,
  setHideLeftMenu,
  setOffCanvas,
  setPointDetailOffcanvas,
  mapPoints,
  trees,
  cableSpacers,
  newPointPosition,
  setNewPointPosition,
  activePointSupervision,
  setActivePointSupervision,
  previewImagesNewPoint,
  previewImagesVisible,
  searchBarValue,
  setLoading,
  carPathMapPoints,
  carPath,
  moveMapPoints,
  setMapPoints,
  setTrees,
  setCableSpacers,
  offCanvas,
  modalFilterPoleComponents,
  alignMarkers,
  toggleModalAlignMarkers,
  lastPageTrees,
  lastPageCableSpacers,
}) => {
  const [map, setMap] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [centerAuto, setCenterAuto] = useState(true);
  const [zoomLevelInfo, setZoomLevelInfo] = useState(zoom); // Utilize o zoom inicial recebido via props

  const [filteredMapPoints, setFilteredMapPoints] = useState([]);
  const [filteredTrees, setFilteredTrees] = useState([]);
  const [filteredCableSpacers, setFilteredCableSpacers] = useState([]);

  const [selectedPoints, setSelectedPoints] = useState([]);
  const [pointsToBeSaved, setPointsToBeSaved] = useState([]);

  const [singleSelection, setSingleSelection] = useState(false);

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [message, setMessage] = useState("");

  const toggleModalConfirmation = () =>
    setModalConfirmation(!modalConfirmation);

  const toggleModalMessage = () => setModalMessage(!modalMessage);

  const dragHistoryRef = useRef([]);

  const drawnLayersRef = useRef(new Leaflet.FeatureGroup());
  const drawControlRef = useRef(null);

  const markersRef = useRef({});

  const hasProcessedTrees = useRef(false);
  const hasProcessedCableSpacers = useRef(false);

  useEffect(() => {
    const handleMoveEnd = () => {
      const bounds = map.getBounds();
      setMapBounds(bounds);
    };

    if (map != null) {
      map.on("moveend", handleMoveEnd);

      return () => {
        map.off("moveend", handleMoveEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // Begin filtering
  useEffect(() => {
    const filterEntities = (entities, searchValue) =>
      entities.filter((entity) => entity.id.toString().includes(searchValue));

    const addEnabledAndDraggable = (filtered, original) =>
      filtered.map((item) => ({
        ...item,
        enabled: original.find((orig) => orig.id === item.id)?.enabled || false,
        draggable: true,
      }));

    const filterPoints = () => {
      setFilteredMapPoints(filterEntities(mapPoints, searchBarValue));
      setFilteredTrees(filterEntities(trees, searchBarValue));
      setFilteredCableSpacers(filterEntities(cableSpacers, searchBarValue));
    };

    const updateWithEnabledAndDraggable = () => {
      if (enableTreePointsView && !hasProcessedTrees.current) {
        setFilteredTrees(filterEntities(trees, searchBarValue));
        if (lastPageTrees) hasProcessedTrees.current = true;
      }

      if (enableCableSpacerPointsView && !hasProcessedCableSpacers.current) {
        setFilteredCableSpacers(filterEntities(cableSpacers, searchBarValue));
        if (lastPageCableSpacers) hasProcessedCableSpacers.current = true;
      }

      setFilteredMapPoints((filtered) =>
        addEnabledAndDraggable(filtered, mapPoints)
      );
      setFilteredTrees((filtered) => addEnabledAndDraggable(filtered, trees));
      setFilteredCableSpacers((filtered) =>
        addEnabledAndDraggable(filtered, cableSpacers)
      );
    };

    if (alignMarkers) {
      updateWithEnabledAndDraggable();
    } else {
      filterPoints();
    }
  }, [
    searchBarValue,
    mapPoints,
    trees,
    cableSpacers,
    alignMarkers,
    enableTreePointsView,
    enableCableSpacerPointsView,
    lastPageTrees,
    lastPageCableSpacers,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (map != null && mapPoints.length > 0 && searchBarValue.length > 3) {
        const allFilteredPoints = [
          ...filteredMapPoints.map((point) => ({ ...point, type: "poles" })),
          ...filteredTrees.map((tree) => ({ ...tree, type: "trees" })),
          ...filteredCableSpacers.map((cableSpacer) => ({
            ...cableSpacer,
            type: "cable-spacers",
          })),
        ];

        if (allFilteredPoints.length === 1) {
          const point = allFilteredPoints[0];

          setFocusPoint(point);
          setPointDetailOffcanvas({
            point: point,
            type: point.type,
          });
          setOffCanvas(true);
          setHideLeftMenu(true);

          map.setView([point.latitude, point.longitude], 16);

          if (point.type === "poles") {
            filteredMapPoints.filter((m) => m.id === point.id)[0][
              "radius"
            ] = 15;
          }

          if (point.type === "trees") {
            filteredTrees.filter((m) => m.id === point.id)[0]["radius"] = 15;
          }

          if (point.type === "cable-spacers") {
            filteredTrees.filter((m) => m.id === point.id)[0]["radius"] = 15;
          }
        }

        if (allFilteredPoints.length === 0) {
          try {
            setLoading(true);
            const { data } = await api.get(
              `/detected-objects/poles/components/${searchBarValue}`
            );

            if (data.length > 0) {
              const point = data[0];

              setFilteredMapPoints(data);
              setFocusPoint(point);
              setPointDetailOffcanvas({
                point: point,
                type: "poles",
                viewComponents: true,
              });
              setOffCanvas(true);
              setHideLeftMenu(true);

              map.setView([point.latitude, point.longitude], 16);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredCableSpacers,
    filteredMapPoints,
    filteredTrees,
    map,
    searchBarValue,
  ]);
  // End filtering

  // Begin Set active point in supervision
  useEffect(() => {
    if (map != null && activePointSupervision != null) {
      setFocusPoint(activePointSupervision);
      setPointDetailOffcanvas({
        point: activePointSupervision,
        type: "poles",
      });
      setOffCanvas(true);
      setHideLeftMenu(true);

      if (zoomLevelInfo <= minZoomToGroupPoints) {
        map.setView(
          [activePointSupervision.latitude, activePointSupervision.longitude],
          minZoomToGroupPoints
        );
      }
      map.setView([
        activePointSupervision.latitude,
        activePointSupervision.longitude,
      ]);

      if (!mapPoints.some((m) => m.id === activePointSupervision.id)) {
        mapPoints.push(activePointSupervision);
      }

      mapPoints.filter((m) => m.id === activePointSupervision.id)[0][
        "radius"
      ] = 15;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePointSupervision]);
  // End Set active point in supervision

  const setFocusPoint = (point) => {
    let mapMarkers = [
      ...filteredMapPoints,
      ...filteredTrees,
      ...filteredCableSpacers,
    ];

    mapMarkers.forEach((value) => {
      value.radius = value.id === point?.id ? 15 : false;
    });
  };

  const getPointsMarkers = (points, icon, color, type) => {
    let pointMarkers = [];
  
    if (mapBounds) {
      points = points.filter((point) => {
        const { latitude, longitude } = point;
        return mapBounds.contains([latitude, longitude]);
      });
    }

    points.map((point) => {
      if (!point.enabled) {
        return null;
      }

      const handleClick = () => {
        if (type === "poles") {
          setActivePointSupervision(point);
        }

        setFocusPoint(point);

        setPointDetailOffcanvas({
          point,
          type,
          viewComponents: modalFilterPoleComponents,
        });

        setOffCanvas(true);
        setHideLeftMenu(true);

        if (zoomLevelInfo <= minZoomToGroupPoints) {
          map.setView([point.latitude, point.longitude], minZoomToGroupPoints);
        }
        map.setView([point.latitude, point.longitude]);
      };

      if (!offCanvas) {
        point.radius = false;
      }

      pointMarkers.push(
        <DraggableMapMarker
          key={Math.random()}
          point={point}
          icon={icon}
          color={color}
          clickFunction={handleClick}
          draggable={point.draggable}
          onDragStart={handleMarkerDragStart}
          onDragEnd={handleMarkerDragEnd}
          onDrag={handleMarkerDrag}
          markerRef={markersRef}
        />
      );

      if (type === "poles") {
        if (!centerTag.latMax || point.latitude < centerTag.latMax)
          centerTag.latMax = point.latitude;
        if (!centerTag.latMin || point.latitude > centerTag.latMin)
          centerTag.latMin = point.latitude;
        if (!centerTag.lngMax || point.longitude < centerTag.lngMax)
          centerTag.lngMax = point.longitude;
        if (!centerTag.lngMin || point.longitude > centerTag.lngMin)
          centerTag.lngMin = point.longitude;

        pointMarkers.push(
          <RecenterAutomatically
            key={Math.random()}
            lat={(centerTag.latMax + centerTag.latMin) / 2}
            lng={(centerTag.lngMax + centerTag.lngMin) / 2}
            centerAuto={centerAuto}
            centerTag={centerTag}
          />
        );
      }

      return null;
    });
    return pointMarkers;
  };

  const renderMarkersWithClustering = (
    points,
    markerRenderer,
    threshold = 100
  ) => {
    const totalPoints = points.reduce(
      (sum, { enabled, data = [] }) => sum + (enabled ? data.length : 0),
      0
    );

    if (totalPoints > threshold && zoomLevelInfo < 17) {
      return (
        <MarkerClusterGroup
          disableClusteringAtZoom={17} // disables clustering when the map zoom level reaches the value.
          spiderfyDistanceMultiplier={0} // When multiple markers are at the exact same location, it spreads them into a circle.
          maxClusterRadius={30} // This determines the maximum radius that the clustering engine will consider to group markers together.
          showCoverageOnHover={true}
        >
          {points.map(
            ({ enabled, data, icon, color, type }) =>
              enabled && markerRenderer(data, icon, color, type)
          )}
        </MarkerClusterGroup>
      );
    }

    return points.map(
      ({ enabled, data, icon, color, type }) =>
        enabled && markerRenderer(data, icon, color, type)
    );
  };

  const renderMarkersInitialPosition = (markers, color) => {
    let pointMarkers = [];

    markers?.map((marker) => {
      pointMarkers.push(
        <Marker
          key={Math.random()}
          icon={CreateCustomIcon(color)}
          position={[marker.initialLat, marker.initialLng]}
          style={{ cursor: "default" }}
        />
      );

      pointMarkers.push(
        <Polyline
          key={Math.random()}
          positions={[
            [marker.initialLat, marker.initialLng],
            [marker.latitude, marker.longitude],
          ]}
          weight={2}
          color={color}
        />
      );

      return null;
    });
    return pointMarkers;
  };

  const getPreviewImagesMarkers = (points, color) => {
    let pointMarkers = [];

    points.map((point) => {
      pointMarkers.push(
        <Marker
          key={Math.random()}
          icon={CreateCustomIcon(color)}
          position={[point.latitude, point.longitude]}
          style={{ cursor: "default" }}
        />
      );
      return null;
    });
    return pointMarkers;
  };

  useEffect(() => {
    if (moveMapPoints || alignMarkers) {
      updateDraggable(
        filteredMapPoints,
        selectedPoints,
        setFilteredMapPoints,
        true
      );

      if (filteredTrees.length > 0) {
        updateDraggable(filteredTrees, selectedPoints, setFilteredTrees, true);
      }

      if (filteredCableSpacers.length > 0) {
        updateDraggable(
          filteredCableSpacers,
          selectedPoints,
          setFilteredCableSpacers,
          true
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoints, moveMapPoints, alignMarkers]);

  const updateDraggable = (
    pointsArray,
    selectedPoints,
    setStateFunction,
    drag
  ) => {
    const updatedPoints = pointsArray.map((point) => {
      const isSelected = selectedPoints.includes(String(point.id));
      return { ...point, draggable: isSelected ? drag : point.draggable };
    });

    setStateFunction(updatedPoints);
  };

  const beginMarkersSelection = () => {
    const newPoints = Object.entries(markersRef.current).map(([id]) => id);
    setSelectedPoints(newPoints);
    setSingleSelection(true);
  };

  useEffect(() => {
    if (!moveMapPoints) {
      handleClearAll();
    }

    if (moveMapPoints || alignMarkers) {
      beginMarkersSelection();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveMapPoints, alignMarkers]);

  const onDrawCreate = (e) => {
    setSingleSelection(false);

    const layer = e.layer;
    const geojson = layer.toGeoJSON();

    const polygon = turf.polygon(geojson.geometry.coordinates);

    const pointsWithinPolygon = Object.entries(markersRef.current)
      .filter(([id, marker]) => {
        const latLng = marker?.getLatLng();
        const point = turf.point([latLng.lng, latLng.lat]);
        return turf.booleanPointInPolygon(point, polygon);
      })
      .map(([id]) => id);

    setSelectedPoints(pointsWithinPolygon);
  };

  const handleClearAll = () => {
    clearPolygon();

    dragHistoryRef.current = [];

    beginMarkersSelection();
    resetMapPointsRadius();
    setFilteredMapPoints(mapPoints);
    setFilteredTrees(trees);
    setFilteredCableSpacers(cableSpacers);
    setPointsToBeSaved([]);
  };

  const resetMapPointsRadius = () => {
    let mapMarkers = [...mapPoints, ...trees, ...cableSpacers];

    mapMarkers.forEach((value) => {
      value.radius = false;
    });
  };

  const handleMarkerDragStart = (point) => {
    if (!point) return;

    const getInitialPosition = (id) => {
      const marker = markersRef.current[id];
      if (marker) {
        const latLng = marker.getLatLng();
        return { id, lat: latLng.lat, lng: latLng.lng };
      }
      return { id, lat: point.latitude, lng: point.longitude };
    };

    const initialPositions = singleSelection
      ? [getInitialPosition(point.id)]
      : selectedPoints.map((id) => getInitialPosition(id));

    dragHistoryRef.current.push({
      type: "drag",
      points: initialPositions,
    });

    point.initialPositions = initialPositions;
    point.initialPosition = getInitialPosition(point.id);
  };

  const handleMarkerDrag = (event, point) => {
    const { lat: initialLat, lng: initialLng } = point.initialPosition;
    const { lat, lng } = event.target.getLatLng();

    const deltaLat = lat - initialLat;
    const deltaLng = lng - initialLng;

    if (!singleSelection) {
      selectedPoints.forEach((id) => {
        const initialPos = point.initialPositions?.find((ip) => ip.id === id);

        const updatedLatitude = initialPos.lat + deltaLat;
        const updatedLongitude = initialPos.lng + deltaLng;

        const marker = markersRef.current[id];
        if (marker) {
          markersRef.current[id].setLatLng([updatedLatitude, updatedLongitude]);
        }
      });
    }
  };

  const handleMarkerDragEnd = (point) => {
    [setFilteredMapPoints, setFilteredTrees, setFilteredCableSpacers].forEach(
      (setFunction, index) => {
        const points = [filteredMapPoints, filteredTrees, filteredCableSpacers][
          index
        ];
        if (points.length > 0) {
          updateFilteredPointsInitialPosition(setFunction);
        }
      }
    );

    setPointsToBeSaved((prev) => {
      const updatedPointsMap = new Map(prev.map((p) => [p.id, p]));

      if (singleSelection && point) {
        const marker = markersRef.current[point.id];
        if (marker) {
          const { lat, lng } = marker.getLatLng();
          const existingPoint = updatedPointsMap.get(point.id);
    
          updatedPointsMap.set(point.id, {
            id: point.id,
            latitude: lat,
            longitude: lng,
            initialLat: existingPoint?.initialLat ?? point.latitude,
            initialLng: existingPoint?.initialLng ?? point.longitude,
          });
        }
      } else {
        selectedPoints.forEach((id) => {
          const marker = markersRef.current[id];
          if (marker) {
            const { lat, lng } = marker.getLatLng();
            updatedPointsMap.set(id, {
              id: Number(id),
              latitude: lat,
              longitude: lng,
            });
          }
        });
      }

      return Array.from(updatedPointsMap.values());
    });

    clearPolygon();
    setSingleSelection(true);
  };

  function updateFilteredPointsInitialPosition(setFunction) {
    setFunction((prevPoints) =>
      prevPoints.map((point) => {
        const marker = markersRef.current[point.id];
        if (marker) {
          const { lat, lng } = marker.getLatLng();
          return {
            ...point,
            latitude: lat,
            longitude: lng,
            initialPosition: { lat, lng },
          };
        }
        return point;
      })
    );
  }

  const saveUpdatedMarkers = async () => {
    setLoading(true);
    try {
      const saveComponents = async (items) => {
        await Promise.all(
          items.map(async (item) => {
            try {
              if (item.type === "poles") {
                const { data } = await api.get(
                  `/detected-objects/${item.type}/${item.id}/components`
                );
                const updatedComponents = updateComponentPositions(data, item);

                await saveUpdatedComponents(updatedComponents);
              }

              await api.put(`/detected-objects/${item.type}/${item.id}`, item);
            } catch (e) {
              console.error(`Error updating ${item.type} ${item.id}:`, e);
            }
          })
        );
      };

      const pointsWithTypes = moveMapPoints
        ? getPointsToBeSaved()
        : [
            ...getDifferentPoints(mapPoints, filteredMapPoints, "poles"),
            ...getDifferentPoints(trees, filteredTrees, "trees"),
            ...getDifferentPoints(
              cableSpacers,
              filteredCableSpacers,
              "cable-spacers"
            ),
          ];

      // Save components
      await Promise.all([saveComponents(pointsWithTypes)]);

      // Replace updated points in the state
      replaceUpdatedPoints(mapPoints, pointsWithTypes, setMapPoints);
      replaceUpdatedPoints(trees, pointsWithTypes, setTrees);
      replaceUpdatedPoints(cableSpacers, pointsWithTypes, setCableSpacers);

      clearPolygon();

      dragHistoryRef.current = [];

      beginMarkersSelection();
      setMessage("Os pontos foram salvos com sucesso!");
      toggleModalMessage();
    } catch (e) {
      console.error("Error saving updated markers:", e);
      setMessage("Ocorreu um erro ao salvar os pontos!");
      toggleModalMessage();
    } finally {
      setLoading(false);
      toggleModalConfirmation();
    }
  };

  const getPointsToBeSaved = () => {
    return pointsToBeSaved
      .map((point) => {
        const mapPoint = filteredMapPoints.find((p) => p.id === point.id);
        if (mapPoint) {
          return {
            ...mapPoint,
            latitude: point.latitude,
            longitude: point.longitude,
            type: "poles",
          };
        }

        const cableSpacer = filteredCableSpacers.find((p) => p.id === point.id);
        if (cableSpacer) {
          return {
            ...cableSpacer,
            latitude: point.latitude,
            longitude: point.longitude,
            type: "cable-spacers",
          };
        }

        const tree = filteredTrees.find((p) => p.id === point.id);
        if (tree) {
          return {
            ...tree,
            latitude: point.latitude,
            longitude: point.longitude,
            type: "trees",
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const getDifferentPoints = (originalList, filteredList, type) => {
    return filteredList
      .filter((fPoint) => {
        const original = originalList.find((mPoint) => mPoint.id === fPoint.id);
        return (
          original &&
          (original.latitude !== fPoint.latitude ||
            original.longitude !== fPoint.longitude)
        );
      })
      .map((point) => ({ ...point, type })); // Add the type field
  };

  const updateComponentPositions = (components, pole) => {
    const {
      crossHeads,
      dropOutFuses,
      fiberOpticJunctionBoxes,
      insulators,
      lamps,
      lampArms,
      telecomBoxes,
      transformers,
      wireReserves,
    } = components;

    const updateCoordinates = (items) =>
      items.map((component) => ({
        ...component,
        latitude: pole.latitude,
        longitude: pole.longitude,
      }));

    return {
      crossHeads: updateCoordinates(crossHeads),
      dropOutFuses: updateCoordinates(dropOutFuses),
      fiberOpticJunctionBoxes: updateCoordinates(fiberOpticJunctionBoxes),
      insulators: updateCoordinates(insulators),
      lamps: updateCoordinates(lamps),
      lampArms: updateCoordinates(lampArms),
      telecomBoxes: updateCoordinates(telecomBoxes),
      transformers: updateCoordinates(transformers),
      wireReserves: updateCoordinates(wireReserves),
    };
  };

  const saveUpdatedComponents = async (components) => {
    const endpoints = {
      crossHeads: "/detected-objects/cross-heads/",
      dropOutFuses: "/detected-objects/drop-out-fuses/",
      fiberOpticJunctionBoxes: "/detected-objects/fiber-optic-junction-boxes/",
      insulators: "/detected-objects/insulators/",
      lamps: "/detected-objects/lamps/",
      lampArms: "/detected-objects/lamp-arms/",
      telecomBoxes: "/detected-objects/telecom-boxes/",
      transformers: "/detected-objects/transformers/",
      wireReserves: "/detected-objects/wire-reserves/",
    };

    await Promise.all(
      Object.entries(components).flatMap(([key, items]) =>
        items.map(async (item) => {
          try {
            await api.put(`${endpoints[key]}${item.id}`, item);
          } catch (e) {
            console.error(`Error updating ${key} ${item.id}:`, e);
          }
        })
      )
    );
  };

  const replaceUpdatedPoints = (originalArray, updatedPoints, setFunction) => {
    const updatedPointsMap = new Map(
      updatedPoints?.map((point) => [point.id, point])
    );

    setFunction(
      originalArray?.map((point) => {
        const updatedPoint = updatedPointsMap.get(point.id);
        if (updatedPoint) {
          return {
            ...point,
            latitude: updatedPoint.latitude,
            longitude: updatedPoint.longitude,
          };
        }
        return point;
      })
    );
  };

  useEffect(() => {
    const handleUndo = () => {
      const lastHistoryEntry = dragHistoryRef.current.pop();

      if (lastHistoryEntry?.type === "drag") {
        const { points: revertedPoints } = lastHistoryEntry;

        const isSingleSelection = revertedPoints.length === 1;

        if (!isSingleSelection) {
          revertedPoints.forEach(({ id, lat, lng }) => {
            const marker = markersRef.current[id];
            if (marker) {
              marker.setLatLng([lat, lng]);
            }
          });

          handleMarkerDragEnd();
        } else {
          const revertedPoint = revertedPoints[0];
          const { id, lat, lng } = revertedPoint;

          let pointFromList;

          [filteredMapPoints, filteredTrees, filteredCableSpacers].some(
            (list) => {
              const foundPoint = list.find((p) => p.id === id);
              if (foundPoint) {
                pointFromList = {
                  ...foundPoint,
                  latitude: lat,
                  longitude: lng,
                };
                return true;
              }
              return false;
            }
          );

          if (pointFromList) {
            const marker = markersRef.current[id];
            if (marker) {
              marker.setLatLng([lat, lng]);
            }

            handleMarkerDragEnd(pointFromList);
          }
        }
      }
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        handleUndo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMapPoints, filteredTrees, filteredCableSpacers, singleSelection]);

  const clearPolygon = () => {
    if (drawnLayersRef.current != null) {
      drawnLayersRef.current.clearLayers();
    }

    if (drawControlRef.current) {
      drawControlRef.current.disable(); // Disable drawing
    }
  };

  useEffect(() => {
    if (!moveMapPoints) {
      handleClearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveMapPoints]);

  // End Markers drag logic

  const ZoomUpdate = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCenterAuto(false);
        setZoomLevelInfo(mapEvents.getZoom(), 7);
      },
    });
    return null;
  };

  useEffect(() => {
    if (map != null) {
      setNewPointPosition(map.getCenter());
    }
  }, [map, newPointPreviewMap, setNewPointPosition]);

  const startDrawingPolygon = () => {
    setSingleSelection(false);

    if (!drawControlRef.current) {
      drawControlRef.current = new L.Draw.Polygon(map); // Initialize drawControl
    }

    drawControlRef.current.enable(); // Enable drawing
  };

  function transform() {
    const enabledPoints = getEnabledMarkers();

    if (
      !pointsToBeSaved ||
      pointsToBeSaved.length === 0 ||
      enabledPoints.length === 0
    )
      return;

    const coordTranform = new LocalCoordinateTransformer();

    const center = coordTranform.calculateCenter(enabledPoints);

    const transform = vtkLandmarkTransform.newInstance();
    transform.setMode(Mode.SIMILARITY);

    const sourceLandmarks = vtkPoints.newInstance();
    const targetLandmarks = vtkPoints.newInstance();

    pointsToBeSaved.forEach((point) => {
      const initialCartesian = coordTranform.latLonToCartesian(
        point.initialLat,
        point.initialLng,
        center.latitude,
        center.longitude
      );
      const cartesian = coordTranform.latLonToCartesian(
        point.latitude,
        point.longitude,
        center.latitude,
        center.longitude
      );

      sourceLandmarks.insertNextPoint(
        initialCartesian[0],
        initialCartesian[1],
        initialCartesian[2]
      );
      targetLandmarks.insertNextPoint(cartesian[0], cartesian[1], cartesian[2]);
    });

    transform.setSourceLandmark(sourceLandmarks);
    transform.setTargetLandmark(targetLandmarks);

    transform.update();

    const matrix = transform.getMatrix();

    const applyMatrix = ([x, y, z]) => {
      const inputPoint = [x, y, z, 1];
      const outputPoint = [0, 0, 0, 0];

      outputPoint[0] =
        matrix[0] * inputPoint[0] +
        matrix[1] * inputPoint[1] +
        matrix[2] * inputPoint[2] +
        matrix[3] * inputPoint[3];
      outputPoint[1] =
        matrix[4] * inputPoint[0] +
        matrix[5] * inputPoint[1] +
        matrix[6] * inputPoint[2] +
        matrix[7] * inputPoint[3];
      outputPoint[2] =
        matrix[8] * inputPoint[0] +
        matrix[9] * inputPoint[1] +
        matrix[10] * inputPoint[2] +
        matrix[11] * inputPoint[3];

      return coordTranform.cartesianToLatLon(
        outputPoint[0],
        outputPoint[1],
        outputPoint[2],
        center.latitude,
        center.longitude
      );
    };

    const transformedPoints = enabledPoints.map((point) => {
      const [x, y, z] = coordTranform.latLonToCartesian(
        point.latitude,
        point.longitude,
        center.latitude,
        center.longitude
      );

      const [newLat, newLng] = applyMatrix([x, y, z]);
      return {
        ...point,
        latitude: newLat,
        longitude: newLng,
      };
    });

    const poles = transformedPoints.filter((p) => p.type === "pole");
    const trees = transformedPoints.filter((p) => p.type === "tree");
    const cableSpacers = transformedPoints.filter(
      (p) => p.type === "cable-spacer"
    );

    replaceUpdatedPoints(filteredMapPoints, poles, setFilteredMapPoints);
    replaceUpdatedPoints(filteredTrees, trees, setFilteredTrees);
    replaceUpdatedPoints(
      filteredCableSpacers,
      cableSpacers,
      setFilteredCableSpacers
    );

    setPointsToBeSaved([]);
  }

  function getEnabledMarkers() {
    var poles = filteredMapPoints
      .filter((p) => p.enabled)
      .map((p) => {
        return { ...p, type: "pole" };
      });
    var trees = enableTreePointsView
      ? filteredTrees
          .filter((t) => t.enabled)
          .map((t) => {
            return { ...t, type: "tree" };
          })
      : [];
    var cableSpacers = enableCableSpacerPointsView
      ? filteredCableSpacers
          .filter((cs) => cs.enabled)
          .map((cs) => {
            return { ...cs, type: "cable-spacer" };
          })
      : [];

    return [...poles, ...trees, ...cableSpacers];
  }

  return (
    <>
      {moveMapPoints && (
        <ModalDragMapPoints
          clearAll={handleClearAll}
          saveUpdatedMarkers={toggleModalConfirmation}
          startDrawingPolygon={startDrawingPolygon}
          offCanvas={offCanvas}
          polygonsNum={drawnLayersRef?.current?.getLayers().length}
        ></ModalDragMapPoints>
      )}

      {modalConfirmation && (
        <ModalConfirmation
          toggle={toggleModalConfirmation}
          modal={modalConfirmation}
          text={"Deseja salvar os pontos?"}
          confirmFunction={saveUpdatedMarkers}
        />
      )}

      {modalMessage && (
        <ModalMessage
          isOpen={modalMessage}
          message={message}
          toggle={toggleModalMessage}
        />
      )}

      {alignMarkers && (
        <ModalMarkersTransform
          transform={transform}
          numSelectedPoints={pointsToBeSaved.length}
          toggle={toggleModalAlignMarkers}
          clear={handleClearAll}
          save={toggleModalConfirmation}
        />
      )}

      <MapContainer
        center={[-21.7729814283363, -43.364476638112734]}
        zoom={zoom} // Passe o zoom como prop
        scrollWheelZoom={true}
        style={{ width: "100%", height: "100%" }}
        ref={setMap}
        zoomControl={false}
      >
        <ZoomUpdate />
        <TileLayer
          attribution={'<a href="http://www.google.com">Google&copy</a>'}
          url={baseMapSource.url}
          subdomains={baseMapSource.subDomains}
          maxZoom={21}
        />
        {moveMapPoints && (
          <FeatureGroup ref={drawnLayersRef}>
            <EditControl
              position="topright"
              onCreated={(e) => onDrawCreate(e)}
              draw={{
                rectangle: false,
                polygon: false,
                polyline: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{
                remove: false,
                edit: false,
              }}
            />
          </FeatureGroup>
        )}

        {renderMarkersWithClustering(
          [
            {
              enabled: true,
              data: filteredMapPoints,
              icon: SelectedIcon({ url: "/imgs/selected_blue_point.svg" }),
              color: defaultPointColor,
              type: "poles",
            },
            {
              enabled: enableTreePointsView,
              data: filteredTrees,
              icon: SelectedIcon({ url: "/imgs/selected_green_point.svg" }),
              color: defaultTreePointColor,
              type: "trees",
            },
            {
              enabled: enableCableSpacerPointsView,
              data: filteredCableSpacers,
              icon: SelectedIcon({ url: "/imgs/selected_orange_point.svg" }),
              color: defaultCableSpacersColor,
              type: "cable-spacers",
            },
          ],
          getPointsMarkers
        )}

        {alignMarkers &&
          renderMarkersInitialPosition(pointsToBeSaved, "orange")}

        {newPointPreviewMap && (
          <DraggableMarker
            key={Math.random()}
            center={map.getCenter()}
            icon={SelectedIcon({ url: "/imgs/selected_red_point.svg" })}
            newPointPosition={newPointPosition}
            setNewPointPosition={setNewPointPosition}
          />
        )}

        {carPath &&
          carPathMapPoints.map(({ images }, index) => (
            <PolylineDecorator
              key={index}
              positions={images.map((coord) => [
                coord.latitude,
                coord.longitude,
              ])}
            />
          ))}

        {previewImagesVisible &&
          getPreviewImagesMarkers(previewImagesNewPoint, "#FF2C2C")}

        {/* Begin areas_of_interest */}
        {areasOfInterestActive &&
          areaOfInterest &&
          areaOfInterest.map((point, cont = 0) => {
            let contPoint = 0;

            point.forEach((elt) => {
              if (!centerTag.latMax || elt[0] < centerTag.latMax)
                centerTag.latMax = elt[0];
              if (!centerTag.latMin || elt[0] > centerTag.latMin)
                centerTag.latMin = elt[0];
              if (!centerTag.lngMax || elt[1] < centerTag.lngMax)
                centerTag.lngMax = elt[1];
              if (!centerTag.lngMin || elt[1] > centerTag.lngMin)
                centerTag.lngMin = elt[1];
              contPoint++;
            });

            let recenter = null;
            if (
              areaOfInterest.length === 1 ||
              (cont++ === areaOfInterest.length - 1 &&
                contPoint === point.length)
            ) {
              recenter = (
                <RecenterAutomatically
                  key={Math.random()}
                  lat={(centerTag.latMax + centerTag.latMin) / 2}
                  lng={(centerTag.lngMax + centerTag.lngMin) / 2}
                  centerAuto={centerAuto}
                  centerTag={centerTag}
                />
              );
            }
            return (
              <div key={Math.random()}>
                <Polygon
                  key={Math.random()}
                  pathOptions={{
                    color: colorLayersList[cont][1]
                      ? colorLayersList[cont][1]
                      : "purple",
                    fillOpacity: 0,
                  }}
                  positions={point}
                />
                {recenter}
              </div>
            );
          })}
        {/* End areas_of_interest */}
      </MapContainer>
    </>
  );
};

export default MapContainerComponent;
